.debit-notes {
  &__header {
    @include flex-row(flex-start, center);
    &--new {
      width: 35px;
      height: 13px;
      margin-left: 10px;
      background-color: $affair;
      border-radius: 14px;
      color: $white;
      @include flex-row(center, center);
      @include font-mixin(normal, normal, 600, 8px, 1, $open-sans);
      &.fade-in {
        opacity: 1;
        visibility: visible;
        animation: FadeIn 0.3s ease-in-out 1;
      }
      &.fade-out {
        animation: FadeOut 0.3s ease-in-out 1;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__title {
    @include font-mixin(normal, normal, 600, 12px, 1, $open-sans);
    color: $midnight-blue;
    margin: 0 0 6px 1px;
    &--chart {
      max-width: 250px;
      > span {
        line-height: 1.5;
      }
    }
  }
  &__form {
    background-color: $wild-sand;
    border: 1px solid $mercury;
    padding: 15px 22px 25px 22px;
    border-radius: 10px;
    margin-top: -8px;
    &--item {
      margin: 18px 0 0 0;
      @include inline-flex-column(flex-start, flex-start);
      .dropdown {
        > button {
          background-color: $white !important;
        }
      }
      &__month {
        .dropdown {
          @include dropdown-btn-mixin(222px, 29px, $jumbo);
          button {
            padding: 2px 10px 2px 7px !important;
          }
          .dropdown-menu {
            min-width: 222px !important;
            max-width: 222px !important;
          }
        }
      }
      &__top {
        @include flex-row(flex-start, flex-end);
      }
      &__middle {
        margin-right: 20px;
        &--bottom {
          @include flex-column(flex-start, flex-start);
          width: 200px;
          margin: 25px 0 0 0;
          padding: 0 4px 0 0;
          button:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
      &__right {
        &--top {
          @include flex-row(flex-end, flex-start);
        }
        &--bottom {
          @include flex-row(flex-end, flex-end);
          width: 200px;
          margin: 20px 0 0 0;
          padding: 0 4px 0 0;
          button:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
    }
    &--submit-btn {
      min-width: 104px;
      margin-left: 28px;
      @include flex-row(center, center);
      @include login-btns-mixin(auto, 2px, $tango, 104px, 15px);
      height: 28px !important;
    }
  }
  &__download--btn {
    width: 65px;
    height: 30px;
    padding: 0 !important;
    border: 1.5px solid $tango;
    color: $tango;
    border-radius: 15px;
    background-color: transparent;
    &__excel {
      margin-left: 10px;
    }
    &:hover {
      color: $white;
      background-color: $tango;
    }
    span {
      @include font-mixin(normal, normal, 600, 13px, 18px);
    }
    i {
      font-size: 12px;
      margin-left: 5px;
    }
    @include button-mixin($tango, 15px, 1.5px solid $tango);
    @include flex-row(center, center);
  }
  &__more-criteria {
    margin: 22px 0 0 0;
    &--label {
      @include font-mixin(normal, normal, 600, 11px, 15px);
      color: $abbey;
      &:hover {
        color: $tango;
      }
    }
    label {
      margin: 0 !important;
    }
    span[class^=icon-] {
      margin-top: 4px;
      font-size: 12px !important;
    }
    &--form__content {
      @include flex-row(flex-start, flex-start);
      flex-wrap: wrap;
      margin: 0 0 20px 0;
    }
    &--details__content {
      margin: 0 0 20px 0;
      padding: 0 15px;
    }
  }
  &__datepicker {
    margin-right: 42px;
    &--item {
      margin: 0 0 6px 0;
      > div {
        width: 108px;
        > div {
          box-shadow: none !important;
          border: 1px solid $dove-gray !important;
          border-radius: 3px;
          height: 29px;
          .rw-select-bordered {
            border: 0;
            position: relative;
            &:before {
              content: "";
              height: 18px;
              border-left: 1px solid $dove-gray;
              display: block;
              position: absolute;
              left: 0;
              top: 4px;
            }
          }
        }
      }
      input {
        font-size: 12px !important;
        padding: 0 5px !important;
      }
      button {
        border: 0;
        > span {
          margin-top: -2px;
        }
      }
    }
    &--top {
      margin-top: 3px;
    }
    &--bottom {

    }
  }
  &__search-box {
    @include override-multiple-mids (200px, 29px, 10px 0 0 0, 1px solid $abbey, 29px, 36px, auto, 0 1px, 1px solid $alto, auto);
    margin-top: -5px;
    .search-box {
      &__element {
        margin-top: 8px;
        margin-bottom: 15px;
        max-width: 200px;
      }
      &__control {
        @include font-mixin(normal, normal, 400, 12px, 1, $open-sans);
        color: $abbey !important;
        &:hover {
          border-color: $jumbo;
        }
      }
      &__value-container {
        padding: 0 5px !important;
      }
    }
  }
  &__daterange {
    margin: 9px 0 0 3px;
    @include font-mixin(normal, normal, 600, 11px, 1, $open-sans);
  }
  &__overview {
    padding: 20px 20px 0 20px;
    @include flex-row(stretch, flex-end);
    &--left {
      width: 50%;
      max-width: 500px;
    }
    &--right {
      width: 50%;
      max-width: 500px;
    }
    &--table {
      width: 70%;
      max-width: 325px;
      margin-top: 15px;
      position: relative;
      border: 0 !important;
      .td__overview {
        border-top: 0 !important;
        padding: 12px 8px;
        &--scheme {
          @include font-mixin(normal, normal, 600, 11px, 1, $open-sans);
          width: 40%;
          text-align: center;
          border-right: 1px solid $bombay;
          border-left: 1px solid $bombay;
        }
        &--amount {
          @include font-mixin(normal, normal, 400, 11px, 1, $open-sans);
          text-align: right;
          border-right: 1px solid $bombay;
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: 1px;
        background-color: $bombay;
        height: 3px;
        top: -3px;
        width: calc(100% - 2px);
      }
      .tfoot__overview {
        position: relative;
        td {
          padding-top: 15px;
        }
        &--scheme {
          text-align: center;
        }
        &--amount {
          text-align: right;
        }
        span {
          @include font-mixin(normal, normal, 700, 11px, 1);
        }
        &:before {
          content: "";
          position: absolute;
          left: 1px;
          background-color: $mercury2;
          height: 6px;
          width: calc(100% - 2px);
        }
      }
    }
    &--chart {
      margin: 0 0 45px -90px;
      .amcharts-title{
        transform: translate(50%, calc(50% + 12px))
      }
      .amcharts-export-menu {
        display: none !important;
      }
    }
  }
  &__details {
    padding: 0 20px 20px 20px;
    &--table {
      margin: 30px 0 0 -25px;
      font-size: 12px;
      color: $shark;
      border: none !important;
      width: 100%;
      max-width: 950px;
      .debit-notes-details-table {
        border: 0 !important;
      }
      thead {
        border: none !important;
        tr {
          th {
            @include font-mixin(normal, normal, 400, 9px, 11px, $open-sans);
            text-align: center;
            border: none !important;
            padding-bottom: 15px;
            background-color: transparent;
          }
        }
      }

      tbody {
        border-top: 2.5px solid $bombay;
        tr {
          td {
            @include font-mixin(normal, normal, 400, 11px, 11px, $open-sans);
            border: none !important;
            text-align: center;
            height: 33px !important;
            vertical-align: middle;
            &:nth-child(4) {
              width: 20% !important;
            }
            &:nth-child(n+5) {
              text-align: right !important;
            }
            &:not(:last-child) {
              border-right: 1px solid $bombay !important;
            }
            > span {
              white-space: nowrap;
            }
          }
        }
      }
      .react-bootstrap-table-pagination {
        width: 100%;
        @include flex-row(space-between, flex-end);
        margin: 0 !important;
        background-color: $white;
        height: 30px;
        .col-md-6.col-xs-6.col-sm-6.col-lg-6 {
          width: 100% !important;
          height: 30px;
        }
        .react-bootstrap-table-pagination-list {
          @include flex-row(flex-end, center);
          .react-bootstrap-table-page-btns-ul {
            margin: 25px 15px 0 0;
            padding-bottom: 8px;
            li {
              a {
                border: none !important;
                color: $abbey;
                @include font-mixin(normal, normal, 400, 12px, 1, $open-sans);
                &:hover,
                &:active,
                &:focus {
                  background-color: transparent;
                  font-weight: 600;
                }
              }
              &.active {
                a {
                  background-color: transparent !important;
                  border-color: transparent !important;
                  font-weight: 700 !important;
                  z-index: 1 !important;
                }
              }
              &:first-child a {
                padding-left: 0 !important;
              }
              &[title="Προηγούμενη σελίδα"],
              &[title="Previous page"],
              &[title="Επόμενη σελίδα"],
              &[title="Next page"],
              &[title="Πρώτη σελίδα"],
              &[title="First page"],
              &[title="Τελευταία σελίδα"],
              &[title="Last page"] {
                a {
                  color: transparent !important;
                  &:hover {
                    font-weight: 400 !important;
                  }
                }
                &.disabled {
                  a,
                  a:before {
                    border-color: $light-silver !important;
                  }
                }
              }
              &[title="Προηγούμενη σελίδα"],
              &[title="Previous page"] {
                a:before {
                  @include caret-horizontal($tango, 0, rotate(135deg));
                  content: ' ';
                  position: absolute;
                }
              }
              &[title="Πρώτη σελίδα"],
              &[title="First page"] {
                a:before,
                a:after {
                  @include caret-horizontal($tango, 0, rotate(135deg));
                  content: ' ';
                  position: absolute;
                }
                a:after {
                  margin-left: 1px !important;
                }
              }
              &[title="Επόμενη σελίδα"],
              &[title="Next page"] {
                a:before {
                  @include caret-horizontal($tango, 0, rotate(-45deg));
                  content: ' ';
                  position: absolute;
                }
              }
              &[title="Τελευταία σελίδα"],
              &[title="Last page"] {
                a:before,
                a:after {
                  @include caret-horizontal($tango, 0, rotate(-45deg));
                  content: ' ';
                  position: absolute;
                  margin-left: -4px;
                }
                a:after {
                  margin-left: -2px;
                }
              }
            }
          }
        }
        .react-bootstrap-debit-notes-table-pagination-total {
          @include font-mixin(normal, normal, 600, 12px, 1, $open-sans);
          @include flex-row(flex-start, flex-end);
          height: 30px;
          margin: 0 0 0 15px;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .debit-notes {
    $dbn: &;
    &__more-criteria--form__content {

    }
    &__form {
      position: relative;
      padding: 15px 22px 50px 22px;
      &--item {
        &__top {
          flex-wrap: wrap;
          max-width: 200px;
          #{$dbn}__download--btn {
            margin: 10px 0 0 auto;
          }
        }
        &__middle {
          margin-left: 0;
        }
        &__right--top {

        }
        &__month .dropdown button {
          width: 200px;
        }
      }
      &--submit-btn {
        position: absolute;
        bottom: 15px;
        right: 15px;
      }
    }
    &__overview {
      flex-wrap: wrap;

      &--left,
      &--right {
        width: 100%;
        max-width: initial;
        margin-top: 20px;
      }

      .table-responsive {
        border: 0 !important;
      }

      &--table {
        width: 100%;
        max-width: initial;
      }

      &--chart {
        width: 100%;
        max-width: initial;
        margin: 0 0 20px 0;

        .donut-chart--cards {
          flex-wrap: wrap;
        }
      }
    }

    &__title--chart {
      max-width: initial;
    }

    &__details {
      width: calc(100vw - 50px);
      &--table {
        max-width: calc(100vw - 50px);
        width: 100%;
        .react-bootstrap-table {
          overflow-x: auto;
          width: calc(100vw - 75px);
          margin: 0 -10px;
        }
        .react-bootstrap-table-page-btns-ul {
          margin: 90px 0 0 0 !important;
        }
        .react-bootstrap-table-pagination {
          flex-direction: column-reverse;

          .react-bootstrap-debit-notes-table-pagination-total {
            margin: -30px 0 0 0;
          }
        }
      }
    }
  }
}
