.header-logo-no-cursor .logo {
  cursor: default !important;
}

.expired-pw-area {
  input {
    height: 25px;
  }

  &__text {
    &--container {
      display: flex;
      flex-direction: column;
      max-width: 450px;
      margin-bottom: 25px;
      color: $midnight-blue;

      h2 {
        font-weight: 600;
        font-size: 25px;
      }
      article {
        max-width: 400px;
        margin-top: 25px;
      }
    }
  }
  .login-area {
    &__content {
      @include flex-column(center, center);
      margin: 110px auto 0 auto;
    }
   &__login-box {
      padding: 30px 65px 15px 75px;
    }
    &__forgot-password {
      margin-top: 10px;
    }

    &__login-with-forgot {
      margin-left: 0;
    }

    &__copyright {
      @include font-mixin(normal, normal, 600, 10px, 1.2, $open-sans);
      color: $jumbo;
      @include flex-row(center, center);
      width: 100%;
      margin: 5% auto 0 auto;
      position: absolute;
      bottom: 20px;
    }
}

  .user-profile {
    &__icon-password-eye {
      top: 6px;
    }

    &__btn {
      margin: 10px auto;
      &_gray {
        cursor: not-allowed;
      }
    }
    &__bottom-error {
      margin-top: 0 !important;
    }
  }

  .feedback-msg {
    display: flex;
    &.msg-success {
      color: $limeade;
    }
    &.msg-fail{
      color: $bright-red;
    }
    justify-content: center;
    align-items: center;
    > span:nth-child(2) {
      margin-left: 10px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .login-area {
    &__login-with-forgot {
      margin-left: auto !important;
    }
    &__content {
      margin: 0 auto 0 auto !important;
    }
  }

  .expired-pw-area {
    .login-area {
      &__login-with-forgot {
        width: calc(100% - 40px);
      }
      &__login-box {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding: 30px 40px 0 40px;
      }
    }
  }
}