
.transaction__details {
  &_modal-header {
    height: 62px;
    @include flex-reversed-row(space-between, flex-end);
    padding: 10px 35px;
    position: relative;
    border-bottom: 1px solid $jumbo;

    &:before,
    &:after {
      border-bottom: 1px solid $white;
      width: 35px;
      content: "";
      position: absolute;
      bottom: -1px;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    .close {
      color: $lochmara !important;
      opacity: 0.7 !important;
      transform: translateY(-20px) translateX(25px);
    }

    .modal-title {
      @include flex-row(space-between, center);
      width: 57%;
    }

    .buttons-wrapper {
      @include flex-row(space-between, center);
      width: 75px;
    }

    &--print {
      display: none;
    }
  }

  &__refund-modal {
    width: 20%;
    margin-top: 15%;

    &__header {
      min-height: 16.42857143px;
      padding: 15px;
      border-bottom: none;
      @include override-modal-close-btn;

    }

    &__button-wrapper {
      @include flex-row(space-evenly, center);
      padding-top: 20px;

      &__cancel-button {
        @include black-border-btn-mixin(0 0 10px 0, 11px, $dark-silver, 6px 13px !important);
      }

      &__refund-button {
        min-width: 76px;
        @include black-border-btn-mixin(0 0 10px 0, 11px, $tango, 6px 20px !important, hidden);
      }
    }

    &__text-wrapper {
      @include flex-row(center, center);

      &.refund-modal-min-height {
        min-height: 112px;
      }

     .refund-message-feedback {
        margin-bottom: 24px;
          &--error {
            color: $bright-red;
          }
          &--success {
            color: $fan-green;
          }
          &--icon {
            vertical-align: middle;
            margin-right: 10px;
            font-size: 20px;
          }
      }
    }

    &__bold-text-wrapper {
      @include flex-row(center, center);
      font-weight: 700;
    }
  }

  &_modal.modal-dialog {
    width: 740px;
    margin-top: 85px;
  }

  &_modal-body {
    //padding: 0 35px;
  }

  @media print {
    &_modal-header {
      &--print {
        display: block !important;
        padding: 0 !important;
        margin: 80px 0 20px 0;
        flex-direction: row !important;
        height: 50px;
        &:before,
        &:after {
          content: unset !important;
        }
      }
    }
    &--print-lbl {
      display: block !important;
      font: normal normal 300 15px/1.2 $open-sans;
      margin-top: 5px;
    }
    &__icon--no-print {
      display: none !important;
      line-height: 0 !important;
    }
    &__print--btn {
      display: none !important;
    }
    &--refund {
      display: none !important;
    }
  }

  &--print-lbl {
    display: none;
  }

  &__print--btn {
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
    @include hover-focus-states($darker-alabaster, $darker-alabaster, $tango, $midnight-blue);
    background-color: $darker-alabaster;
    border-radius: 50%;
    border: 0 !important;
    color: $tango !important;
    font-size: 12px !important;
    padding: 0 !important;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: $tango;
    }

    &:disabled {
      background-color: $light-alto !important;
      box-shadow: none;
    }
  }
}

.all-details {
  $ad: &;
  &__refund-modal {

    &--currency-label {
      margin-left: 8px;
    }

    &__text {
      @include flex-row(center, center);
    }

    &__amount-textbox-wrapper {
      padding-top: 10px;
      padding-bottom: 40px;
      @include flex-row(center, center);
    }

    &__amount-textbox {
      width: auto;
      max-width: 100px;
      padding: 0 5px;
    }

    &__button-wrapper {
      @include flex-row(space-between, center);

      &__cancel-button {
        @include black-border-btn-mixin(0 0 10px 0, 11px, $dark-silver, 6px 20px !important);
      }

      &__refund-button {
        @include black-border-btn-mixin(0 0 10px 0, 11px, $tango, 6px 20px !important, hidden);
      }
    }
  }

  &__wrapper {
    padding: 0 35px;
    &--no-refund-btn {
      padding-bottom: 95px;
    }
  }

  &__detail-row {
    @include flex-row(space-between, flex-start);
    flex-wrap: wrap;
    border-bottom: 1px solid $alto;
    &--aliases .all-details__detailColumn {
      white-space: pre-wrap;
      word-break: break-word;
      &:nth-child(1) {
        width: 22% !important;
      }
      &:nth-child(2){
        width: 18% !important;
      }
      &:nth-child(3) {
        width: 18% !important;
      }
      &:nth-child(4) {
        width: 33% !important;
      }
      @media screen and (max-width: $medium-mobile-limit-plus-a-hundred) {
        &:nth-child(1),
        &:nth-child(2){
          width: 40% !important;
        }
        &:nth-child(3) {
          display: none !important;
        }
      }
    }
  }
  &__refund-row {
    padding-top: 40px;
    padding-bottom: 15px;
    @include flex-row(center, flex-start);

    &__refund-button {
      @include blue-border-btn-mixin(0 0 10px 0, 11px, 6px 20px);
      &:hover,
      &:active,
      &:focus:hover,
      &:active:focus,
      &:focus {
       border: 1px solid $bahama-blue !important;
      }
    }
  }

  &__print-buttons-row {
    @include flex-row(space-between, flex-start);
    flex-wrap: wrap;
    position: absolute;
    bottom: 35px;
    right: 82%;
    width: 11%;
    height: auto;
  }

  &__detailColumn {
    @include flex-column(flex-start, flex-start);
    min-height: 65px;
    padding-top: 15px;
    margin-bottom: 5px;

    &:first-child {
      align-items: flex-start !important;
      width: 22%;
    }

    &:nth-child(2) {
      width: 18%;
    }

    &:nth-child(3) {
      width: 18%;
    }

    &:nth-child(4) {
      width: 18%;
    }

    &:last-child {
      width: 12%;
    }

    > span {
      white-space: pre-wrap;
      &:first-child {
        @include font-mixin(normal, normal, 700, 9px, 1.4, $open-sans);
        color: $jumbo;
      }

      &:nth-child(2) {
        @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
        margin-top: 5px;
      }
    }

    .bank-icon-wrapper {
      margin-top: 5px;
      margin-left: 10px;
      &__one {
        margin-left: 5px !important;
      }
      &__nexi {
        margin-left: 0 !important;
      }
      &__worldline {
        margin-left: 4px !important;
      }
    }

    > span {
      &.icon-icon-check {
        margin-top: 15px;
        margin-left: 15px;
        color: $fan-green;
      }

      &.icon-x-icon {
        margin-top: 15px;
        margin-left: 15px;
        color: $bright-red;
        font-size: 0.7em;
      }
    }
  }
}

.refunded-by__container {
  width: calc(200% - 20px);
  word-break: break-all;
}

#transaction-details-receipt-email-popover {
  display: block;
  top: 35% !important;
  left: 33% !important;
  position: absolute;
  width: auto;
  > .arrow {
    display: none;
  }
}

#transaction-details-popover {
  display: block;
  top: 350px !important;
  left: 290px !important;
  width: 230px;

  > .arrow {
    visibility: hidden;
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .transaction__details {
    &_modal.modal-dialog {
      width: auto !important;
      margin: 85px 15px;
    }
  }
  .all-details {
    &__detailColumn {
      height: auto !important;
      padding-bottom: 10px;
    }
  }
  #transaction-details-receipt-email-popover {
    left: 37% !important;
  }
}

@media screen and (max-width: $common-mobile-limit) {
  .all-details__detailColumn > span:nth-child(2) {
    word-break: break-all;
  }
  #transaction-details-receipt-email-popover {
    left: 31% !important;
  }
}

@media screen and (max-width: $min-mobile-limit) {
  .all-details {
      &__detailColumn {
        font-size: 12px !important;
        span:nth-child(2) {
          font-size: 12px !important;
        }
      }
    }
  #transaction-details-receipt-email-popover {
    left: 7% !important;
    top: 73% !important;
  }
}

@media screen and (max-width: $medium-mobile-limit-plus-a-hundred) {
  .all-details {
    &__wrapper {
      padding: 0 20px;
    }

    &__detail-row {
      justify-content: flex-start !important;
    }

    &__detailColumn {
      word-break: normal;
      margin-right: 10px;

      &.vMerchant-col {
        width: 40% !important;
      }

      &.authorizationCode-col {
        width: 38% !important;
      }

      &.closeBatch-col {
        width: 38% !important;
      }

      &.cardEntryMode-col {
        width: 40% !important;
      }

      &.totalInstalments-col {
        width: 30% !important;
      }

      &.redemptionAmount-col {
        width: 28% !important;
      }

      &.cashier-col {
        width: 22% !important;
      }

      &.remainingAmount-col {
        width: 50% !important;
      }

      &:first-child {
        width: 40%;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(3) {
        width: 35%;
      }

      &:nth-child(4) {
        width: 30%;
      }

      &:last-child {
        width: 12%;
      }
    }
  }
  #transaction-details-receipt-email-popover {
    left: 25% !important;
    top: 70% !important;
  }
}
