@mixin hot-popover($margin, $width, $height, $max-width) {
  width: $width;
  max-width: $max-width !important;
  height: $height;
  margin: $margin;
  z-index: 6;
}

.hotspot {
  position: relative;
  $scale-duration: 2.5s;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $affair;

  &:before,
  &:after {
    content: '';
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
    position: absolute;
    top: 0;
    left: 0;
    transition: box-shadow .3s;
    z-index: -1;
  }

  &:after {
    animation: pulse-animation $scale-duration ease-in-out infinite both;
    background-color: $lochmara;
    opacity: .2;
  }

  &:before {
    animation: pulse-animation-outter $scale-duration ease-in-out infinite both !important;
    background-color: $malibu;
    animation-delay: $scale-duration;
    opacity: .3;
  }

  &:after {

  }

  &:hover {
    box-shadow: 0 0 .5px .5px $affair;
    &:before,
    &:after {
      box-shadow: 0 0 .5px .5px $french-pass;
    }
  }
  &__wrapper {
    position: absolute;
    z-index: 5;
    cursor: pointer;
  }

  &__close {
    @include flex-row(center, center);
    text-align: center;
    margin-top: 6px;
    color: $white;
    font-size: 8px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  &__companiesDropdown   {
    top: 10px;
    right: -9px;
  }
  &__storesMultiselect {
    top: 8px;
    right: 52px;
  }
  &__reportsTransactions {
    top: -57px;
    right: -11px;
    left: unset !important;
  }
  &__quickSearch {
    top: 20px;
    left: 15px;
  }
  &__reportsBanks {
    top: 6px;
    left: 20px;
  }
  &__downloadReports {
    top: -28px;
    left: 50%;
  }
  &__cardScheme {
    top: 0;
    left: 52px;
    z-index: 6;
  }
  &__cardsDates {
    top: 8px;
    right: -9px;
  }
  &__transactionTypes {
    left: 32px;
    top: -30px;
  }
  &__exportChartHints {
    top: 14px;
    left: 35px;
  }
  &__downloadBusinessExcel {
    top: -40px !important;
    left: -6px;
  }
  &__eCommerceBusiness {
    top: 8px;
    left: -8px;
  }
  &__transactionExcelButton {
    right: 280px;
    top: 21px;
    z-index: 7;
  }
  &__transactionDetailsInfo {
    top: 5px;
    left: 30%;
    //margin-left: 200px;
  }
  &__transactionSettlingInfo {
    top: 68px;
    right: 30%;
  }
  &__settledTransactions {
    z-index: 6;
  }
  &__isSettledTransactionHeader {
    top: -20px;
  }
  &__threeDSecureHeader {
    top: -13px;
    left: 15px;
  }
  &__responseCodeHeader {
    right: 10px;
    top: -13px;
    b {
      color: $bright-red;
    }
  }
}

.hotSpot-element {
  &__wrapper {
    position: relative;
    &--quick-find {
      width: 100%;
    }
    &--multiselect-stores {
      @include inline-flex-row(flex-start, flex-start);
      min-width: 360px;
    }
    &--ecom-info {
      position: absolute;
      top: 15px;
      right: 15px;
    }
    &--centered {
      margin-left: auto;
      margin-right: auto;
    }
    &--trans-settling-info {
      z-index: 6;
      display: block;
      //left: calc(50% + 70px);
      //top: 25px;
    }
  }
}

div[id^="hotSpot-popover"] {
  @include font-mixin(normal, normal, 400, 14px, 17px, $open-sans);
  color: $affair;
  padding: 5px 0 5px 0;

  .arrow {
    display: none !important;
  }
}

.hot-popover-image {
  margin-bottom: 20px;
}

.popover.hot-popover {
  &__companiesDropdown {
    @include hot-popover(28px 0 0 -95px, 210px, auto, 400px);
  }
  &__storesMultiselect {
    @include hot-popover(28px 0 0 -95px, 210px, auto, 400px);
    left: unset !important;
  }
  &__reportsTransactions {
    @include hot-popover(27px 0 0 0, 300px, auto, 400px);
    left: unset !important;
    right: -265px !important;
    top: 0 !important;
  }
  &__quickSearch {
    @include hot-popover(27px 0 0 -10px, 300px, auto, 400px);
  }
  &__reportsBanks {
    @include hot-popover(32px 0 0 -15px, 280px, auto, 400px);
    ul {
      @include new-margin-block(8px);
    }
  }
  &__downloadReports {
    @include hot-popover(27px 0 0 0, 296px, auto, 400px);
    left: unset!important;
    right: -128px !important;
  }
  &__cardScheme {
    @include hot-popover(26px 0 0 0, 240px, auto, 400px);
    left: -28px !important;
  }
  &__cardsDates {
    @include hot-popover(27px -2px 0 0, 240px, auto, 400px);
    left: unset !important;
    right: 0 !important;
  }
  &__transactionTypes {
    @include hot-popover(7px 0 0 -15px, 240px, auto, 400px);
    top: unset !important;
  }
  &__exportChartHints {
    @include hot-popover(27px 0 0 0, 280px, auto, 400px);
    ul {
      @include new-margin-block(8px);
    }
  }
  &__downloadBusinessExcel {
    @include hot-popover(28px 0 0 0, 240px, auto, 400px);
    left: 20px;
  }
  &__eCommerceBusiness {
    @include hot-popover(17px 0 0 0, 220px, auto, 400px);
    top: 18px !important;
    cursor: auto !important;
  }
  &__transactionExcelButton {
    @include hot-popover(28px 0 0 -110px, 241px, auto, 400px);
  }
  &__transactionDetailsInfo {
    @include hot-popover(28px 0 0 -110px, 241px, auto, 400px);
  }
  &__transactionSettlingInfo {
    @include hot-popover(28px 0 0 -110px, 241px, auto, 400px);
  }
  &__settledTransactions {
    @include hot-popover(25px 0 0 -48px, 230px, auto, 400px);
  }
  &__pendingTransactions {
    @include hot-popover(25px 0 0 -48px, 230px, auto, 400px);
  }
  &__isSettledTransactionHeader {
    @include hot-popover(28px 0 0 0, 240px, auto, 400px);
    ul {
      @include new-margin-block(8px);
    }
  }
  &__threeDSecureHeader {
    @include hot-popover(25px 0  0 0px, 190px, auto, 400px);
    left: -65px !important;
    cursor: auto !important;
  }
  &__responseCodeHeader {
    @include hot-popover(28px 0 0 0px, 165px, auto, 400px);
    left: unset !important;
    right: -25px !important;
  }
}


@keyframes pulse-animation {
   0% { transform: scale(1.5); }
   50% { transform: scale(2); }
   100% { transform: scale(1.5); }
 }

@keyframes pulse-animation-outter {
  0% { transform: scale(1.5); }
  50% { transform: scale(3); }
  100% { transform: scale(1.5); }
}


@media screen and (max-width: $tablet-width-limit) {
  .hotSpot-element {
    &__wrapper {
      width: 100% !important;
      &--multiselect-stores {
        min-width: initial !important;
      }
      &--companies-dropdown {
        width: auto !important;
        align-self: flex-start;
      }
      &--ecom-info {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .hotspot {
    &__companiesDropdown {
      z-index: 7;
    }
    &__storesMultiselect{
      margin-top: 20px;
    }
    &__reportsTransactions{
      margin-top: -45px;
    }
    &__quickSearch {
      z-index: 7;
    }
    &__reportsBanks {
      left: 21px !important;
      top: 10px !important;
    }
    &__cardScheme {
      left: 50px !important;
    }
    &__transactionTypes {
      left: 48px !important;
      bottom: 93px !important;
    }
    &__downloadBusinessExcel {
      top: -112px !important;
      z-index: 7;
    }
    &__transactionExcelButton{
      right: 60px !important;
      z-index: 6;
    }
    &__transactionDetailsInfo {
      z-index: 7;
      top: 46px !important;
      left: 10% !important;
    }
  }

  .popover.hot-popover {
    &__companiesDropdown {
      margin-top: 26px !important;
      margin-left: -150px !important;
    }
    &__storesMultiselect {
      left: unset !important;
      margin-top: 28px;
      right: -38px !important;
    }
    &__reportsTransactions {
      right: -5px!important;
    }
    &__quickSearch {
      width: 180px !important;
      //margin-left: -40px !important;
    }
    &__reportsBanks {
      margin-top: 35px !important;
    }
    &__downloadReports {
      width: 200px !important;
      margin: 26px 0 0 0 !important;
      left: calc(50% - 100px) !important;
    }
    &__cardScheme {
      width: 220px !important;
      left: 0 !important;
    }
    &__transactionTypes {
      left: -50px !important;
      margin-top: 6px;
    }
    &__settledTransactions {
      width: 200px !important;
      margin-left: -25px !important;
    }
    &__pendingTransactions {
      width: 200px !important;
      margin-left: -80px !important;
    }
    &__transactionExcelButton {
      right: -55px !important;
      left: unset !important;
    }
    &__transactionDetailsInfo {
      width: 170px !important;
      margin-left: calc(50% - 45px)!important;
    }
    &__transactionSettlingInfo {
      width: 190px !important;
      left: unset !important;
      right: -20px !important;
    }
    &__isSettledTransactionHeader {
      width: 260px !important;
    }
    &__threeDSecureHeader {
      margin-top: 25px;
      width: 190px !important;
      left: -90px !important;
    }
    &__responseCodeHeader {
      width: 160px !important;
      right: -10px !important;
    }
  }
}

@media screen and (max-width: $tablet-width-limit) and (min-width: $medium-mobile-limit-plus-one) {
  .hotspot {
    &__transactionSettlingInfo {
      top: 104px;
      right: 10%;
    }
  }
}

@media screen and (max-width: $medium-mobile-limit) {
  .hotspot {
    &__transactionExcelButton {
      top: 60px !important;
    }
    &__transactionSettlingInfo  {
      top: 104px;
      right: 10%;
    }
  }

  .popover.hot-popover {
    &__transactionDetailsInfo {
      margin-left: calc(50% - 40px) !important;
    }
  }
}