


.block-modal {
  &.modal-backdrop {
    background-color: rgba(0, 36, 93, 1) !important;
    opacity: 0.65 !important;
  }
  &.modal-content {
    span {
      color: $midnight-blue;
      font-weight: 600;
    }
  }
}

.forms {
  &__show-help {
    width: 100%;
    @include font-mixin(normal, normal, 600, 0.8em, 1, $open-sans);
    text-align: center;
    margin-top: 15px;
  }
}

html {
  overflow-y: unset !important;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $open-sans;
  color: $shark;
  background-color: $alabaster;
}

body {
  overflow-y: auto;
}

body.modal-open {
  overflow-y: hidden !important;
}

@include reset-input-autofill;

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

input:focus {
  outline: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


button {
  outline: none !important;
}

#main-app {
  top: 0;
  overflow: hidden;
}

.logo {
  img {
    width: 160px;
    margin-left: 15px;
    &.login {
      display: block;
      width: 80%;
      max-width: 200px;
      height: auto;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 8px;
    }
    &.intro {
      width: 100%;
      height: auto;
      margin-right: auto;
      margin-left: auto;
      margin-top: 10px;
    }
    &.terms-and-conditions {
      width: 80px;
    }
  }
}

.terms-and-conditions {

  &__title {
    text-align: center;
    color: $bahama-blue;
  }

  &__modal {
    padding-right: 30px;
  }

  &__modal-body {
    max-height: 500px;
    overflow-y: scroll;
    margin-left: 5%;

    &__pre-text {
      white-space: pre-wrap;
    }

    &__attention-part {
      margin-top: 10px;
    }

    &__accept-button {
      margin-top: 10px;
      border-radius: 15px;
      border: none;
      width: auto;
      background-color: $tango;
      color: white;
      padding: 5px 15px;
    }
  }

  &__modal-footer {
    padding-bottom: 30px;
    padding-right: 0;
  }

  &__download {
    @include download-btn-mixin;
    border-radius: 50%;
    border: 0 !important;
  }
}

.policies {
  &__modal {
    &.modal-dialog {
      width: calc(100% - 600px) !important;
      height: calc(100vh - 130px) !important;
    }
    .modal-header {
      border-bottom: 0 !important;
    }
    .modal-body {
      padding: 15px 0 0 0 !important;
      iframe {
        border-width: 0 !important;
      }
    }
  }
}

.switch-locale {
  @include flex-row(flex-end, baseline);
  position: relative;
  padding: 1.4rem 1rem 0 0;
  color: $bahama-blue;

  input {
    display: none;
  }

  label {
    &.locale-selected {
      color: $tango;
    }
  }

  span {
    margin: 3px;
    color: $jumbo;
  }

  label {
    @include font-mixin(normal, normal, 400, 14px, 1, ($open-sans));
    cursor: pointer;
  }
}

.timer-logout {
  text-align: center;
  margin-left: 10px;
  color: $abbey;
}

.logout {
  width: 22px;
  height: 23px;
  cursor: pointer;
  color: $tango;
  &__pending {
    opacity: 0.7;
    &:hover {
      cursor: not-allowed !important;
      opacity: 0.7;
    }
  }
  &:not(.logout__pending):hover {
    color: $cerulean;
  }
}

.timer {
  @include font-mixin(normal, normal, lighter, 11px, 1, ($open-sans));
  margin-bottom: 5px;

  .timer-icon {
    margin-right: 5px;
    vertical-align: middle;
  }
}

.main-page {
  margin: 70px -60px 0 155px;
  height: auto !important;
  min-width: 912px;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
}


.main-content {
  padding: 0 10px 3px 0 !important;
}

.generic-error-msg {
  @include error-warning-msg-mixin ($bright-red);
}

.generic-warning-msg {
  @include error-warning-msg-mixin ($carrot-orange);
}

.generic-success-modal-msg {
  @include flex-column(flex-start, center);

  .icon-success {
    color: $limeade;
    font-size: 45px;
    margin-bottom: 10px;
  }

  .text-success {
    color: $midnight-blue;
    margin-bottom: 15px;
  }
}

.generic-warning-modal-msg {
  @include flex-column(flex-start, center);

  .icon-failed {
    color: $bright-red;
    font-size: 45px;
    margin-bottom: 10px;
  }

  .text-warning {
    color: $shark;
    margin-bottom: 15px;
  }
}

.generic-modal-title {
  color: $midnight-blue;
}

/* OVERRIDE MODAL BG */
.modal-backdrop {
  background-color: $nepal !important;

  &.in {
    opacity: 0.6 !important;
  }
}

/* Sliding panes */

.slide-pane {
  &__header {
    display: none !important;
  }

  &__content {
    padding: 35px 25px !important;
  }
}

.modal-backdrop + .modal {
  padding-right: 0 !important;
}

.amcharts-export-menu {
  opacity: 1 !important;

  .export-main > a {
    @include download-btn-mixin;
  }
}

.wave {
  @include wave-props(absolute)
}

.wave-registration {
  @include wave-props(relative)
}

.empty-msg__shape {
  width: 210px;
  height: 210px;
  background-image: url("../img/shingle.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @include flex-column(center, center);
  .empty-msg {
    &__wrapper {
      color: $white;
      max-width: 180px;
      text-align: center;
      position: absolute;
      margin-left: -10px;
    }

    &__title {
      @include font-mixin(normal, normal, 600, 16px, 1.3, $open-sans);
    }

    &__text {
      margin-top: 6px;
      @include font-mixin(normal, normal, 400, 13px, 1.2, $open-sans);
      text-align: center;
    }
  }
}

.no-pos__wrapper {
  min-height: calc(100vh - 200px);
  @include flex-column(flex-start, center);
}

@media screen and (max-width: $bg-wave-limit) {
  .wave,
  .wave-registration {
    left: 50%;
    position: absolute !important;
    margin-left: -640px;
  }
}

@media screen and (max-width: $common-medium-limit) {
  .main-page {
    margin: 0 20px 0 20px !important;
    width: calc(100% - 40px) !important;
  }

  .policies {
    &__modal {
      &.modal-dialog {
        width: calc(100% - 100px) !important;
        margin: 20px auto !important;
        height: calc(100vh - 20px) !important;
      }
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .main-page {
    min-width: initial !important;
    overflow: visible !important;
    &:not(.business-page) {
      margin: 70px 20px 0 20px;
    }
    &.business-page {
      margin: 20px 20px 0 20px !important;
    }
  }
  .main-content {
    //padding: 0 20px !important;
  }

  .logout {
    font-size: 18px !important;
  }

  .slide-pane {
    &__content {
      padding: 24px 15px !important;
    }
  }
  .policies {
    &__modal {
      &.modal-dialog {
        width: calc(100% - 40px) !important;
      }
    }
  }

  .terms-and-conditions {
    &__modal {
      padding-right: 0 !important;
    }
    &__download {
      margin-right: 10px;
    }
  }
}


/* ICONS BANKS */

@mixin banks-sprite($width, $height, $posx, $posy, $zoom) {
  width: $width;
  height: $height;
  display: block;
  background: url('../img/banks_sprite.png') $posx $posy;
  overflow: hidden;
  &.shrinked {
    zoom: $zoom;
    -moz-transform: scale($zoom);
    -moz-transform-origin: 0 0;
  }
}

.bg-cardlink_one {
  @include banks-sprite(41px, 33px, -5px, -104px, 0.8);
}

.bg-cardlink_one2 {
  @include banks-sprite(40px, 31px, -58px, -104px, 0.8);
}

.bg-nexi {
  @include banks-sprite(56px, 16px, -4px, -19px, 1.1);
}

.bg-attica_bank {
  @include banks-sprite(32px, 29px, -62px, -62px, 0.8);
}

.bg-worldline {
  @include banks-sprite(38px, 27px, -64px, -9px, 1.2);
}

.bg-nbg {
  @include banks-sprite(35px, 25px, -114px, -10px, 0.9);
}

.bg-piraeus_bank {
  @include banks-sprite(32px, 32px, -10px, -62px, 0.8);
}

.bg-sklavenitis {
  @include banks-sprite(31px, 27px, -114px, -55px, 0.8);
}

@media (min-width: 768px) and (max-width: 990px) {
  .modal-dialog {
    width: calc(100vw - 200px) !important;
    margin: 30px auto !important;
  }
}
