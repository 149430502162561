.registration-information-page {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: $alabaster;
  min-height: 620px;
  .msg {
    &__title {
      text-align: center;
      @include font-mixin (normal, normal, 600, 28px, 1, $open-sans);
      word-spacing: 0.1em;
      color: $midnight-blue;
      margin-top: 60px;
    }
    &__wrapper {
      display: block;
      position: relative;
      margin: auto;
      width: 35%;
      min-width: 200px;
    }
    &__text {
      margin-top: 15px;
      padding: 15px 65px 30px 65px;
      text-align: center;
      color: $midnight-blue;
      font-size: 16px;
      //background-color: $white;
      //border-radius: 15px;
      //box-shadow: 1px 1px 6px rgba($black, 0.25);
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .invalid-invitation-area {
    .msg__wrapper {
      width: calc(100% - 40px) !important;
    }
    .msg__text {
      padding: 0 !important;
    }
  }


}