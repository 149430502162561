.intro-screen {
    $is: &;
    width: 100%;
    height: 100%;
    background-color: $alabaster;
    color: $midnight-blue;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    z-index: 10;
    min-height: 620px;

    .logo {
        cursor: default;
        img {
            margin-left: 15px;
        }
    }

    &__top {
        @include flex-row(flex-end, center);
        margin: 3rem 2.5rem 1rem 0;
    }

    &__switch-locale {
        margin-right: 20px;
    }

    &__logout-locale {
        @include flex-row(flex-end, center);
        .timer-logout {
            color: $midnight-blue !important;
            margin-top: -40px;
        }
        .logout {
            color: $midnight-blue !important;
        }
    }

    &__content {
        height: 100%;
        @include flex-column(stretch, center );
        position: relative;
        #{$is}__title {
            text-align: center;
            @include flex-column(center, center);
            flex-grow: 0.6;
            span:first-child {
                @include font-mixin(normal, normal, 300, 2.07em, 1.2, ('Open Sans', sans-serif));
            }
            span:nth-child(2) {
                @include font-mixin(normal, normal, 700, 3em, 1.2, ('Open Sans', sans-serif));
            }
        }

        #{$is}__menu {
            $m: &;
            flex-wrap: wrap;
            @include flex-row(center, flex-end);
            list-style: none;
            flex-grow: 1.7;
            margin-top: -250px;
            padding-bottom: 40px;
        }

        #{$is}__menu__link {
            border-radius: 60px;
            margin: 8px;
            @include flex-column(flex-start, stretch);
            width: 14.286em;
            height: 14.286em;
            padding: 20px;
            background-color: $white;
            @include box-shadow(0, 6px, 20px, 0, rgba($black, 0.10));
            position: relative;
            overflow: hidden;
            div[class^="icon-icon-"] {
                font-size: 4.071em;
                margin-top: 17px;
                text-align: center;
                @include transition(all, 200ms, linear);
            }

            h5 {
                @include font-mixin(normal, normal, 600, 1em, 1.2, ('Open Sans', sans-serif));
                @include reset-margin-block;
                text-decoration: none;
                text-align: center;
                margin: 15px 0 0 0;
                color: $midnight-blue;
                @include transition(all, 200ms, linear);
            }

            p {
                color: $abbey;
                @include font-mixin(normal, normal, 400, 0.781em, 1.3, ('Open Sans', sans-serif));
                @include reset-margin-block;
                text-align: center;
                margin: 5px 0 0 0;
                @include transition(all, 200ms, linear);
            }

            &:hover {
                @include transform(scale(1.1));
                @include transition(all, 200ms, linear);
                div[class^="icon-icon-"] {
                    @include animation(smallToBig, 500ms, alternate, 0, 1, ease);
                    //@include transparent-shadow($bahama-blue, transparent);
                    font-size: 77px;
                    margin-top: 0;
                }

                h5 {
                    @include animation(smallToBig, 500ms, alternate, 0, 1, ease);
                    margin: 10px 0 0 0;
                }

                .icon-icon-tour {
                    //text-shadow: 0 0 5px $white !important;
                    font-size: 6.214em !important;
                    margin-top: 15px !important;
                }

                p {
                    //@include animation(moveFromBottom, 500ms, alternate, 0ms, 1, ease);
                }
            }


            &_highlight {
                background-color: $bahama-blue;
                position: relative;

                h5 {
                    color: $white;
                    margin-top: 15px;
                    max-width: 94%;
                }


                .icon-icon-tour {
                    font-size: 5em !important;
                    color: $white !important;
                    margin-top: 25px !important;
                }
                &__header {
                    position: absolute;
                    top: 0;
                    text-align: center;
                    padding: 8px;
                    background-color: $tango;
                    color: $white;
                    width: 47%;
                    left: 26.5%;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    @include font-mixin(normal, normal, 700, 0.643em, 1.1, ('Open Sans', sans-serif));

                }
            }
        }

        #{$is}__menu__option {
            @include flex-row(center, flex-start);
            position: relative;
            flex-grow: 1.2;
            z-index: 1;
            @include custom-checkbox($midnight-blue, $midnight-blue, transparent, 3px 0 0 0, -3px 5px 0 0, 2px 1px 0 0);
        }
    }

    &__copyright {
        @include font-mixin(normal, normal, 600, 10px, 1.2, $open-sans);
        color: $jumbo;
        width: 100%;
        position: absolute;
        margin: -35px auto;
        text-align: center;
    }

    &__wave-wrapper {
        position: relative;
        height: 60px;
    }
}


@media screen and (max-width: $tablet-width-limit) {
    .intro-screen {
        $is: &;
        font-size: 10px;
        padding-bottom: 2.5em;

        &__top {
            display: block !important;
            margin: 4rem 2rem 1rem 2rem !important;
        }

        .logo {
            img {
                &.intro {
                    width: 60%;
                }
            }
        }

        &__menu {
            margin: 3rem 0 3rem 0 !important;
            &__option {
                label {
                    max-width: calc(100% - 40px) !important;
                    white-space: normal !important;
                    line-height: 1.2 !important;
                    text-align: center !important;
                }
            }
        }

        &__content {
            justify-content: space-around !important;
            height: auto;
            padding: 0 20px 4rem 20px;
            min-height: 70vh;
        }

        &__logout-locale{
            justify-content: space-between !important;
        }

        &__menu__link {
            border-radius: 5em !important;
            justify-content: center !important;
            div[class^="icon-icon-"] {
                font-size: 3.5em !important;
                margin-top: 0 !important;
            }
            h5 {
                margin-top: 5px !important;
            }
            p {
                font-size: 1em !important;
            }
        }

       &__title {
            text-align: center;
            span:first-child {

            }
            span:nth-child(2) {

            }
        }

    }
}

