footer {
  @include flex-row(center, flex-end);
  height: 60px;
  padding-bottom: 30px;
  position: relative;
}

.footer {
  &__wrapper {
    min-height: 60px;
  }
  &__block {
    @include font-mixin(normal, normal, 600, 11px, 1.2, $open-sans);
    color: $midnight-blue;
    border-spacing: 5px 0;
    &:hover {
      color: $tango;
    }
    span {
      display: table-cell;
      vertical-align: bottom;
      cursor: pointer;
      &:first-child {
        line-height: 30px;
        margin-right: 10px;
      }
    }
  }
  &__copyright {
    @include font-mixin(normal, normal, 600, 10px, 1.2, $open-sans);
    color: $jumbo;
    position: absolute;
    left: 15px;
    z-index: 7;
  }
}

@media screen and (max-width: $tablet-width-limit) {
  footer {
    flex-direction: column !important;
    align-items: center !important;
  }

  .footer {
    &__copyright{
      position: static !important;
      margin-bottom: 10px;
      z-index: 1 !important;
    }
  }
}