.order-status {
  &__container {
    margin-top: 15px;
    width: calc(100% - 10px);
    h6 {
      font-size: 12px !important;
    }
  }
  &__wrapper {
    @include box-shadow(0, 1px, 9px, 1px, rgba($dove-gray, 0.35));
    border-radius: 12px;
  }
  &__logo {
    padding: 4px;
    @include flex-row(center, center);
    &--icon {
      width: 100%;
      margin-left: 2px;
    }
  }
  &__btn {
    background-color: $white;
    padding: 5px 5px 10px 5px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    @include flex-row(center, center);
    &--clickable {
      color: $white;
      padding: 5px;
      margin-top: 5px;
      border-radius: 15px;
      white-space: nowrap;
      @include flex-row(center, center);
      @include font-mixin(normal, normal, 400, 11px, 12px, ($open-sans));
      &:hover,
      &:focus:hover,
      &:active {
        opacity: 0.76;
        color: $white !important;
      }
      &:focus {
        color: $white !important;
      }
    }
    &--icon {
      margin-left: 3px;
    }
    &--none {
      background-color: $tango;
    }
    &--pending {
      background-color: $tango;
    }
    &--partiallyActivated {
      background-color: $tango;
    }
    &--blocked {
      background-color: $red;
    }
    &--activated {
      background-color: $limeade;
    }
    &--declined {
      background-color: $abbey;
    }
    &--cancelled {
      background-color: $abbey;
    }
  }

  &__modal {
    &.modal-dialog {
      width: 20% !important;
    }
    .modal-header {
      border: 0 !important;
      padding: 10px 10px 0 10px !important;
      min-height: auto !important;
    }
    .modal-title {
      line-height: 0.7 !important;
      img {
        width: 140px;
      }
    }
    .modal-body {
      padding: 0 40px 30px 40px !important;
      article p {
        white-space: pre-wrap;
      }
    }
    &--body {
      margin: 15px auto 0 auto;
      font-size: 14px !important;
      ul {
        @include new-margin-block(8px);
      }
    }
    &--title {
      &-icon {
        display: block;
        width: 80px;
        margin: 20px auto 0 auto;
      }
      &-text {
        @include flex-row(center, center);
        margin-top: 17px;
        font-size: 17px;
        font-weight: 600;
        color: $lochmara;
      }

    }
  }
  &__bottom-btn {
    font-size: 14px;
    @include btns-mixin(75%, 20px, $tango, 200px, 14px, $white, $tango);
    &:hover {
      color: $white !important;
    }
    > span {
      @include flex-row(center, center);
      line-height: 30px;
    }
    &--container {
      @include flex-row (center, center)
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .order-status {
    &__modal {
      &.modal-dialog {
        width: calc(100% - 40px) !important;
        margin: 20px auto !important;
      }
    }
  }
}