#left-panel {
  width: 155px;
  position: fixed;
  padding: 12px 5px 12px 15px;
  z-index: 9;
  background-color: $alabaster;
  height: 100vh;

  nav {
    @include flex-column(space-between, flex-start);
    padding-right: 30px;
    a {
      @include font-mixin(normal, normal, 400, 13px, 2, ($open-sans));
      color: $shark;
      width: 128px;
      &._active {
        font-weight: bold;
        color: $midnight-blue;
      }

      &:last-child > span {
        padding-bottom: 12px;
      }
    }
  }

  nav a:hover:not(._active),
  ul li:hover,
  .external-list a:hover {
    color: $bahama-blue !important;
    font-weight: bold;
  }
  .external-list .switch-item:hover {
    color: $bahama-blue !important;
    font-weight: bold;
  }

  .modal-list {
    list-style: none;
    padding: 17px 0 0 0;
    min-height: 69px;
    @include reset-margin-block;

    &__item {
      @include font-mixin(normal, normal, 400, 10px, 2.6, ($open-sans));
      cursor: pointer;
      &._active__modal {
        font-weight: bold;
        color: $midnight-blue;
      }

      &:last-child > span {
        //border-bottom: 1px solid $shark;
        padding-bottom: 10px;
      }
    }
  }

  .external-list {
    padding-top: 15px;
    @include flex-column(space-between, flex-start);
    @include reset-margin-block;
    &__link {
       @include font-mixin(normal, normal, 400, 10px, 2.7, ($open-sans));
       cursor: pointer;
       color: $shark;
       &--expandable {
         &:hover {
           color: $bahama-blue !important;
           font-weight: bold;
           .caret {
             border-width: 0 2px 2px 0 !important;
             border-color: $bahama-blue !important;
           }
         }
         .caret {
            @include override-caret($shark, 2px);
            border-width: 0 1px 1px 0 !important;
            margin-left: 6px;
          }
          &_expanded{
            color: $bahama-blue !important;
            font-weight: bold;
            .caret {
              @include override-caret-reversed;
              border-width: 0 2px 2px 0 !important;
              border-color: $bahama-blue !important;
              margin-top: -3px !important;
            }
            .external-list__link--content {
              height: 40px;
            }
          }
        }
        &--content {
          @include flex-column(stretch, stretch);
          overflow: hidden;
          height: 0;
          transition: height 300ms ease-out;
          margin-top: -3px;
        }
        &--item {
          @include font-mixin(normal, normal, 400, 10px, 1.8, ($open-sans));
        }
    }
  }
}

.overlay-help {
  @include font-mixin(normal, normal, 400, 10px, 2.7, ($open-sans));
  @include inline-flex-row(space-around, center);
  cursor: pointer;
  color: $shark;
  width: calc(100% - 14px);
  position: relative;
  &._active {
    font-weight: bold;
    color: $midnight-blue;
  }
  .toggle {
    position: relative;
    display: block;
    width: 26px;
    height: 14px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    margin: 0 10px 0 auto;
    &:before {
      content: "";
      position: relative;
      top: 0;
      left: 0;
      width: 26px;
      height: 14px;
      display: block;
      background: $alto;
      border-radius: 8px;
      transition: background .2s ease;
    }
    span {
      position: absolute;
      top: -1px;
      left: -2px;
      width: 16px;
      height: 16px;
      display: block;
      background: $mercury;
      border-radius: 10px;
      box-shadow: 0 3px 8px rgba($silver, .5);
      transition: all .2s ease;
      &:before {
        content: "";
        position: absolute;
        display: block;
        margin: -18px;
        width: 56px;
        height: 56px;
        background: rgba($malibu, .5);
        border-radius: 50%;
        transform: scale(0);
        opacity: 1;
        pointer-events: none;
      }
    }
  }
  .cbx {
    display: none;
  }

  .cbx:checked + .toggle {
    &:before {
      background: $french-pass;
    }
    span {
      background: $malibu;
      transform: translateX(14px);
      transition: all .2s cubic-bezier(.8, .4, .3, 1.25), background .15s ease;
      box-shadow: 0 3px 8px rgba($malibu, .2);
      &:before {
        transform: scale(1);
        opacity: 0;
        transition: all .4s ease;
      }
    }
  }
  &__bubble {
    top: 254px !important;
    left: 130px !important;
    border-radius: 15px;
    width: 230px;
    position: fixed !important;
    background-color: $white-pointer;
    border: 0 !important;
    box-shadow: 4px 4px 12px 2px rgba(125, 65, 153, 0.4);
    &--content {
      @include flex-column(flex-start, stretch);
      margin-left: 6px;
      &_wrapper {
        @include flex-row(stretch, flex-start);
      }
    }
    &--title {
      @include font-mixin(normal, normal, 700 , 14px, 1.2, $open-sans);
      color: $affair;
    }
    &--text {
      @include font-mixin(normal, normal, 400 , 11px, 1.4, $open-sans);
      margin-top: 3px;
      color: $black;
    }
    &--arrow {
      transform: rotate(-90deg);
      color: $affair;
    }

    &--close {
      position: absolute;
      right: 12px;
      top: 12px;
      color: $bombay;
      font-size: 9px;
      cursor: pointer;
      &:hover {
        color: $affair !important;
      }
    }
    > .arrow {
      display: none !important;
    }
    > .popover-content {
        padding: 20px 28px 20px 8px;
    }
  }
}

.blocking-overlay {
  position: fixed;
  top: 0;
  width: calc(100% - 145px);
  left: 155px;
  height: 100%;
  z-index: 4;
  background-color: $affair;
  opacity: 0.2;
  &__wrapper {
    position: relative;
  }
  &__modal {
    width: 100% !important;
    left: 0 !important;
  }
}

.burger-btn {
  width: 30px;
  height: 30px;
  position: fixed;
  top: -5px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  margin: 33px 0 0 20px;
  z-index: 9;
  display: none;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $midnight-blue;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 8px;
  }

  span:nth-child(4) {
    top: 16px;
  }

  &.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }

  &.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
}

.menu__overlay {
  display: none;
}

@media screen and (max-width: $common-medium-limit) {
  aside {
    position: fixed;
    left: 0;
    top: 0;
    transform: translate3d(-100vw, 0, 0);
    width: 100vw;
    height: 100vh;
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    z-index: 4;
    margin-top: 70px;
    background-color: $alabaster;

    &.menu_open {
      transform: translate3d(0vw, 0, 0);
      overflow: hidden;
    }

    &.menu_close {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .burger-btn {
    display: block;
  }
  .menu__overlay {
    @include overlay-mixin($silver, 0.5, 3);
    &.overlay_open {
      display: block;
    }
    &.overlay_close {
      display: none;
    }
  }
  .overlay-help__bubble {
    display: none !important;
  }
  .blocking-overlay {
    width: 100% !important;
    left: 0 !important;
  }
}

.slide-pane {
  box-shadow: none !important;
  &__overlay {
    margin: 70px 0 0 155px;
    z-index: 4;
  }

  &__content {
    background-color: $wild-sand;
  }
}


  @media screen and (max-width: $tablet-width-limit) {
    .slide-pane__overlay {
      margin: 70px 0 0 0;
      z-index: 9;
    }
    .blocking-overlay {
      width: 100% !important;
      left: 0 !important;
    }
  }

