@mixin tooltip-popover($margin, $width, $height, $max-width) {
  top: -14px ;
  left: 17px ;
}

@mixin tooltip-info-label($right: inherit, $top: inherit, $left: inherit) {
  position: absolute;
  cursor: pointer;
  top: $top !important;
  left: $left !important;
  right: $right !important;
}

@mixin tooltip-info-relative($marginLeft: inherit, $marginTop: inherit) {
  cursor: pointer;
  position: relative;
  margin-left: $marginLeft;
  margin-top: $marginTop;
}

@mixin popover-position($top, $left, $right: inherit, $margin-left: inherit) {
  top: $top !important;
  left: $left !important;
  right: $right !important;
  margin-left: $margin-left !important;
}

/* tltp */
[id$="-tltp"] {
  width: 200px;
  color: $shark !important;
  z-index: 5 !important;
  > .arrow {
    display: none ;
  }
}

button + .icon-icon-info {
  color: $midnight-blue;
  font-size: 10px !important;
  vertical-align: middle;
}

.tooltip-button {
  width: 10px;
  padding: 0;
  margin-left: 5px;
  visibility: hidden;
  position: absolute;
}

.mid-label {
  @include tooltip-info-label(-3px, 0px);
  position: relative !important;
}

.quickFind-label {
  @include tooltip-info-label(-13px, 3px);
  z-index: 3;
}

.company-tk-label {
  @include tooltip-info-label();
  margin: 3px 0 0 3px ;
}

.export-form-label {
  @include tooltip-info-relative(5px);
}

.multiple-mid-transaction-label {
  @include tooltip-info-relative(3px, 17px);
}

.duplicate-transactions-label {
  @include tooltip-info-relative(5px, 8px);
}

.multiple-tid-transaction-label {
  @include tooltip-info-relative(5px, 17px);
}

.refund-label {
  @include tooltip-info-relative(4px, 7px);
}

.download-trans-label {
  @include tooltip-info-label(-10px, 1px);
}

#mid-tltp {
  @include popover-position(3px, -90px );
}

#quickFind-tltp {
  @include popover-position(3px, -90px, 11px, -1px);
}


#quickFind-business-tltp {
  @include popover-position(38px, unset, 11px, -1px);
}

#company-tk-tltp {
  @include popover-position(-8px, 14px, -118px, -1px);
}

#business-export-tltp {
  @include popover-position(3px, 9px);
}

#business-export-tid-tltp {
  @include popover-position(3px, 9px);
}

#refund-tltp {
  @include popover-position(-193px, 9px );
  width: 200px;
  height: 200px;
}

#multiple-mid-popover-tltp {
  @include popover-position(12px, -95px );
  width: 200px;

  > .popover-content {
    padding: 10px 10px 10px 10px ;
  }
}

#duplicate-transactions-tltp {
  @include popover-position(10px, 10px );
  width: 200px;

  > .popover-content {
    padding: 10px 10px 10px 10px ;
  }
}

#report-download-multiple-mid-tltp {
  @include popover-position(12px, unset, 0, 0);
}

#reports-right-column-title-tltp {
  @include popover-position(12px, -100px, 0, 0);
}


/* Additional wrappers' styles */
.transactions__tooltip-wrapper {
  position: relative;
}
.business-mid-alias-label,
.business-tid-alias-label,
.reports-transactions-chart-label,
.overview-kpi-title-label,
.reports-right-column-title-label {
  position: relative;
  cursor: pointer;
  margin-left: 3px;
}

#business-mid-alias-tltp {
  @include popover-position(2px, 10px);
}

#business-tid-alias-tltp {
  @include popover-position(3px, 10px);
}

#download-trans-tltp {
  @include popover-position(-17px, -200px);
}

#reports-transactions-chart-tltp {
  margin-left: 0;
  top: 8px !important;
}

#overview-kpi-title-tltp {
  @include popover-position(7px, 7px);
}

@media screen and (max-width: $tablet-width-limit) {

  button + .icon-icon-info {
    font-size: 14px !important;
  }

  .mid-label {
    cursor: pointer;
    position: absolute !important;
    top: 29px !important;
    right: -17px !important;
  }

  .quickFind-label {
    top: 0 !important;
    right: -18px !important;
  }

  #quickFind-tltp {
    top: 5px !important;
    left: -95px !important;
  }

  .search-fast__business {
    .quickFind-label {
      top: 22px !important;
      right: -20px !important;
    }
    #quickFind-tltp {
      top: 5px !important;
      left: -190px !important;
    }
  }
  .refund-label {
    margin-top: 5px !important;
  }

  #mid-tltp {
    left: -192px  !important;
    right: unset !important;
}

  #business-mid-alias-tltp {
    @include popover-position(15px, -84px);
    width: 180px !important;
  }
  #business-tid-alias-tltp {
    @include popover-position(15px, -90px);
    width: 180px !important;
  }

  #business-export-tltp {
    @include popover-position(16px, -95px);
  }

  #business-export-tid-tltp {
    @include popover-position(15px, -95px);
  }

  #multiple-mid-popover-tltp {
    @include popover-position(15px, unset, calc(100% - 10px), inherit);
  }

  #duplicate-transactions-tltp {
    @include popover-position(15px, unset, calc(100% - 10px), inherit);
  }

  #multiple-tid-popover-tltp {
    @include popover-position(0, 0);
  }


  #overview-kpi-title-tltp {
    left: unset!important;
    right: 4px !important;
    top: 15px !important;
  }

  #reports-transactions-chart-tltp {
    top: 16px !important;
    width: 160px;
    left: unset !important;
    right: 5px !important;
  }


  #report-download-multiple-mid-tltp {
    @include popover-position(15px, -190px, unset, unset );
    > .popover-content {
      padding: 10px 10px 10px 10px ;
    }
  }

  #reports-right-column-title-tltp {
    top: 15px !important;
  }

  #refund-tltp {
    @include popover-position(-202px, -195px );
  }

  .export-form-label {
    margin-top: -2px !important;
  }

  .multiple-tid-transaction-label {
    @include tooltip-info-relative(5px, 16px);
  }
}