
.user-profile {
  @include flex-column(space-between, center);
  &__content {
    height: 30px;
    @include flex-column(space-between, flex-start);
  }
  &__label {
    &__title {
      &_closed {
        @include font-mixin(normal, normal, 700, 9px, 1.2, $open-sans);
      }
      &_opened{
        @include font-mixin(normal, normal, 700, 11px, 1.2, $open-sans);
        color: $tango;
        margin-bottom: 25px;
        letter-spacing: 0.4px;
      }
    }
    &__value {
      &_closed {
        @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
        letter-spacing: -0.1px;
       > span {
         white-space: pre;
         overflow: hidden;
         text-overflow: ellipsis;
       }
      }
      &_opened{
        @include font-mixin(normal, normal, 300, 14px, 1.2, $open-sans);
        color: $midnight-blue;
        margin-bottom: 7px;
      }
    }
  }
  &__item {
    @include user-modal-tile(65px, 10px, 20px 30px, $white);
    @include flex-column(space-between, flex-start);
    > form {
      @include flex-column(space-between, flex-start);
      width: 100%;
      input {
        align-self: stretch;
        border-radius: 50px;
        border: 1px solid $bombay;
        height: 28px;
        padding: 0 10px;
        margin-bottom: 22px;
      }
    }

    &_image{
      @include flex-row(flex-start, flex-start);
      background-color: $tango;
      color: $white;
      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      &-existing-avatar {
        margin-right: 30px;
        position: relative;
        height: 30px;
        width: 50px;

        img {
          height: 50px;
          margin-top: -10px;
          position: absolute;
        }
      }
      &-existing-text {
        @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
        letter-spacing: -0.1px;
      }

      &__delete {
        @include button-as-url($white, $white);
        margin-top: 5px;
        font-size: 9px;
        position: absolute;
        right: 10px;
        bottom: 10px !important;
      }

      .avatar-label-close {
        border-radius: 100%;
        background-color: $white;
        font-size: 8px;
        color: $tango;
        width: 20px;
        height: 20px;
        padding: 6px;
        @include user-profile-btn(15px, 15px);
      }
      .feedback-msg {
        margin-left: -30px !important;
      }
    }
    .toggle-item-btn {
      @include user-profile-btn(10px, 10px);
      padding: 10px;
      font-size: 12px;
      border: none !important;
      background-color: transparent !important;
      width: 30px;
      height: 30px;
      i {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .icon-edit-pencil {
        transition: opacity .3s, transform .3s;
        color: $tango;
        &:hover {
          color: $bahama-blue;
        }
      }
      .icon-x-icon {
        transition: opacity .3s, transform .3s;
        transform: rotate(-180deg) scale(.5);
        opacity: 0;
        color: $jumbo;
        &:hover {
          color: $tango;
        }
      }
      &.isOpened {
        .icon-edit-pencil {
          transform: rotate(180deg) scale(.5);
          opacity: 0;
        }
        .icon-x-icon {
          transform: rotate(0deg) scale(1);
          opacity: 1;
        }
      }
    }
    .feedback-msg {
      color: $white;
      @include feedback-msg($midnight-blue);
      &.hide {
        display: none !important
      }
      > span:nth-child(2) {
          margin-left: 10px;
          font-size: 22px;
      }
    }
  }
  &__btn {
    width: auto;
    padding: 0 20px;
    align-self: center;
    border-radius: 50px;
    height: 28px;
    @include flex-row(center, center);
    min-width: 125px;
    margin-top: 3px;
    &_orange {
      @include button-mixin($white, 50px, 1px solid transparent);
      background-color: $tango;
      @include hover-focus-states($tango, $midnight-blue, $white, $white);
    }
    &_gray {
      @include button-mixin($white, 50px, 1px solid $bombay);
      background-color: $bombay;
    }
  }

  &__footnote {
    margin-top: 18px;
    letter-spacing: 0.2px;
    @include font-mixin(normal, normal, 600, 9px, 1.5, $open-sans);
    color: $jumbo;
  }

  &__bottom-error {
    display: inline-block;
    color: $bright-red;
    margin-top: 20px;
    > span {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  &__avatar-error {
    display: inline-flex;
    font-size: 11px;
    line-height: 13px;
    max-width: 125px;
    margin-left: 23px;
    > span {
      vertical-align: middle;
      margin-right: 5px;
      display: table-cell;
    }
  }
  &__icon-password-eye {
    @include password-eye(2px, 0);
  }
  &__password-container {
    width: 100%;
    position: relative;
    input {
      width: 100%;
      padding: 0 33px 0 10px !important;
    }
  }
}

.avatarEditorWithButton {
  @include flex-column(flex-start, center);
  position: relative;
  width: 165px;
}

.user-avatar-submit-form {
  margin-bottom: 0;
  align-items: flex-end !important;
  svg {
    z-index: 2 !important;
    left: unset !important;
    right: -35px !important;
    top: -10px !important;
  }
}


.btn-close-edit-avatar-tooltip {
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: -20px !important;
  top: -10px !important;
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
}

.user-avatar-submit-btn {
  margin-top: 10px;
  border-radius: 15px;
  background-color: $midnight-blue;
  border: 1px solid $midnight-blue;
  padding: 0 20px;
  width: auto;
  min-width: 125px;
  height: 28px;
  &:hover {
    background-color: $lochmara;
    border: 1px solid $lochmara;
  }
}

.slide-pane {
  $sp: &;
  &__profile {
    height: 100%;
    overflow: hidden;
    #{$sp}__content {
      height: 100%;
      width: 380px;
      overflow-y: scroll;
      padding: 35px 32px 35px 25px !important;
      box-sizing: content-box;
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .slide-pane {
    $sp: &;
    &__profile {
      width: 100% !important;
      height: 100% !important;
      #{$sp}__content {
        width: 100% !important;
        padding: 24px 15px !important;
      }
    }
    .user-profile {
      width: calc(100% - 30px) !important;
      &__item {
        padding: 15px !important;
        .feedback-msg {
          margin-left: -15px !important;
        }
      }
    }
  }
}