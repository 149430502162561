.reset-area-step1 {
  @include reset-login(100vh, 110px);
  overflow-y: visible !important;

  &__custom-modal {
    width: 20%;
    margin-top: 10%;
    @include override-modal-close-btn;
  }
}

.reset-area-step2 {
  @include reset-login(100vh, 0);
  overflow-y: visible !important;

  &__right {
    @include flex-column(space-between, flex-start);
  }

  &__custom-modal {
    width: 20%;
    margin-top: 10%;
    @include override-modal-close-btn;
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .reset-area-step1 {
    height: 100vh !important;
    &__custom-modal {
      width: calc(100% - 40px) !important;
      margin-left: 20px !important;
    }
  }
  .reset-area-step2 {
    &__right {
      width: 100% !important;
    }
    &__login-box {
      width: 100% !important;
    }
    &__custom-modal {
      width: calc(100% - 40px) !important;
    }
  }
}