.login-area__wrapper,
.intro-screen {
  .modal-backdrop {
    background-color: rgba(0, 36, 93, 1) !important;
    opacity: 0.65 !important;
  }
}
.intro-dialog {
  &__intro-screen {
    margin: 90px auto !important;
  }
  &__login-screen {
    margin: 170px auto !important;
  }
  width: 560px !important;
  .modal-header {
    border: 0 !important;
    @include override-modal-close-btn;
    .close {
      line-height: 0.5 !important;
    }
  }
  .modal-body {
    text-align: center !important;
    p {
      max-width: 65%;
      font: normal normal 22px/1.1 $open-sans;
      margin: 25px auto;
      color: $lochmara;
    }
    article {
      font: normal normal 14px/1.3 $open-sans;
      color: $shark;
      max-width: 72%;
      &.article__intro-screen {
          margin: 20px auto 75px auto;
      }
      &.article__login-screen {
        margin: 70px auto 75px auto;
        a {
          font-weight: bold !important;
          color: $lochmara !important;
          &:hover {
            color: $tango !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {

  .intro-dialog {
    width: calc(100% - 40px) !important;
    margin: 60px auto !important;
  }
}