@mixin bounce-mixing($width, $height) {
  width: $width;
  height: $height;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loader__wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;

  &.bahama-blue {
    @include loader($bahama-blue);
    @include spinner($bahama-blue);
  }

  &.tango {
    @include loader($tango);
    @include spinner($tango);
  }

  &.affair {
    @include loader($affair);
    @include spinner($affair);
  }

  &.midnight-blue {
    @include loader($midnight-blue);
    @include spinner($midnight-blue);
  }

  &.cerulean {
    @include loader($cerulean);
    @include spinner($cerulean);
  }

  &.white {
    @include loader($white);
    @include spinner($white);
  }


  h6 {
    @include font-mixin(normal, normal, 600, 18px, 1.2, $open-sans);
    text-align: center;
    margin: 0 !important;
  }

  .loader__sub-msg{
    @include font-mixin(normal, normal, 600, 14px, 1.2, $open-sans);
    color: $shark;
    text-align: center;
    margin: 15px 0 !important;
  }

  #loader {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  #loader2 {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }

  #box {
    width: 50px;
    height: 60px;
    opacity: 0.65;
    animation: animate 0.7s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px 30px 20px 30px / 20px 40px 30px 10px ;
  }

  #box2 {
    width: 60px;
    height: 55px;
    opacity: 0.55;
    animation: animate 0.9s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px 20px 30px 20px / 40px 20px 40px 30px;
  }

  #shadow {
    width: 50px;
    height: 5px;
    background: $cornflower-blue;
    opacity: 0.4;
    position: absolute;
    top: 90px;
    left: 0;
    border-radius: 50%;
    animation: shadow .8s linear infinite;
  }

  h4 {
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    font-weight: 200;
    opacity: .5;
    font-family: sans-serif;
  }

  /* SPINNER */
  .spinner {
    margin: 30px auto 0;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    @include bounce-mixing(18px, 18px);

    &.smaller-bounces {
      @include bounce-mixing(12px, 12px);
    }
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;

    &.smaller-bounces {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

.is-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(158, 182, 196, 0.6);
  top: 0;
  left: 0;
  z-index: 8;
}

.white-loader-bg {
  padding: 30px 25px 25px 25px;
  width: 340px;
  height: 240px;
  background-color: $white;
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% - 170px);
  border-radius: 15px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}

@keyframes loader1 {
  0% { left: -100px }
  100% { left: 110% }
}

@keyframes loader2 {
  0% { left: -100px }
  100% { left: 110% }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@keyframes shadow {
  50% {
    transform: scale(1.4,.4);
  }
}


@keyframes loader {
  0% {
    left: -100px
  }
  100% {
    left: 110%;
  }
}


@keyframes animate {
  10% { border-bottom-left-radius: 35px;
    border-bottom-right-radius: 20px; }
  20% { transform: translateY(10px) rotate(20deg); }
  40% { transform: translateY(20px) scale(1.4,.5) rotate(35deg);
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 20px;
  }
  60% { transform: translateY(35px) rotate(50deg);
  }
  80% { transform: translateY(0) rotate(70deg);
  }
  100% { transform: translateY(0) rotate(90deg);
  }
}

/* SPINNER'S KEYFRAMES */
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}