/* MIXINS */
@mixin search-field-mob {
  width: 100%;
}

.right-column {
  padding: 63px 0 0 0;
  width: 300px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -22px;
  @include font-mixin(normal, normal, 400, 14px, 1, $open-sans);
  &.no-quick-find {
    padding: 12px 0 0!important;
  }

  .banner__wrapper {
    width: 100%;
    height: auto;
    border-radius: 12px;
    > div {
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        border-radius: 12px;
      }
    }
  }
}

.support-form {
  margin-top: 20px;
  height: auto;
  @include single-container-wrap;
  &__btn {
    @include blue-border-btn-mixin(30px 0 15px 0, 11px, 6px 20px);
  }
}

#support-tabs {
  border-radius: 12px;
  ul {
    background-color: $white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    display: table;
    table-layout: fixed;
    width: 100%;
    li {
      display: table-cell;
      border: none !important;
      border-radius: 14px;
      font-size: 1em;
      &:not(.active) {
        background-color: $white;
        font-weight: 300;
      }
      &:hover {
        a {
          color: $bahama-blue !important;
        }
      }
      a {
        background-color: $white;
        margin: 0 !important;
        border: none !important;
        color: $shark;
        text-align: center;
        @include font-mixin(normal, normal, 600, 12px, 1.2, $open-sans);
        padding: 10px 15px 10px 0 !important;
        > span {
          padding: 0 15px 5px 15px !important;
          letter-spacing: -1px;
          white-space: nowrap;
        }
      }
      #support-tabs-tab-1 {
        border-top-left-radius: 12px;
        border-top-right-radius: 0;
      }
      #support-tabs-tab-2 {
        border-top-right-radius: 12px;
        border-top-left-radius: 0;
      }
      &:first-child {
        width: 50%;
      }
      &:nth-child(2) {
        width: 50%;
        max-height: 40px;
      }
      &.active {
        a {
          background-color: $white;
          border: none !important;
          font-weight: 600;
          color: $midnight-blue !important;
          > span {
            border-bottom: 4px solid $bahama-blue;
            padding: 0 15px 5px 15px;
          }
        }
      }
      a:hover {
        margin-left: 0;
      }
    }
  }

  .tab-content {
    min-height: 205px;
    border-top: 1px solid $bombay;
    div[id^=support-tabs-pane] {
      padding: 0;
    }
  }
}

#support-tabs-pane-1 p {
  @include font-mixin(normal, normal, 300, 1.29em, 1, $open-sans);
  text-align: center;
  margin-top: 15px;
  @include flex-row(center, center);
  .support-icon {
    font-size: 36px;
    color: $lochmara;
  }
  &:first-child {
    margin-top: 30px;
  }
  &:nth-child(2) {
    @include font-mixin (normal, normal, 600, 12px, 1.2, $open-sans);
    margin-bottom: 5px !important;
  }
  &:nth-child(3) {
    @include font-mixin (normal, normal, 300, 20px, 1.2, $open-sans);
    color: $midnight-blue;
    margin-top: 5px !important;
  }
}

#support-tabs-pane-2 p {
  line-height: 1.4;
  a {
    color: $midnight-blue;
    &:hover {
      color: $bahama-blue;
    }
  }
}

.faq__element {
  border-bottom: 1px solid $bombay;
  .panel-default {
    border: 0 !important;
    margin-bottom: 0 !important;
  }
  .panel-heading {
    @include override-panel;
    border-color: transparent !important;
    color: $midnight-blue;
    background-color: $white !important;
    padding: 10px 15px !important;
    cursor: pointer;
    h6 {
      margin: 0 !important;
      border: 0 !important;
      font-size: 11px !important;
      @include accordion-icons($lochmara, 9px, 5px);
      > i {
        top: 50%;
        margin-top: -4px;
      }
      position: relative;
    }
  }
  .panel-collapse {
    .panel-body {
      @include font-mixin (normal, normal, 400, 11px, 1.4, $open-sans);
      padding: 0 15px 10px 15px !important;
      border-color: transparent !important;
    }
    //&:not(.collapse) {
    //  .panel-body {
    //    border-bottom: 1px solid $bombay !important;
    //  }
    //}
  }
}

.faq__title {
  max-width: 250px;
  white-space: normal;
  line-height: 1.4;
  width: 85%;
  font-weight: 600;
}

.more-questions {
  padding: 10px 15px;
  @include flex-row(flex-start, center);
  &__icon {
    font-size: 32px;
    color: $midnight-blue;
    margin-right: 10px;
  }

  &__text {
    @include flex-column (space-evenly, flex-start);
    min-height: 40px;
  }
  &__first {
    @include font-mixin(normal, normal, 300, 14px, 1.2, $open-sans);
  }
  &__second {
   @include font-mixin(normal, normal, 700, 9px, 1.2, $open-sans);
    color: $bahama-blue !important;
  }
}

#collapsible-panel-download-reports {
  border: 0 !important;
  @include single-container-wrap;
  margin-top: 20px;
  .panel-heading {
    @include override-panel;
    border-radius: 0 !important;
    background-color: $cerulean !important;
    border: 1px solid $cerulean !important;
    color: $white !important;
    cursor: pointer;
    position: relative;
    h6 {
      margin: 0 !important;
      @include font-mixin (normal, normal, 400, 14px, 1.2, $open-sans);
      @include accordion-icons($white, 17px, 15px);
    }
  }
  .panel-body {
    background-color: $wild-sand;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    h6 {
      color: $midnight-blue;
      @include font-mixin(normal, normal, 600, 12px, 1.2, $open-sans);
    }

    .datetimepickers-wrapper {
      display: flex;
      margin-bottom: 40px;
      .rw-datetime-picker {
        &:nth-child(2) {
          margin-left: 15px;
        }
        .rw-widget-picker {
          border-radius: 0 !important;
          border: 1px solid $dove-gray;
          .rw-select-bordered {
            border-color: $dove-gray !important;
          }
          .rw-btn-select {
            color: $dove-gray !important;
          }
          input {
            padding: 0 0 0 3px !important;
          }
        }
      }
      .rw-state-focus .rw-widget-container {
        box-shadow: none !important;
        border-color: $dove-gray !important;
      }

      .datetime-picker__right {
        .rw-calendar-popup.rw-popup-container {
          left: unset !important;
          right: -6px !important;
        }
      }
    }

    .reports__dropdowns {
      margin-bottom: 5px;
      @include override-multiple-mids (196px, initial, 10px 0 15px 0, 1px solid $abbey, 29px, 64px, auto, 0 3px, 1px solid $alto, auto, 90%, 12px);
      @include autofill-dropdowns-disabled-mixin;
    }
    .reports__selection {
      .search-box {
        &__menu-list {
          max-height: 300px !important;
        }
      }

      &.en-width {
        .search-box__menu {
          width: calc(100% + 25px);
          margin-left: -25px;
        }
      }
      &.el-width {
        .search-box__menu{
          width: calc(100% + 70px);
          margin-left: -70px;
        }
      }
      &--title {
        margin-top: 10px !important;
      }
    }

    &__button-container {
      @include flex-row(flex-end, center);
      height: 40px;

      &__reset-link {
        @include button-as-url($lochmara);
        position: absolute;
        right: 20px;
        font-size: 9px;
      }
    }

    .reports-format-radius-section {
      margin: 7px 15px 15px 0;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .format--radio {
        cursor: pointer;
        @include radio-btns($bahama-blue);
        width: auto;
        display: flex;
        flex-direction: column;

        .format {
            margin-left: 5px;
            cursor: pointer;
        }

        label {
          display: inline-flex;
          align-items: flex-start;
          @include font-mixin(normal, normal, 600, 13px, 14px, $open-sans);
          margin-bottom: 6px;
          cursor: pointer;
        }
      }
    }
  }
}

.reports-allmids-cbox {
  &__wrapper {
    @include flex-row (flex-start, center);
    margin-top: 20px;
  }

  margin-bottom: 0;
  position: relative;
  @include custom-checkbox($tango, $tango, $white, 2px 0 0 0, 2px 5px 6px 0, 2px 1px 0 0);
}

.reports-allmids-select {
  @include override-multiple-mids (196px, 29px, 10px 0 15px 0, 1px solid $abbey, 29px, 64px, auto, 0 3px, 1px solid $alto, auto, 90%);

  &__wrapper {
    @include flex-row (flex-start, flex-start);
    cursor: pointer;
  }
  &__info-button {
    width: 5%;
    padding: 0;
    margin-left: 5px;
    visibility: hidden;
    /* Makes input not-clickable */
    position: absolute;
    /* Remove input from document flow */
    &-label {
      margin-top: 11px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.submit-reports-request {
  &__wrapper {
    @include flex-row(center, center);
    padding-top: 10px;
  }

  &__btn {
    @include btns-mixin(auto, 0, $tango, 50%, 1.2, $white, transparent);
    cursor: pointer;
    &:hover {
      @include hover-focus-states($tango, $midnight-blue, $white, $white);
    }
    &.btn-disabled {
      @include btns-mixin(auto, 0, $alto, 50%, 1.2, $white, transparent);
      cursor: not-allowed;
      &:hover {
        @include btns-mixin(auto, 0, $alto, 50%, 1.2, $white, transparent);
      }
    }
  }
}

.social-btns {
  margin-top: 20px;
  margin-bottom: 17px;
  @include single-container-wrap;
  padding: 20px;
  &__header {
    @include font-mixin(normal, normal, 400, 13px, 15px, $open-sans);
    color: $shark;
    margin-top: 0 !important;
  }
  &__row {
      @include flex-row(space-between, flex-end);
  }
  &__wrapper {
    @include flex-row(flex-start, flex-end);

    .icon-social-fb {
      font-size: 16px;
      color: $facebook;
    }

    .icon-social-instagram {
      font-size: 16px;
      color: $instagram;
    }

    .icon-social-linkedin {
      font-size: 16px;
      color: $linkedin;
    }

    .icon-youtube {
      font-size: 16px;
      color: $youtube;
    }
  }
  &__link {
    @include font-mixin(normal, normal, 400, 12px, 14px, $open-sans);
    color: $bahama-blue;
  }
  a {
    margin-right: 15px;
  }
}

.youtube-align {
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: -2.5px;
}


@media screen and (min-width: $tablet-width-limit) and (max-width: 1320px) {
  .right-column {
    font-size: 12px;
  }

  .support-form #support-tabs ul li {
    font-size: 0.8em;
  }
}

.search-per-store {
  flex-grow: 3;
  > div {
    @include search-field (261px, $wild-sand, 10px, $silver);
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .right-column {
    padding: 0 !important;
    margin-top: -10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }

  #collapsible-panel-download-reports {
    margin-top: 30px;
  }

  .reports__selection {
    .search-box {
      &__element {
        z-index: 2;
      }
      &__menu {
        width: 100% !important;
        margin-left: 0 !important;
        z-index: 3;
        &-list {
          max-height: unset !important;
        }
      }
    }
  }

  .support-form {
    #support-tabs ul li {
      font-size: 0.85em;
      height: 40px;
      &:first-child {
        width: 52%;
      }
      &:nth-child(2) {
        width: 48%;
      }
      > a {
        line-height: 1.2;
        height: 40px;
        > span {
          height: 30px;
          display: inline-block;
        }
      }
    }


    #support-tabs-pane-1 {
      p:first-child {
        font-size: 1.1em !important;
        line-height: 1.4 !important;
      }
      p button {
        font-size: 0.69em !important;
      }
    }
  }

  .appo-banner-6571 > div > div {
    margin: 33px auto 0 auto !important;
    display: block !important;
  }
}

