.support-modal {
  &_header {
    border-bottom: 0 !important;
    @include override-modal-close-btn;
    .modal-title {
      color: $midnight-blue;
      text-align: center;
      padding-top: 10px;
    }
  }

  &__dialog {
    width: 480px;
  }
}

.support-form {
  &__content {
    .loader__wrapper {
      margin: 0 -15px
    }
  }

  &__block {
    @include flex-column(space-between, stretch);
    height: 50px;
    @include dropdown-btn-mixin;
    width: auto !important;
    > span {
      color: $midnight-blue;
    }

    button {
      width: 205px !important;
      &:disabled {
        .caret {
          border-color: $bombay !important;

        }
        &:hover {
          background-color: $light-alto !important;
        }
      }
    }

    &__row {
      @include flex-row(flex-start, flex-start);
      margin-top: 20px;
    }

    .dropdown-menu {
      width: 205px !important;
    }

    .single-element-value {
      width: 200px;
      margin-right: 20px;
      padding-left: 5px;
    }
  }

  &__textarea {
    @include flex-column(space-between, stretch);
    textarea {
      @include font-mixin(normal, normal, 400, 12px, 1.2, $open-sans);
      margin-top: 5px;
      width: 430px;
      height: 200px !important;
      padding: 5px 10px;
      outline: none !important;
      resize: vertical;
      &:disabled {
        opacity: 0.65;
        cursor: not-allowed;
      }
    }

    > span {
      color: $midnight-blue;
    }
  }
  &__mid-dropdown {
    width: 220px;
  }
  &__mid-dropdown,
  &__tid-dropdown {
    height: 28px;
    .search-box {
      $sb: &;
      width: 205px;
      margin-top: 2px !important;
      &__control {
        width: 205px !important;
        margin-top: 2px !important;
        border: 1px solid $alto !important;
        min-height: 26px;
        &--is-disabled {
          width: 205px !important;
          margin: 2px 0 0 0 !important;
          cursor: not-allowed !important;
          opacity: 0.65;
          background-color: $light-alto !important;
          color: $cornflower-blue!important;
          min-height: 26px;
          #{$sb}__indicators {
            border: 0 !important;
          }
        }
        &--is-focused {
          width: 205px !important;
          margin-top: 0 !important;
        }
      }

      &__single-value,
      &__option {
        @include font-mixin(normal, normal, 400, 12px, 1.2, $open-sans);
      }

      &__placeholder{
        span {
          @include font-mixin(normal, normal, 400, 12px, 1.2, $open-sans);
          color: $abbey;
        }
      }
    }

    @include autofill-dropdowns-mixin;
    @include autofill-dropdowns-disabled-mixin;
  }

  &__file-attachment {
    margin-top: 25px;
    ul {
      list-style: none;
    }
    section > .dropzone {
      margin-bottom: 30px;
      cursor: pointer;
    }
    h2 {
      font-size: 14px;
      color: $midnight-blue;
      font-weight: 500;
    }
    ul {
      margin-left: 0 !important;
      @include new-margin-block(20px);
    }
  }

  &__remove-attachment {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
  }

  &__dropzone {
    width: 430px;
    border: 1px dashed $tango;
    padding: 15px;
    font-size: 12px;
  }


  &__button-wrapper {
    margin-top: 10px;
    @include flex-row(center, center);
    font-weight: 600;
    button {
      @include btns-mixin(auto, 0, $tango, auto, 1.2, $white, $tango);
      @include hover-focus-states ($tango, $midnight-blue, $white, $white);
      cursor: pointer;
      padding: 0 20px;
      &:disabled {
        @include btns-mixin(auto, 0, $alto, auto, 1.2, $white, $alto);
        cursor: not-allowed;
        padding: 0 20px;
      }
    }
  }
}

.support-email-response {
  min-height: 120px;
  $ser: &;
  h4 {
    color: $midnight-blue;
    margin-top: 0 !important;
    @include flex-column(space-between, center);
    > span:first-child {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 5px;
      font-size: 35px;
    }
    #{$ser}__msg {
      margin-top: 25px;
      text-align: center;
    }
    .icon-success {
      color: $limeade;
    }
    .icon-failed {
      color: $bright-red;
    }
  }
}

.support-error-file {
  color: $bright-red;
  font-size: 10px;
  margin-bottom: 15px;
  @include flex-row(flex-start, center);
  &__text {
  }
  &__icon{
    margin-right: 5px;
    display: inline-block;
    vertical-align: baseline;
  }
}


@media screen and (max-width: $tablet-width-limit) {
  .support-modal {
    &__dialog {
      width: calc(100% - 20px) !important;
    }
  }

  .support-form {
    &__block {
      &--tid {
        margin-top: 20px;
      }
      &__row {
        flex-wrap: wrap;
      }
    }
    &__textarea {
      width: 100% !important;
      textarea {
        width: 100% !important;
      }
    }
    &__file-attachment {
      section {
        width: 100% !important;
      }
    }
    &__dropzone {
      width: 100% !important;
    }
  }

  .support-error-file {
    display: inline-block !important
  }
}