header {
  height: 70px;
  @include flex-row(space-between, center);
  position: fixed;
  width: 100%;
  top:0;
  background-color: $white;
  z-index: 9;
  padding: 0 20px 0 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  border-bottom: 0.5px solid #DBDBDB;
  .header-logo-wrapper {
    flex-grow: 3;
  }

  .logo {
    //flex-grow: 3;
    //flex-grow: 0;
  }

  .header__right-part {
    @include flex-row(center, center);
    flex-grow: 0;
    .switch-locale {
      color: $midnight-blue;
      .locale-selected {
        color: $midnight-blue;
        font-weight: 600;
      }
    }
  }

  .user {
    flex-grow: 1;
    span:nth-child(2) {
      margin-left: 5px;
    }
    @include flex-row(flex-start, center);
    span {
      @include font-mixin(normal, normal, 400, 16px, 1, ($open-sans));
      color: $midnight-blue;
    }
    &__initials {
      margin-left: 15px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: $white;
      border: 2px solid $tango;
      color: $tango;
      @include font-mixin(normal, normal, 300, 22px, 1, ($open-sans));
      @include flex-column(center, center);
      cursor: pointer;
    }

    &__image {
      margin-left: 15px;
      img {
        width: 48px;
      }
    }

    .notification-img {
      margin-left: 15px;
      width: 20px;
      height: 21px;
      margin-top: 3px;
    }
  }

  .profile-link {
    @include flex-row(space-between, center);
  }
}

@media screen and (max-width: $common-medium-limit) {
  header {
    .logo {
      margin-left: 60px;
    }
  }
}
@media screen and (max-width: $tablet-width-limit) {
  header {
    .logo {
      margin-left: 60px;
      margin-top: -10px;
      img {
        width: 7.7em !important;
        height: auto !important;
      }
    }

    .user {
      display: none !important;
    }

    .header__right-part {
      align-items: flex-start !important;
    }
  }
}