@font-face {
    font-family: "Calibri";
    src: url("../fonts/calibri/calibri.eot");
    src:
            url("../fonts/calibri/calibri.woff") format("woff"),
            url("../fonts/calibri/calibri.woff2") format("woff2"),
            url("../fonts/calibri/calibri.ttf") format("truetype"),
            url("../fonts/calibri/calibri.otf") format("opentype");
}

@font-face {
    font-family: "Calibri";
    src:
            url("../fonts/calibri/calibri-bold.woff") format("woff"),
            url("../fonts/calibri/calibri-bold.woff2") format("woff2"),
            url("../fonts/calibri/calibri-bold.ttf") format("truetype"),
            url("../fonts/calibri/calibri-bold.otf") format("opentype");
    font-weight: bold;
}

