@import 'common/variables';
@import "~bootstrap3/dist/css/bootstrap.css";
@import "~bootstrap3/dist/css/bootstrap-theme.css";
@import 'common/hotSpots';
@import 'common/tooltip';
@import 'common/introDialog';
@import 'common/loader';
@import 'common/generic';
@import 'standaloneViews/introScreen';
@import 'standaloneViews/login';
@import 'standaloneViews/resetLogin';
@import 'standaloneViews/registerMerchant';
@import 'standaloneViews/userDetails';
@import 'standaloneViews/resetSteps';
@import 'standaloneViews/userDetails';
@import 'standaloneViews/expiredPassword';
@import 'standaloneViews/regsitrationInformation';
@import 'mainFrame/header';
@import 'mainFrame/sidebar';
@import 'mainFrame/topMainRenderer';
@import 'mainFrame/rightColumn';
@import 'sidebar/userProfile';
@import 'sidebar/orderStatus';
@import 'sidebar/userManagement';
@import 'overview/overview';
@import 'overview/dashboard';
@import 'business/business';
@import 'business/detailsModal';
@import 'business/debitNotes';
@import 'transactions/transactions';
@import 'transactions/table';
@import 'transactions/details';
@import 'reports/reports';
@import 'supportForms/supportModal';
@import 'mainFrame/footer';
@import 'standaloneViews/errorPage';
@import 'bankTotals/bankTotals';
@import "../css/pfm.css";
@import "../css/cookiebot.css";
@import "~amcharts3/amcharts/plugins/export/export.css";
@import "~@fortawesome/fontawesome-free/css/all.css";


