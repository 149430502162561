
.new-password-form {
  @include reset-login(100vh, 110px);
  overflow-y: visible !important;

  &__custom-modal {
    width: 20%;
    margin-top: 10%;
  }

  &__right {
    @include flex-column(space-between, flex-start);
  }

  &__info-button-label {
    margin-left: 5px;
    cursor: pointer;
  }

  &__info-button {
    width: 5%;
    padding: 0;
    margin-left: 5px;
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
  }

  &__wrong-creds {
    @include icon-font-mixin(0.7em, red);
    margin-top: -10px;
    margin-bottom: 10px;
  }

  &__button-area {
    @include flex-row(center, center);
    width: 100%;
    margin: 0 0 15px 0;
  }

  &__button {
    @include login-btns-mixin(45%, 15px, $tango, 150px, 15px);
  }

  &__icon-password-eye {
    @include password-eye(7px, 0);
  }
}

.new-phone-form {
  @include reset-login(100vh, 110px);
  overflow-y: visible !important;

  &__wrong-creds {
    @include icon-font-mixin(0.7em, red);
    margin-top: -10px;
    margin-bottom: 10px;
  }

  &__button-area {
    @include flex-row(center, center);
    width: 100%;
    margin: 0 0 15px 0;
  }

  &__button {
    @include login-btns-mixin(45%, 15px, $tango, 150px, 15px);
  }
}


.otp-form {
  @include reset-login(100vh, 110px);
  overflow-y: visible !important;

  &__modal {
    width: 20%;
    margin-top: 10%;
  }

  &__wrong-creds {
    @include icon-font-mixin(0.7em, red);
    margin-top: -10px;
    margin-bottom: 10px;
  }

  &__resend-button {
    @include btns-mixin(70%, 15px, white, 150px, 15px, $lochmara, 2px solid $lochmara);
  }

  form {
    @include flex-column(flex-start, center);
  }

  &__digit {
    @include flex-column(flex-start, center);
  }

  &__digit-title {
    color: $midnight-blue;
    font-weight: 600;
  }

  &__digit-column {
    @include flex-column(center, center);
  }

  &__button-area {
    @include flex-row(center, center);
    width: 100%;
    margin: 0 0 15px 0;
  }

  &__login-button {
    @include login-btns-mixin(45%, 15px, $tango, 150px, 15px);

    &__gray {
      @include login-btns-mixin(45%, 15px, $bombay, 150px, 15px);

      &.btn-disabled {
        cursor: not-allowed;
      }
    }
  }


  &__code {
    width: 45% !important;
    text-align: center;
  }

  &__few {
    margin: 21px 0 10px 0;
    font-size: 0.35em;
  }
}