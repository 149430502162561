@charset "UTF-8";

@font-face {
  font-family: "pfm";
  src:url("../fonts/pfm.eot");
  src:url("../fonts/pfm.eot?#iefix") format("embedded-opentype"),
    url("../fonts/pfm.woff") format("woff"),
    url("../fonts/pfm.ttf") format("truetype"),
    url("../fonts/pfm.svg#pfm") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "pfm" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "pfm" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-social-fb:before {
  content: "\77";
}
.icon-logo-cardlink:before {
  content: "\76";
}
.icon-ico-chat-bubble:before {
  content: "\78";
}
.icon-icon-company:before {
  content: "\79";
}
.icon-ico-countdown-clock:before {
  content: "\61";
}
.icon-icon-dashboard:before {
  content: "\62";
}
.icon-social-instagram:before {
  content: "\64";
}
.icon-social-linkedin:before {
  content: "\65";
}
.icon-logoff:before {
  content: "\6f";
}
.icon-icon-mob:before {
  content: "\68";
}
.icon-notification:before {
  content: "\69";
}
.icon-icon-reports:before {
  content: "\6b";
}
.icon-icon-tour:before {
  content: "\6c";
}
.icon-icon-transactions:before {
  content: "\6d";
}
.icon-social-twitter:before {
  content: "\74";
}
.icon-icon-attention:before {
  content: "\66";
}
.icon-icon-check:before {
  content: "\6e";
}
.icon-icon-pos:before {
  content: "\70";
}
.icon-icon-ecommerce:before {
  content: "\71";
}
.icon-icon-info:before {
  content: "\72";
}
.icon-x-icon:before {
  content: "\73";
}
.icon-minus-less:before {
  content: "\75";
}
.icon-right-open-1:before {
  content: "\7a";
}
.icon-right-open:before {
  content: "\41";
}
.icon-edit-pencil:before {
  content: "\42";
}
.icon-lock:before {
  content: "\43";
}
.icon-circled-plus:before {
  content: "\44";
}
.icon-plus-accordion:before {
  content: "\46";
}
.icon-failed:before {
  content: "\47";
}
.icon-pending:before {
  content: "\48";
}
.icon-more-questions:before {
  content: "\4a";
}
.icon-magnifier-showall:before {
  content: "\4c";
}
.icon-download-icon:before {
  content: "\63";
}
.icon-circle-check:before {
  content: "\4e";
}
.icon-circle-clock:before {
  content: "\4f";
}
.icon-saita:before {
  content: "\50";
}
.icon-youtube:before {
  content: "\51";
}
.icon-kpi-arrow:before {
  content: "\52";
}
.icon-printer:before {
  content: "\53";
}
.icon-show-password-eye:before {
  content: "\54";
}
.icon-hide-password-eye:before {
  content: "\55";
}
.icon-circled-minus:before {
  content: "\56";
}
.icon-paperclip:before {
  content: "\57";
}
.icon-anepityxis:before {
  content: "\49";
}
.icon-epityxis:before {
  content: "\58";
}
.icon-notification-filled:before {
  content: "\59";
}
.icon-kleismeno-paketo:before {
  content: "\30";
}
.icon-magnifier-icon:before {
  content: "\67";
}
.icon-minus-accordion:before {
  content: "\45";
}
.icon-no-data:before {
  content: "\6a";
}
.icon-success:before {
  content: "\4b";
}
.icon-call:before {
  content: "\4d";
}
