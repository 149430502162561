.business {
  @include single-container-wrap;

  &-page {
    @include main-page(0 20px 0 155px);
    overflow: visible !important;
    min-height: calc(100vh - 200px);

    &__right-part {
      @include flex-column(space-between, flex-end);
      padding: 0 0 0 10px !important;
      text-align: left;
      z-index: 2;
    }
  }
}

.__excel-button {
  text-shadow: none !important;
  position: relative;
  width: 245px;
  height: 38px;
  border-radius: 20px;
  color: $alabaster;
  background: $tango none;
  border: 1px solid transparent !important;
  margin-top: -50px;
  @include download-btn-mixin;
  @include hover-focus-states($tango, $midnight-blue, $white, $white);
}

.__mid-excel-button {
  text-shadow: none !important;
  position: absolute;
  top: 65px;
  left: 15px;
  width: 245px;
  height: 38px;
  border-radius: 20px;
  color: $alabaster;
  background: $tango none;
  border: 1px solid transparent !important;
  margin-top: -50px;
  @include download-btn-mixin;
  @include hover-focus-states($tango, $midnight-blue, $white, $white);

  &.disabled {
    opacity: 0.7;
    background-color: $bombay !important;
  }
}

.logo-area {
  width: 130px;
  height: 80px;
}

.file-label {
  color: $pelorous;
  cursor: pointer;
  @include font-mixin(normal, normal, normal, 0.6em, 1.3, ('Open Sans', sans-serif));
}

.file-label:hover {
  text-decoration: underline;
}

#file_input_id {
  display: none;
}

.company-details {
  @include font-mixin(normal, normal, normal, 20px, 1.3, ('Open Sans', sans-serif));

  &__text {
    @include font-mixin(normal, normal, normal, 0.8em, 1.3, ($open-sans));
  }

  &__container {
    @include flex-row (space-between, center);

    > div {
      &:first-child {
        flex-grow: 0;
        padding-right: 25px;
      }

      &:not(:first-child) {
        flex-grow: 3;
      }
    }
  }
}

.business-row {
  @include flex-row(flex-start, stretch);

  &__elements {
    flex-wrap: wrap;
    margin: 5px 15px 0 0;

    &_logo {
      margin: 0 5rem 1.5rem 0;
    }

    &_item {
      margin-right: 40px;
      flex: 0.3 1 !important;
      margin-bottom: 15px;
    }
  }
}

#company-Tk-popover {
  top: 80px !important;
  left: 385.17px !important;
}

#company-Tk-popover-en {
  top: 83px !important;
  left: 278.17px !important;
}

.business-column {
  @include flex-column(flex-start, stretch);
}

.text-highlight {
  color: #64BCC3;

  &-edit {
    text-decoration: underline dotted #64BCC3;
  }
}

.legal-rep-dropdown {
  margin-top: 5px;

  &:before {
    @include caret-horizontal($tango, 3, rotate(-45deg));
    content: "";
    transition: transform 0.3s linear;
  }

  &.legal-rep-dropdown-arrow-down:before {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.legal-rep-dropdown-arrow-right:before {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

.legal-rep {
  background-color: $wild-sand;

  &.legal-rep-on {
    display: block;
  }

  &.legal-rep-off {
    display: none;
  }
}

.billing {
  background-color: $wild-sand;

  &.billing-on {
    display: flex;
  }

  &.billing-off {
    display: none;
  }
}

.business-modal {

}


#collapsible-panel-company-elements {
  @include business-accordion;
}

.company-elements__title__wrapper {
  @include flex-row(flex-end, stretch);

  > span:nth-child(2) {
    margin-left: 5px;
  }
}


#collapsible-panel-company-elements--body {
  .panel-body {
    padding: 10px 15px !important;

    .business-column {
      > span {
        @include font-mixin(normal, normal, 700, 9px, 1.4, $open-sans);
        color: $shark;
      }

      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        flex: 0.1;
      }

      &:last-child {
        flex: 0.45 !important;
      }
    }

    .business-element__value {
      @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
      color: $shark;
    }
  }
}

.business-areas {
  @include single-container-wrap;
  margin: 0 !important;
  padding: 10px 15px 25px 15px;
  position: relative;

  h4 {
    @include business-title-mixin;

    &:before {
      @include left-pseudo-border($tango, 13px);
    }
  }

  &__company-tk-info {
    @include font-mixin (normal, normal, 400, 10px, 16px, $open-sans);
    margin-top: 0;
  }
}

.area {
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid $gallery;

  &__dragscroll {
    overflow: hidden !important;
    box-sizing: content-box;
    cursor: grab;
    margin-top: 20px;
    margin-bottom: -17px;
    padding-bottom: 17px;
  }

  &__tabs {
    width: auto;
    display: inline-flex;
    position: relative;
  }

  &__option {
    margin: 0 40px 0 0;
    display: inline-flex;
    white-space: nowrap;
    align-items: flex-start;
    height: 22px;
     a {
      @include font-mixin (normal, normal, 600, 11px, 35px, $open-sans);
      color: $shark !important;
      padding: 0 5px;
      white-space: nowrap;
    }

    &:hover:not(.business-area-selected) {
       a {
        color: $tango !important;
      }
    }

    &.business-area-selected {
      border-bottom: 4px solid $tango;;
      padding-bottom: 21px;
      margin-top: 10px;

       a {
        @include font-mixin (normal, normal, 600, 13px, 1.2, $open-sans);
      }
    }
  }

  &__tab-content {
    margin: 0 -15px;
    padding: 0 15px;
    height: calc(100% - 360px);
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid #B4B5B8;
  }

  &__details {
    margin-top: 30px;
  }

  &__name {
    margin-top: 0;
    margin-bottom: 0;
    @include font-mixin(normal, normal, 600, 11px, 1, $open-sans);
    color: $tango;
  }

  &__address {
    @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
  }

  &__mid {
    @include font-mixin(normal, normal, 700, 11px, 13px, $open-sans);
    margin-top: 3px;
  }

  &__alias {
    margin-top: 6px;

    > span {
      @include font-mixin(normal, normal, 600, 10px, 1.2, $open-sans);
      border-radius: 12px;
      background-color: $affair;
      color: $white;
      padding: 1px 6px;
    }
  }

  &__row {
    max-width: 900px;
    flex-wrap: wrap;
    align-items: flex-start !important;
    .business-clickable {
      margin-right: 50px;
      margin-top: 20px;
      cursor: pointer;
      width: 250px;
    }
  }
}

.company-logo-submit-form {
  margin-top: 25px;

  button {
    color: $white;
  }
}

.modal-right {
  @include flex-row(flex-end, stretch);
}

.business-popover-toggle {
  position: relative;
  text-shadow: none !important;
  width: 235px;
  height: 38px;
  border-radius: 20px;
  color: $white;
  background: $tango none;
  border: 1px solid transparent !important;
  margin-top: -50px;
  @include font-mixin(normal, normal, 400, 13px, 1, $open-sans);
  @include flex-row(space-between, center);

  &__loader {
    @include flex-row(flex-end, center);
    margin-top: -75px;
    margin-right: 80px;
  }

  > span:first-child {
    transition: opacity .3s;
    position: absolute;
  }

  > span:nth-child(2) {
    transition: opacity .3s;
    opacity: 0;
  }

  > .icons {
    position: relative;
    width: 14px;
    height: 14px;
    padding-right: 16px;

    i {
      position: absolute;
    }

    .icon-download-icon {
      transition: opacity .3s, transform .3s;
      color: $white;
    }

    .icon-x-icon {
      transition: opacity .3s, transform .3s;
      transform: rotate(-180deg) scale(.5);
      opacity: 0;
    }
  }

  &.triggered {
    background: $affair none !important;
    text-shadow: none !important;
    @include hover-focus-states ($affair, rgba(125, 65, 153, 0.25), $white, $affair);
    &:hover,
    &:active,
    &:focus:hover,
    &:active:focus {
      .icon-x-icon {
        color: $affair;
      }
    }
    &:focus {
      .icon-x-icon {
        color: $white;
      }
    }

    .icon-download-icon {
      transform: rotate(180deg) scale(.5);
      opacity: 0;
    }

    .icon-x-icon {
      transform: rotate(0deg) scale(1);
      opacity: 1;
      color: $white;
    }

    > span:first-child {
      filter: alpha(opacity=0);
      opacity: 0;
    }

    > span:nth-child(2) {
      filter: alpha(opacity=1);
      opacity: 1;
    }
  }

  &.move-to-right {;
    @include transform(translateX(calc(100% + 43px)))
  }

  &.not-triggered {
   @include hover-focus-states($tango, $midnight-blue, $white, $white);
  }
}

#business-popover-positioned-bottom {
  @include maxWidthHeight(737px, 560px);
  @include font-mixin(normal, normal, 400, 12px, 1, $open-sans);
  position: absolute;
  z-index: 2;
  color: $abbey;
  width: 350px;
  top: -12px !important;
  left: auto !important;
  right: 325px !important;
  background-color: $alabaster;
  border: 1px solid $mercury;

  > .arrow {
    border-bottom-color: $mercury !important;
    left: calc(100% - 100px) !important;

    &:after {
      border-bottom-color: $alabaster;
    }
  }

  .popover-content {
    padding: 10px 10px !important;

  }
}

#business-export-form-mid {
  top: 75px !important;
  left: 780px !important;

  > .arrow {
    top: 50% !important;
  }
}

.business-popover-modal {
  @include flex-column(flex-start, space-between);

  > #export-reset {
    margin-bottom: 20px;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
  }

  > form {
    @include flex-column(flex-start, stretch);

    > div {
      @include custom-checkbox($tango, $tango, $white, - 7 px 0 0 0, - 3 px 8px 0 0, 3px 1px 0 0);
      margin: 10px !important;

      > label {
        > span {
          position: relative;
          top: 1px;
          margin: 5px;
          text-align: center;
        }
      }
    }

    > span {
      margin: 10px !important;
    }

    > button {
      margin: 10px auto;
      padding: 10px;
      color: $white;
      background-color: $tango;
      border: 0;
      width: 75%;
      border-radius: 100px;

    }

    > button:disabled {
      border-color: #BDBDBD;
      color: #666 !important;
      background-color: $light-alto !important;
      cursor: not-allowed;
    }
  }
}

.dropdown {
  min-width: 190px;
  @include override-dropdown(0);
  &-menu {
    width: 100% !important;
  }
  .companies-export-in-dropdown {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 260px;
  }
  #dropdown-companies-export {
    border-bottom: 2px solid $midnight-blue;
    color: $white;
    text-shadow: unset !important;
    width: 100%;
    min-width: 190px;
    font-family: $open-sans;
    @include flex-row(space-between, center);
    background: $lochmara no-repeat 90% 50%;
    -webkit-appearance: none;
    border-radius: 1px;

    .caret {
      @include override-caret($white, 4px);
    }
  }

  &.open {
    .caret {
      @include override-caret-reversed;
    }
  }

}

.business-export-form {
  .business-allmids-select {
    @include override-multiple-mids (196px, initial, 10px 0 0 0, 1px solid $abbey, 30px, 64px, auto, 0 3px, 1px solid $alto, auto);
  }

  &__single-afm {
    margin-left: 11px;
  }

  &__multiple-mid-wrapper {
    @include flex-row(flex-start, flex-start);

    label {
      &:before {
        content: none !important;
      }
    }
  }

  &__multiple-tid-wrapper {
    @include flex-row(flex-start, flex-start);

    label {
      &:before {
        content: none !important;
      }
    }
  }

  &__info-button {
    width: 5%;
    padding: 0;
    margin-left: 5px;
    visibility: hidden;
    position: absolute;
  }
}

.alias-tltps-wrapper {
  position: relative;
  > span {
    font: normal normal 700 9px/1.4 $open-sans;
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .business-page {
    margin: 20px 20px 0 20px !important;
    height: auto !important;

    h4:before {
      display: none !important;
    }
  }

  #business-export-form-mid {
    top: -2px !important;
    left: 0 !important;
    right: 550px;
  }

  .search-fast {
    &__business {
      width: 65% !important;
      margin-left: auto !important;
      margin-top: 0 !important;
      margin-right: 8px;
      .search-fast {
        z-index: 1 !important;
      }

      .search-fast-label {
        display: none !important;
      }
      .top-main-renderer__multiple-mids-label {
        right: -17px !important;
        top: 22px !important;
      }
      .search-fast__header-tooltip {
        width: 100%;
      }
    }

    &__single-merchant {
      width: 100% !important;
    }
  }

  .business-popover-toggle {
    width: calc(35% - 20px) !important;
    margin-top: -120px !important;
    transition: width 0.3s ease;

    &.triggered {
      width: 35px !important;
      height: 35px !important;
      margin-left: 12px;
      position: relative;
    }

    > span:first-child {
      color: transparent !important;

      &:before {
        color: $white;
        content: "excel";
      }
    }

    > span:nth-child(2) {
      color: transparent !important;
    }

    .icon-download-icon {
      margin-left: -14px !important;
    }

    .icon-x-icon {
      position: absolute;
      color: $white;
      left: -55.5px;
    }

    &__loader {
      position: absolute !important;
      top: -66px;
      justify-content: flex-start !important;
    }
  }

  .reduced-renderer-height {
    height: 110px !important;
    flex-direction: column-reverse !important;
  }

  .lesser-renderer-height {
    height: 25px !important;
    h3 {
      margin-left: 30px !important;
      max-width: calc(100% - 30px) !important;
    }
  }

  .business-row__elements {
    justify-content: space-between !important;

    &_logo {
      margin: 0 20px 30px 0 !important;

      > img {
        max-width: 100px;
      }
    }

    .business-column {
      flex: 3 0 auto !important;
      margin-right: 5%;
      margin-bottom: 25px;
    }
  }
  .business-areas {
    border-radius: 0 !important;

    &__company-tk-info {
      display: none !important;
    }

    .area {
      &__dragscroll {
        padding-right: 15px;
        margin-top: 15px;
    }
      &__tabs {
        padding: 0 !important;
        overflow-x: auto;
        width: calc(100% + 10px);
      }

      &__option {
        margin-right: 20px !important;
        &__header-tooltip {
          @include flex-row(flex-start, flex-start);
        }
        &:first-child {
          margin-left: 10px !important;
        }
      }
      &__tab-content {
        margin: 0 !important;
        padding: 0 !important;
        overflow-x: auto;
      }
    }
  }

  #collapsible-panel-company-elements--heading h4 {
    max-width: calc(100% - 40px);
  }

  #collapsible-panel-company-elements--body .panel-body .business-column:last-child {
    flex: 5 1 auto !important;
    max-width: 70%;
  }

  #collapsible-panel-company-elements {
    border-radius: 0 !important;
  }


  .top-main-renderer__wrapper {
    .dropdown {
      width: 100% !important;
    }
  }

  .area__row .business-clickable {
    width: 100%;
    min-width: 160px;
  }

  #business-popover-positioned-bottom {
    position: absolute !important;
    max-width: none;
    max-height: none;
    width: 100% !important;
    top: -82px !important;
    left: 0 !important;

    > .arrow {
      left: 29px !important;
    }

    .popover-content .search-modal {
      flex-direction: column !important;

      .search-modal__column {
        width: 100% !important;
      }
    }
  }

  .modal-right {
    @include flex-column(flex-start, space-between);;
  }
  .__excel-button {
    margin: -44px 0 0 0 !important;
  }
}
