.slide-pane {
  $sp: &;
  &__user-management {
    #{$sp}__content {
      background-color: $gallery !important;
    }
  }
}

.user-management {
  &__title-and-download-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__excel-button {
    margin-top: -15px !important;
    position: relative;
    border-radius: 20px;
    color: $alabaster;
    background: $tango none;
    border: 1px solid transparent !important;
    @include download-btn-mixin;
    width: 22px !important;
    height: 22px !important;

  }
  &__title {
    @include font-mixin(normal, normal, 400, 16px, 1.2, $open-sans);
    color: $midnight-blue;
    margin-top: -15px !important;
  }
  &__caption {
    @include font-mixin(normal, normal, 400, 9px, 13px, $open-sans);
    color: $shark;
    margin-bottom: 20px;
  }
  &__item {
    @include user-modal-tile(120px, 10px, 15px 15px 10px 15px, $white);
    @include flex-column(space-between, stretch);
  }
  &__name-email {
    @include flex-column(stretch, flex-start);
    margin-bottom: 5px;
  }
  &__user {
    @include font-mixin(normal, normal, 700, 9px, 1.2, $open-sans);
    margin: 0 0 5px 10px;
    &.single__title {
      margin-bottom: 2px !important;
    }
    &.double__title:nth-child(2) {
      margin-bottom: 2px !important;
    }
    &.super-user {
      color: $affair;
      &.double__title:before{
        @include left-pseudo-border($affair, 10px, 15px);
      }
      &.single__title:before{
        @include left-pseudo-border($affair, 28px, 15px);
      }
    }
    &.legal-representative {
      color: $tango;
      &.double__title:before{
        @include left-pseudo-border($tango, 10px, 15px);
      }
      &.single__title:before{
        @include left-pseudo-border($tango, 28px, 15px);
      }
    }
    &.simple-user {
      color: $bahama-blue;
      &.double__title:before{
        @include left-pseudo-border($bahama-blue, 10px, 15px);
      }
      &.single__title:before{
        @include left-pseudo-border($bahama-blue, 28px, 15px);
      }
    }
  }

  &__types-status {
    @include flex-row(space-between, flex-start);
  }

  &__switch-label-value{
    @include font-mixin(normal, normal, 400, 9px, 1.2, $open-sans);
    color: $shark;
  }

  &__user-switch {
    @include cbox-switch($bahama-blue, $glacier, $silver, $seashel, 5px 0 0 auto);
    @include flex-column(stretch, flex-start);
    position: absolute;
    right: 15px;
  }

  &__name {
    @include font-mixin(normal, normal, 300, 15px, 1, $open-sans);
    color: $shark;
    margin-bottom: 3px;
    margin-top: 3px;
  }
  &__email {
    @include font-mixin(normal, normal, 400, 11px, 1, $open-sans);
    color: $midnight-blue;
    margin-bottom: 5px;
  }
  &__dates {
    @include flex-row(space-between, flex-start);
    height: 25px;
    margin-top: 15px;
    .added-on {
      @include flex-column(stretch, flex-start);
    }
    .last-connect{
      @include flex-column(stretch, flex-end);
    }
    .added-on,
    .last-connect {
      height: inherit;
      > span:first-child {
        @include font-mixin(normal, normal, 400, 9px, 1, $open-sans);
      }
      > span:nth-child(2) {
        @include font-mixin(normal, normal, 400, 11px, 1, $open-sans);
        margin-top: 3px;
      }
    }
  }
  &__disabled {
    background-color: $concrete !important;
    label,
    input {
      display: none !important;
    }
  }
}


@media screen and (max-width: $tablet-width-limit) {
  .slide-pane {
    &__user-management {
      width: 100% !important;
      height: 100% !important;
    }
    .user-profile__item {
      padding: 15px !important;
    }
  }
}
