.bank-totals-wrapper {
  margin-top: 20px;
  .empty-chart {
    margin-left: 5%;
  }
}

.bank-totals-spacer {
  min-height: 600px;
  position: relative;
}

.bank-totals-table-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1106px;
  padding: 30px 0 50px 0;
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid $alto;
  }
}

.bank-totals-title-wrapper {
  @include flex-row(space-between, flex-end);
}

.section_title {
  @include font-mixin (normal, normal, 400, 16px, 1.2, $open-sans);
  color: $midnight-blue;
  background-color: transparent !important;
}


.label-to-right {
  @include font-mixin (normal, normal, 400, 16px, 1.2, $open-sans);
  color: $midnight-blue;
  background-color: transparent !important;
}

.bank-totals-table {
  border: 0 !important;
  width: 100%;

  thead {
    tr {
      th {
        padding: 15px !important;
        text-align: center;
        border-bottom: $bombay solid 2px;

        &:first-child {
          border: 0 !important;
        }

        > span {
          font-size: 11px;
        }
      }
    }
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: $alabaster;
    }

    &:nth-last-child(2) {
      border-bottom: $bombay solid 2px !important;
    }

    &:nth-last-child(1) {
      td {
        &:nth-child(10) {
          font-weight: 700;
        }

        &:nth-child(11) {
          font-weight: 700;
        }
      }
    }

    td {
      border: 0 !important;
      padding: 15px !important;
      position: relative;
      @include font-mixin(normal, normal, 400, 11px, 1.2, $open-sans);
      text-align: right;
      white-space: nowrap;

      &:first-child {
        text-align: left;
        white-space: nowrap;
        border: 0 !important;
        @include font-mixin(normal, normal, 700, 11px, 1.2, $open-sans);
      }

      &:nth-child(2) {
        border-left: $bombay solid 1px !important;
      }

      &:nth-child(4) {
        border-left: $bombay solid 1px !important;
      }

      &:nth-child(6) {
        border-left: $bombay solid 1px !important;
      }

      &:nth-child(8) {
        border-left: $bombay solid 1px !important;
      }

      &:nth-child(10) {
        border-left: $bombay solid 1px !important;
      }

      &:nth-child(11) {
        border-right: $bombay solid 1px !important;
      }
    }
  }

  &__empty-state {
    @include flex-column(flex-start, center);
    min-height: calc(100vh - 360px);
  }
}

.bank-totals-search {
  @include flex-column(flex-start, flex-start);
  margin: 0 auto;
  max-width: 1106px;
  width: 100%;

  &__row {
    @include flex-row(space-between, flex-start);

    > div {
      margin: 10px;
    }
  }

  &__column {
    @include flex-column(space-between, stretch);

    .datetimepickers-wrapper {
      display: flex;
      margin-bottom: 40px;
      .rw-datetime-picker {
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
    }

    > span {
      margin: 0 10px 10px 0;
      font-size: 11px;
      color: #00245D;
    }
  }
}

.bank-totals-multi-mids {
  @include override-multiple-mids(230px, 35px, 25px 0 0 0, 2px solid $lochmara, 35px, 35px, 35px, 0 8px, 2px solid $alto, auto);
}

.bank-totals-popover-wrapper {
  top: 181px;
  @include custom-checkbox($tango, $tango, $white, - 7 px 0 0 0, -3px 8px 0 0, 3px 1px 0 0);

  label {
    color: #595A5C;
    margin: 10px 10px 0 0;
  }

  &__excel-button {
    @include download-btn-mixin;
    display: block;
    border-radius: 50px;
    border: 0 !important;
    margin-top: -8px;
    //text-shadow: none !important;
    //position: relative;
    //top: 85px;
    //right: -20px;
    //width: 157px;
    //height: 38px;
    //border-radius: 20px;
    //color: $white;
    //background: $tango none;
    //border: 1px solid transparent !important;
    //margin-top: -50px;

    &:disabled {
      background-color: $light-alto !important;
      cursor: not-allowed;
    }
  }
}

.bank-totals-popover-toggle {
  width: 210px;
  height: 35px;
  border-radius: 20px;
  border: 1px solid $affair;
  color: $jumbo;
  background: $wild-sand none !important;
  @include flex-row(space-between, center);

  &.not-triggered {
    @include hover-focus-states ($wild-sand, rgba(125, 65, 153, 0.25), $jumbo, $affair);
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $affair;
    text-shadow: none;
    box-shadow: none !important;
  }

  > span:first-child {
    transition: opacity .3s;
    position: absolute;
  }

  > span:nth-child(2) {
    transition: opacity .3s;
    opacity: 0;
  }

  > .icons {
    position: relative;
    width: 14px;
    height: 14px;
    padding-right: 16px;

    i {
      position: absolute;
    }

    .icon-ico-magnifier {
      transition: opacity .3s, transform .3s;
      color: $affair;
    }

    .icon-x-icon {
      transition: opacity .3s, transform .3s;
      transform: rotate(-180deg) scale(.5);
      opacity: 0;
    }
  }

  &.triggered {
    color: $white !important;
    background: $affair none !important;
    text-shadow: none !important;
    @include hover-focus-states ($affair, rgba(125, 65, 153, 0.25), $white, $affair);

    &:hover,
    &:active,
    &:focus:hover,
    &:active:focus {
      .icon-x-icon {
        color: $affair;
      }
    }

    &:focus {
      .icon-x-icon {
        color: $white;
      }
    }

    .icon-ico-magnifier {
      transform: rotate(180deg) scale(.5);
      opacity: 0;
    }

    .icon-x-icon {
      transform: rotate(0deg) scale(1);
      opacity: 1;
      color: $white;
    }

    > span:first-child {
      filter: alpha(opacity=0);
      opacity: 0;
    }

    > span:nth-child(2) {
      filter: alpha(opacity=1);
      opacity: 1;
    }
  }

  &.move-to-right {;
    @include transform(translateX(calc(100% + 43px)))
  }
}

@media screen and (max-width: $common-medium-limit) {
  .bank-totals-table {
    overflow: auto !important;
    display: block;
    &-wrapper {
      overflow: hidden;
      max-width: calc(100% - 30px);
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {

  .label-to-right {
    font-size: 12px !important;
    text-align: center !important;
  }

  .bank-totals {

    &-title-wrapper {
      align-items: center !important;
    }

    &-title-wrapper {
      flex-direction: column !important;
    }

    &-search__row {
      flex-direction: column !important;
      justify-content: flex-start !important;
      margin-top: 15px;
    }

    &-multi-mids {
      margin-top: 0;
    }

    &-table__empty-state {
      min-height: 35vh;
    }
  }
}

@media screen and (max-width: $min-mobile-limit) {


  #bank-totals-popover-positioned-bottom {
    width: 260px;
  }

  .bank-totals-popover-toggle {
    > .popover-content {
      width: 100px;
    }
  }
}