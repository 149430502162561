#collapsible-panel-company-details {
  @include business-accordion;
  .panel-body {
    padding: 10px 15px !important;
  }
}
#collapsible-panel-contact-details {
  .business-details__column {
    &--address {
      min-width: calc(210px + 25px) !important;
      &.single-stats {
        min-width: calc(315px + 50px) !important;
      }
    }
    &--sub {
      max-width: 105px !important;
      min-width: 105px !important;
    }
    &--city {
      max-width: 105px !important;
      min-width: 105px !important;
    }
  }
}

.business-modal {
  padding: 10px 40px;
  background-color: $darker-alabaster;
  &__header {
    border: none !important;
    background-color: $darker-alabaster;
      @include override-modal-close-btn;
  }

  &__content {
    position: relative;
  }

  .support-business__wrapper {
    h5 {
      font-size: 11px;
    }
    @include flex-column(center, center);
    button {
      @include blue-border-btn-mixin(0 0 10px 0, 11px, 6px 20px);
      padding: 5px 20px;
    }
  }
}

#collapsible-panel-bank-collaborators {
  @include business-accordion;
  .bank-collaborators {
    @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
    @include flex-row(flex-start, flex-end);
    flex-wrap: wrap;
    &__item {
      margin-right: 40px;
    }
  }
}

#collapsible-panel-contact-details,
#collapsible-panel-terminal-details,
#collapsible-panel-billing-type,
#collapsible-panel-payments-and-fees {

  @include business-accordion;
}

#collapsible-panel-billing-type {
  .business-details {
    &__column {
      @include flex-column(space-evenly, stretch);
    }
    &__value {
      margin-top: 3px !important;
    }
  }
}

#collapsible-panel-terminal-details {

  .panel-body {
    padding-top: 5px !important;
  }

  .collapsed {
    .active-state__column--label {
      display: none !important;
    }
  }
}

  .active-state__column--label {
    @include font-mixin(normal, normal, 400, 12px, 1.2, $open-sans);
    color: $shark;
    margin: -19px 0 5px 1px;
  }

  .business-row__details {
    flex-wrap: wrap;
    &--billing {
      @include flex-row(space-between, flex-start);
    }
    .business-details {
      &__column {
        flex: 1;
        margin-right: 25px;
        margin-bottom: 25px;
        > span {
          @include font-mixin(normal, normal, 700, 9px, 1.4, $open-sans);
          color: $shark;
        }
      }
      &__value {
        @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
        color: $shark;
      }
    }
  }

  .editable-field {
    @include editable-fields (calc(210px + 25px));
  }
  .change-postal-code {
    @include editable-fields(105px);
    &__btn {
      min-width: 105px;
      max-width: 105px;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  .business-row__pos {
    @include flex-row (flex-start, flex-start);
    flex-wrap: wrap;
    border-bottom: 1px solid $bombay;
    padding-bottom: 20px;
  }

  .multiple-stats__content {
    #business-merchantAlias-popover-positioned-bottom,
    #business-terminalAlias-popover-positioned-bottom,
    #business-postCode-popover-positioned-bottom {
      margin-top: 52px;
      > .arrow {
        left: 50% !important;
      }
    }

    #business-merchantAlias-popover-positioned-bottom {
      margin-top: 52px;
      left: 205px !important;
    }

    #business-postCode-popover-positioned-bottom {
      left: 205px !important;
    }

    #business-terminalAlias-popover-positioned-bottom {
      margin-top: 52px !important;
      left: 75px !important;
    }
  }


  .popover__business-modal {
    button {
      border: none;
      background: none;
      &.icon-x-icon {
        font-size: 11px;
        color: $red;
      }
      &.icon-icon-check {
        font-size: 15px;
        color: $limeade;
      }
    }
    input {
      padding-left: 5px;
    }
  }

  .receipt-modal-row {
    @include flex-row(flex-start, center);

    &__elements {
      flex-wrap: wrap;
      margin: 5px 15px 0 0;

      &_logo {
        margin: 0 5rem 1.5rem 0;
      }

      &_item {
        margin-right: 40px;
        flex: 0.3 1 !important;
        margin-bottom: 15px;
      }
    }
  }

  .popover__receipt-modal {
    button {
      border: none;
      background: none;
      &.icon-saita {
        font-size: 15px;
        color: $limeade;
        margin-left: 7px;

        &:disabled {
          color: $light-alto !important;
        }
      }
      &.icon-x-icon {
        font-size: 6px;
        color: $jumbo;
        position: absolute;
        left: 92%;
        top: 8%;
      }
    }
    input {
      padding-left: 5px;
    }
    div {
      &.support-error-file {
        margin-bottom: 0 !important;
      }
    }
  }
  .editable-popover-label {
    color: $tango;
    margin-bottom: 10px;
  }

  .active-state {
    &__column {
      @include flex-column(space-around, flex-start);
      &--left{
        width: 115px;
      }

      &--dropdowns {
        @include flex-row(flex-start, stretch)
      }

      .dropdown {
        @include dropdown-btn-mixin;
        .dropdown-menu {
          min-width: 145px !important;
          max-width: 145px !important;
        }
      }
    }
    &__circle {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 50%;
    }
    &__red {
      background-color: $red;
    }
    &__green {
      background-color: $limeade;
    }
    &__label {
      @include font-mixin(normal, normal, 700, 9px, 1.6, $open-sans);
      margin-left: 3px;
    }
    &__value {
      @include font-mixin(normal, normal, 400, 9px, 1.6, $open-sans);
      margin-left: 3px;
    }
  }

  .business-row__tid-values__top {
    padding: 20px 0 0 0;
    justify-content: flex-start !important;
    .business-details__column {
        flex: 1 1 !important;
        max-width: 105px;
        min-width: 105px;
        white-space: pre-wrap;
        word-break: break-word;
    }
  }

  .business-row__tid-values__bottom {
    align-items: flex-start !important;
    padding: 0 0 20px 0;
    border-bottom: 1px solid $bombay;
    .business-details__column {
      max-width: 105px;
      min-width: 105px;
      white-space: pre-wrap;
      word-break: break-word;
      &:first-child {
         .business-details__value {
           width: 105px !important;
         }
      }
      &:last-child {
        min-width: 100px;
      }
    }
  }

  .tid-bank__details {
    &_column {
      height: 80px;
      @include flex-column(space-around, flex-end);
      font-size: 10px;
    }
    &_wrapper {
      padding-top: 35px;
    }
    &_row {
      text-align: right !important;
      margin: 5px 0;
    }
    &_acquirers_row {
      margin-top: -20px;
      @include flex-row(space-around, stretch);
      width: 100%;

      &_ecom {
        margin-top: -20px;
        @include flex-row(space-around, stretch);
        width: 250px;
      }
    }
    &_acquirer-label {
      @include font-mixin(normal, normal, 700, 9px, 1.2, $open-sans);
      margin-bottom: 5px;
      text-align: center;
    }
    &_acquirer-value {
      text-align: center;
      @include flex-row(center, center);
      &:nth-child(2) {
        margin-top: 12px;
        height: 14px !important;
      }
      &:nth-child(3) {
        height: 14px !important;
        margin-top: 15px;
      }
      &:nth-child(4) {
        height: 28px !important;
        margin-top: 23px;
      }
      .icon-icon-check {
        font-size: 10px;
      }
      .icon-x-icon {
        font-size: 8px;
      }
    }
    &_acquirers_column {
      @include flex-column(flex-start, center);
      height: 100px;
    }
  }

  .icon-red {
    color: $red;
  }

  .icon-green {
    color: $limeade;
  }

  #show-ecom-btn {
    @include reset-btn-styles;
    @include blue-border-btn-mixin(0, 14px, 6px 12px);
    position: relative;
    width: 212px;
    height: 38px;
    border-radius: 20px;
    @include flex-row(space-between, center);
  }

  .dropdown__ecoms--wrapper {
    position: relative;
    .dropdown {
      @include override-dropdown(0 0 0);
      .caret {
        @include override-caret($bahama-blue, 3px);
        margin-right: -10px;
        margin-left: 10px;
      }
      &.open {
        .caret {
          @include override-caret-reversed;
        }
      }
      button {
        color: $bahama-blue;
        padding: 6px 20px 6px 12px;
        font-size: 14px;
        border: 1px solid $bahama-blue;
        text-shadow: unset !important;
        min-width: 190px;
        max-width: 275px;
        font-family: $open-sans;
        @include flex-row(space-between, center);
        background: $white none !important;
        -webkit-appearance: none;
        border-radius: 1px;
        box-shadow: none !important;
        &:hover,
        &:active,
        &:focus:hover,
        &:active:focus,
        &:focus {
          background-color: $white !important;
          border: 1px solid $bahama-blue !important;
          color: $bahama-blue !important;
        }
      }
      .dropdown-menu {
        > li {
          height: auto !important;
          margin-bottom: 5px;
          > a {
            white-space: normal !important;
          }
        }
      }
    }
  }


@mixin grid-top-titles($fontSize, $col, $span) {
  @include font-mixin(normal, normal, 700, $fontSize, 1.2, $open-sans);
  grid-column: $col / span $span;
  text-align: center;
}

.fees-table {
 &__container {
   .table-responsive {
     border: none !important;
   }
 }
  &__caption {
    @include flex-column(flex-start, flex-start);
    padding-top: 0 !important;
    width: 100%;
    margin-bottom: 10px;
    .fees-table__iban {
      &--title {
        @include font-mixin(normal, normal, 700, 9px, 1.4, $open-sans);
        color: $shark;
      }
      &--value {
        @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
      }
    }
  }
  th {
    width: 25%;
    padding-left: 0 !important;
    border: none !important;
    @include font-mixin(normal, normal, 700, 9px, 1.4, $open-sans);
    color: $shark;
  }
  td {
    width: 25%;
    padding: 3px 0 !important;
    border: none !important;
    @include font-mixin(normal, normal, 300, 15px, 1.2, $open-sans);
    &:not(.no-data) {
      text-transform: capitalize;
    }
    &.no-data {
      padding-top: 10px;
    }
  }
}

  @media screen and (max-width: $tablet-width-limit) {
    #show-ecom-btn {
      width: calc(35% - 20px) !important;
      > span:first-child {
        color: transparent !important;
        width: inherit !important;
        &:before {
          color: $bahama-blue;
          content: "e-shop";
        }
      }
    }
    .company-elements__title__wrapper {
      display: block !important;
    }

    .business-details__value {
      white-space: pre-wrap;
    }

    .business-modal {
      padding: 10px !important;
    }
    .tid-bank__details_column {
      width: 20%;
      margin-right: 5px;
    }

    .tid-bank__details_acquirers_column {
      margin-left: 5px;
    }

    #collapsible-panel-company-details {
      .business-row__details {
        flex-direction: column !important;
      }

      .business-details__column {
        flex: auto !important;
        margin-bottom: 10px !important;
      }
    }

    #collapsible-panel-contact-details {
      .business-details__column {
        &--address {
          min-width: 23vw !important;
          max-width: 23vw !important;
        }
        &--area {
          min-width: 23vw !important;
          max-width: 23vw !important;
        }
        &--city {
          min-width: 23vw !important;
          max-width: 23vw !important;
        }
        &--empty-helper {
          min-width: 23vw !important;
          max-width: 23vw !important;
        }
        &.change-postal-code__btn {
          min-width: 23vw !important;
          max-width: 23vw !important;
        }
        &--sub {
          min-width: 23vw !important;
          max-width: 23vw !important;
        }
      }
    }
    #collapsible-panel-terminal-details {
      .business-details__column {
        &--duo,
        &--serial-num,
        &--status-pos {
          min-width: 25vw;
          max-width: unset !important;
        }
        &--trio {
          min-width: 20vw;
          max-width: unset !important;
        }
      }
      .business-details__column {
        &--address {
          min-width: 52vw !important;
        }
        &--area {
          max-width: 105px;
        }
        &--empty-helper {
          max-width: 105px;
        }
      }
    }

    .bank-collaborators__item {
      margin-bottom: 10px;
      margin-right: 20px;
    }

    .business-row__pos {
      padding-bottom: 0!important;

      .active-state__pos {
        margin-bottom: 10px;
      }
      .active-state__column {
        margin-bottom: 15px;
        width: 145px !important;
        margin-right: 15px;
        &--label {
          height: 28px;
          margin-top: 5px;
        }
        &--dropdowns{
          flex-wrap: wrap;
        }
        .dropdown {
          button {
            margin-right: 0  !important;
            width: 145px !important;
            min-width: 120px !important;
          }
        }
      }
    }
    .active-state__column--label {
      left: 15px !important;
      top: 98px !important;
    }
    .active-state__column--dropdowns {
      margin-top: 20px;
      width: 100%;
    }
    #collapsible-panel-billing-type {
      .business-details {
        &__column {
          min-width: 20vw !important;
        }
      }
    }
    .dropdown__ecoms--wrapper {
      .dropdown {
        min-width: unset !important;
        &-menu {
          left: unset !important;
          right: 0;
        }
        button {
          min-width: unset !important;
          width: 92px !important;
          > span {
            &:first-child {
              color: transparent !important;
              &:before {
                content: "e-shops";
                color: $bahama-blue;
              }
            }
            &.caret {
              position: absolute !important;
              right: 20px !important;
            }
          }
        }
      }
    }
  }


  @media screen and (max-width: 375px) {
    .tid-bank__details_row {
      font-size: 9px !important;
    }
  }


@media screen and (max-width: 320px) {
  .active-state__column {
    .dropdown {
      button {
        font-size: 9px !important;
      }
    }
  }
}