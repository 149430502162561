@mixin reports-title-mixin($margin) {
  @include font-mixin (normal, normal, 400, 16px, 1.2, $open-sans);
  color: $midnight-blue;
  margin: $margin !important;
  background-color: transparent !important;
}

@mixin donut-charts($padding) {
  &--chart {
    @include single-container-wrap;
    padding: 15px;

    .amcharts-title {
      transform: translate(111px, 104px);
    }

    &_wrapper {
      padding: $padding !important;
      min-height: 190px;
    }
  }

  &--transactions-count {
    padding: 12px 15px 10px 15px;
  }

  &--section {
    &_title {
      @include reports-title-mixin(0)
    }

    &_pre-title {
      @include font-mixin (normal, normal, 900, 9px, 1.2, $open-sans);
      color: $midnight-blue;
      margin: 0 !important;
      height: 20px;

      &:before {
        @include left-pseudo-border($tango, 12px);
      }
    }
  }
}

.reports {
  &__transactions {
    @include single-container-wrap;
    position: relative;
    padding-top: 12px;
    margin: 0 !important;

    &--chart {
      padding: 0 0 0 15px !important;
    }

    &--companies_selection {
      border-left: 1px solid $alto;
      height: 355px;
      margin-top: -19px;
      padding-left: 20px !important;
      overflow-y: auto;
    }

    &--section_title {
      @include reports-title-mixin(0 0 0 15px);

      &:before {
        @include left-pseudo-border($tango, 13px);
        margin-top: 3px;
      }
    }
    &--company_cbox {
      @include custom-checkbox($white, $tango, $tango, -7px 0 0 0, -3px 8px 0 0, 2px 1px 0 0);
      margin-bottom: 15px;
        label {
            line-height: 18px !important;
            text-overflow: ellipsis;
            overflow: hidden;
          > span:first-child {
            margin-top: -11px;
          }
        }
        &--header {
          @include font-mixin(normal, normal, 400, 12px, 14px, $open-sans);
          color: $abbey;
          height: 50px;
          max-width: 160px;
          display: block;
          margin-bottom: 10px;
        }
      }
  }

  &-page {
    @include main-page(0 20px 0 155px);
    @include charts-timespan-dropdown-mixin(inherit);
  }

  &__bank {
    @include donut-charts(0 10px 0 0);
    &--chart {
      min-height: 440px;
      @include override-chart-div(50%, -20px, 0 0 0 -59.55px);
    }
    &-cards {
      margin: 20px 0 0 0;
    }
    &--section_title {
      max-width: 150px;
    }
  }

  &__card {
    @include donut-charts(0 0 0 10px);
    //&--section {
    //  &_pre-title {
    //    &:before {
    //      left: 9px !important;
    //    }
    //  }
    //}
    &--chart {
      @include override-chart-div(50%, -20px, 0 0 0 -59.55px);
      min-height: 334px;
      position: relative;
      /* mastercard label width fix */
      .grenadier {
        td {
          min-width: 60px !important;
        }
      }
      .empty-chart {
        min-height: 192px !important;
      }
    }
  }

  &__chart-switch {
    margin: 60px 0 20px 0;
    white-space: nowrap;
    @include cbox-switch($tango, $rajah, $glacier, $midnight-blue, 0 10px 0 10px);
    @include flex-row(flex-start, flex-start);
    width: 100%;
    &--blue-purple {
      @include cbox-switch($affair, $london-hue, $glacier, $midnight-blue, 0 10px 0 10px);
      width: calc(100% - 255px) !important;
      margin-left: 40px;
    }
    &--blue-orange-reversed {
      @include cbox-switch($midnight-blue, $glacier, $rajah, $tango, 0 10px 0 10px);
      width: calc(100% - 255px) !important;
    }
    &.cards-switch {
      width: 220px !important;
      position: relative;
      margin-left: 47px;
      background-color: transparent;
    }

    .report-translate {
      color: $bombay;
      font-weight: 600;
      &_active {
        font-weight: 600;
        &.blue__switch {
          color: $midnight-blue;
        }
        &.orange__switch {
          color: $tango;
        }
        &.count-label {
          color: $affair;
        }
        &.amount-label {
          color: $midnight-blue;
        }
      }
    }
  }

  &__counts {
    @include flex-row(space-between, flex-start);
    @include donut-charts(10px 10px 10px 10px);
    margin-top: 10px;
    min-height: 95px;
  }

  &--counts {
    @include flex-column(stretch, flex-start);
    margin: 0 auto 0 auto;
    .charts-icon {
      width: 14px;
      height: 14px;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
    }
    .charts-icon + span {
      @include font-mixin(normal, normal, 700, 11px, 1.2, $open-sans);
      max-width: 120px;
      color: $mine-shaft;
      display: inline-block;
    }
    .icon-circle-check {
      color: $affair;
    }
    .icon-circle-clock {
      color: $tango;
    }
    > span {
      font-size: 30px;
      margin-left: 19px;
      color: $dove-gray;
    }
  }


  &__transaction-types {
    @include donut-charts(10px 0 10px 0);
    position: relative;
    padding-top: 12px;
    margin: 0 !important;
    @include override-chart-div(0, -54px, 0);
  }
}

.empty_flex_div {
  width: 211px;
  height: 20px;
}

.cards-switch {
  margin: 20px 0 20px 0;
  &__wrapper {
    @include flex-row(space-evenly, flex-start);
    width: 100%;
    bottom: 0;
  }
}

.donut-chart {
  &--cards {
    @include flex-row(space-evenly, flex-start);
    width: 100%;
  }
  &--banks {
    @include flex-column(space-evenly, center);
    .react-bootstrap-table {
      width: 100%;
    }
  }
  &-tbl-hdr {
    @include flex-column(flex-end, stretch);
    flex-wrap: wrap;
    &--item {
      white-space: nowrap;
      &:nth-child(2) {
        margin-left: 4px;
      }
    }
  }
}

.donut-chart-table {
  margin-top: 20px;
  margin-bottom: 0;
  border: 0 !important;
  min-width: 211px;
  thead tr th {
    border: 0 !important;
    padding: 2px !important;
    white-space: nowrap;
    @include font-mixin(normal, normal, 400, 11px, 1.2, $open-sans);
    &:nth-child(2),
    &:nth-child(3) {
      text-align: right;
    }
  }
  tbody tr {
    &.jacksons-purple {
      @include donut-chart-table-row($jacksons-purple);
    }
    &.grenadier {
      @include donut-chart-table-row($grenadier);
    }
    &.sunglow {
      @include donut-chart-table-row($sunglow);
    }
    &.pelorous {
      @include donut-chart-table-row($pelorous);
    }
    &.blumine {
      @include donut-chart-table-row($blumine);
    }
    &.carrot-orange {
      @include donut-chart-table-row($carrot-orange);
    }
    &.bahama-blue {
      @include donut-chart-table-row($bahama-blue);
    }
    &.midnight-blue {
      @include donut-chart-table-row($midnight-blue);
    }
    &.deep-sea {
      @include donut-chart-table-row($deep-sea);
    }
    &.sangria {
      @include donut-chart-table-row($sangria);
    }
    &.jumbo {
      @include donut-chart-table-row($jumbo);
    }
    &.malibu {
      @include donut-chart-table-row($malibu);
    }
    &.thunderbird {
      @include donut-chart-table-row($thunderbird);
    }
    &.forest-green {
      @include donut-chart-table-row($forest-green);
    }
    td {
      border: 0 !important;
      padding: 2px !important;
      position: relative;
      &:first-child {
        @include font-mixin(normal, normal, 700, 11px, 1.2, $open-sans);
        padding-left: 15px !important;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 5px;
          width: 8px;
          height: 8px;
          display: block;
          border-radius: 50%;
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        @include font-mixin(normal, normal, 400, 13px, 1.2, $open-sans);
        text-align: right;
      }
      &:nth-child(3){
        padding-left: 10px !important;
      }
    }
  }
}


.report-totals-table {
  @include flex-column(stretch, flex-end);
  margin: 10px;
}

.reports-table-header {
  background-color: #999999;
}

.reports-table-row {
  margin: 10px;
  padding: 10px;
}

.chart-right {
  justify-content: flex-end !important;
  padding-right: 45px !important;
  margin: 0 0 5px 0 !important;
}

.chart-left {
  position: relative;
}

.date-align-right {
  float: right;

  &__wrapper {
    @include flex-row(space-between, flex-start);
    color: $midnight-blue;
  }

  &__flex {
    display: flex;
    justify-content: flex-end;
  }
}

.submit-reports-request__wrapper {
  button {
    min-width: 30% !important;
  }
}

.bank-symbol-container {
  @include flex-column(flex-start, center);
  margin-top: 30px;
}

.bank-stacked-chart-wrapper {
  margin-left: 10px;
  display: block;
  position: relative;
  width:60%;
}

.redemption-bank-label {
  display: none;
}

.stacked-chart-table {
  border: 0 !important;
  margin-top: 30px;d
  thead tr th {
    border: 0 !important;
    padding: 2px 5px !important;
    @include font-mixin(normal, normal, 400, 11px, 1.2, $open-sans);
    text-align: right;
  }

  tbody tr {
    td {
      border: 0 !important;
      padding: 2px 5px !important;
      position: relative;
      @include font-mixin(normal, normal, 400, 13px, 1.2, $open-sans);
      &:first-child {
        white-space: nowrap;
      }
      &:nth-child(2){
        min-width: 60px !important;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        text-align: right;
      }
      &:nth-child(3),
      &:nth-child(4) {
        min-width: 90px;
      }
    }
    &:last-child {
      td {
        font-weight: 600;
        &:first-child {
          color: transparent !important;
        }
        &:nth-child(3) {
          color: $affair;
        }
        &:nth-child(4) {
          color: $tango;
        }
      }
    }
  }
}

.bank-symbol-container {
  @include flex-column(flex-start, center);
  margin-top: 30px;
}

.bank-stacked-chart-container {
  position: relative;
  @include flex-row(space-between, flex-start);
  @include override-chart-div(0, 0, 0);
  .react-bootstrap-table {
    display: block;
    margin-right: 15px;
  }
  &:not(.last-item) {
    border-bottom:  1px solid $alto;
  }
}


.stacked-chart-table {
  border: 0 !important;
  margin-top: 30px;
  thead tr th {
    border: 0 !important;
    padding: 2px 5px !important;
    @include font-mixin(normal, normal, 400, 11px, 1.2, $open-sans);
    text-align: right;
  }

  tbody tr td {
    border: 0 !important;
    padding: 2px 5px !important;
    position: relative;
    @include font-mixin(normal, normal, 400, 13px, 1.2, $open-sans);
    min-width: 56px;
    &:first-child {
      white-space: nowrap;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      text-align: right;
    }
  }
}

.reports__installments--chart{
  padding: 0 15px !important;
  margin-top: -15px !important;
}

.installments__gauge {
  @include flex-column(space-between, flex-start);
  height: 400px;
  margin-top: -60px;
  border-left: 1px solid $alto;
  padding: 30px 0 30px 20px !important;
  z-index: 0;
  min-width: 190px;
}

.installments-date-dropdown {
  @include flex-row(flex-end, center);
}

.per-period {
  &__wrapper {
    color: $lochmara;
    margin-top: 60px;
    h6 {
      margin-bottom: 3px;
    }
    padding-left: 22px;
  }
  &__value {
    font-size: 22px;
  }

  &__type {
    font-size: 14px;
  }

  &__caption {
    color: $shark;
    font-size: 11px;
  }
}

.empty-chart {
  width: 100%;
  @include flex-column(center, center);
  min-height: 150px;
  &__text {
    color: $affair;
    font-size: 16px;
    margin-left: 20px;
  }
  &__icon {
    color: $affair;
    font-size: 70px;
    margin-bottom: 10px;
  }
}

.dates__chart-dropdown__wrapper {
  color: $midnight-blue;
  display: block;
  float: right;
  z-index: 1;
  .dropdown {
    z-index: 1 !important;
  }
  > div {
    text-align: right;
  }
}

.reports__redemption {
  &__table__wrapper{
    @include flex-column(center, flex-end);
  }
    &__total-sums {
      margin-right: 15px;
      min-width: 255px;
    &__wrapper {
        @include flex-row(flex-end, flex-start);
    }

    &__title {
      @include font-mixin(normal, normal, 700, 10px, 1.2, $open-sans);
      background-color: $alto;
      padding: 2px 5px;
      width: 100%;
      margin: 0 0 5px 0;
    }

    &__container {
      display: grid;
      grid-template-columns: auto auto auto;
      @include font-mixin(normal, normal, 700, 14px, 1.2, $open-sans);
    }

    &__item {
      text-align: right;
      padding: 2px 5px !important;
      &:first-child {
        min-width: 75px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        min-width: 90px;
      }
      &--card {
        color: $affair;
      }
      &--amount {
        color: $tango;
      }
    }
  }
}

/* Override charts styles */

div[id^=chart-export-btn] {
  position: absolute;
  //top: 50px;
}

#chart-export-btn__ {
  &TotalsTransactions {
    top: 5px;
    left: 5px;
  }
  &TransactionsWithInstallments{
    left: -25px;
  }
  &bank {
    margin-top: -3px;
    left: 5px;
  }
  &card {
    left: 5px;
    margin-top: 3px;
  }
  &transaction-types {
    margin-top: 4px;
    left: 5px;
  }
}

.amChartsInputField {
  height: 28px;
  margin-top: 5px;
}

#chart-export-btn__card {
  position: absolute;
  left: 15px;
}

.reports__transactions--chart {
  .amcharts-scrollbar-chart-div {
    overflow: visible !important;
  }
  @include override-chart-div(-5px, -240px, 0);
  .amChartsPeriodSelector {
    height: 16px !important;
    fieldset {
      > legend + div {
        position: absolute !important;
        bottom: -40px !important;
        right: 40px;
      }
    }
  }

  .amChartsButton.amcharts-period-input {
    background-color: transparent !important;
    color: $midnight-blue;
    border: 0 !important;
    background-image: url('../../assets/img/magnifier_showAll.svg');
    background-repeat: no-repeat;
    margin-left: 6px;
    margin-top: -5px;
    width: 150px;
    font-size: 10px;
    height: 26px !important;
    background-size: 16px;
    background-position: 18px 5px;
  }

  .amChartsButtonSelected.amcharts-period-input-selected {
    visibility: hidden !important;
    opacity: 0 !important;
    height: 26px !important;
  }
}

.redemption-chart-export-menu {
  opacity: 1 !important;
  display: inline-block;
  margin-top: -58px;
  .export-main > a {
    display: block;
    border-radius: 50px;
    @include download-btn-mixin;
  }
}

#collapsible-panel-redemption-chart,
#collapsible-panel-installments-chart,
#collapsible-panel-bank-totals {
  @include business-accordion;
  margin-top: 10px !important;

  .panel-body {
    padding: 10px 15px !important;

    .date-align-right__wrapper {
      justify-content: flex-end !important;
    }
  }
}

@media screen and (max-width: $common-medium-limit) {
  .date-align-right__wrapper {
    flex-wrap: wrap;
  }

  .installments__gauge {
    width: 100%;
    border-left: 0;
    align-items: center;
  }

  .reports__card--chart {
    .donut-chart--cards {
      flex-wrap: wrap;
      .react-bootstrap-table {
        width: 100%;
      }
    }
  }
  .reports__bank--chart {
    .donut-chart--banks {
      .react-bootstrap-table {
        width: 100%
      }
    }
  }


  #chart-export-btn__ {
    &bank {
      margin-top: 0 !important;
    }
  }

  .installments--chart {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .reports {
    &__chart-switch {
      &.chart-right {
        font-size: 12px !important;
      }
      &--blue-orange-reversed {
       margin-left: -35px;
      }
    }
    &__transactions--chart .amChartsPeriodSelector fieldset > legend + div {
      position: absolute !important;
      bottom: -45px !important;
      right: 0 !important;
      width: 101%;
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .reports {
    &-page {
      @include flex-reversed-column(flex-start, stretch);
      margin: 20px 20px 0 20px !important;
      flex-direction: column-reverse !important;
    }

    &__bank--chart_wrapper {
      padding: 0 !important;
    }

    &__card--chart_wrapper {
      margin-top: 20px;
      margin-left: -10px;
      margin-bottom: 20px;
      width: calc(100% + 10px) !important;
    }

    &__transaction-types {
      &--chart {
        .amcharts-scrollbar-vertical {
          display: none !important;
        }
      }
      &--section_title {
        max-width: 70% !important;
      }
    }

    &__transactions {
      &--section_title {
        max-width: 70% !important;
        display: inline;
      }
      &--company_cbox--header {
          max-width: none !important;
      }

      &--chart {
        padding: 0 !important;
        @include override-chart-div(10px, -240px, 0);
        &.installments--chart {
          @include override-chart-div(-2px, -240px, 0);
          padding: 0 0 0 15px !important;
          margin-top: 15px !important;
        }
      }

      &--companies_selection {
        margin-top: 0;
      }

      &--companies_selection {
        height: auto !important;
        border-left: 0 !important;
      }
    }
    &__total__transactions--chart {
      margin-top: 15px;
    }

    &__card--chart {
      min-height: 465px !important;
    }

    &__chart-switch {
      width: 100% !important;
      &--blue-purple {
        margin-top: 60px;
      }
      &--blue-orange-reversed {
        margin-top: 90px;
      }
    }

    &__card--section_pre-title:before {
      left: 0 !important;
    }

    &__counts {
      min-height: 180px !important;
      .date-align-right__wrapper {
        position: absolute !important;
        right: 15px !important;
      }
      .reports--counts {
        margin-top: 70px;
        > span:first-child {
          @include font-mixin(normal, normal, 300, 36px, 44px, $open-sans);
        }
      }
    }

    &__transaction-types {
      .date-align-right__wrapper {
        h5 + div {
          margin-top: -4px;
        }
      }
    }

    &__redemption__table__wrapper  {
      width: calc(100% - 15px);
      align-items: stretch;
      .stacked-chart-table {
        tbody tr td {
          min-width: unset !important;
        }
      }
    }

    &__redemption__total-sums {
      width: calc(100% + 15px) !important;
      margin-right: 0 !important;
      &__container {
        padding-right: 15px;
      }
    }
  }

  .reports__transactions-installments {
    margin: 0 !important;
  }

  .installments__outter__wrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .installments__gauge {
    height: auto !important;
    align-items: center !important;
    margin-top: -30px !important;
    border-left: 0 !important;
  }

  .empty_flex_div {
    width: 0 !important;
  }

  .empty-chart {
    margin: 30px 0 0 0;
    &__text {
      text-align: center !important;
    }
  }

  .chart-right {
    padding-right: 15px !important;
    margin-top: 20px !important;
  }

  .cards-switch {
    width: 100% !important;
    justify-content: center !important;
    margin: 15px 0 !important;
    &__wrapper {
      justify-content: center !important;
    }
  }
  .reports__card--chart {
    .donut-chart--cards {
      flex-direction: column !important;
      align-items: center !important;
      padding-bottom: 10px;
      .react-bootstrap-table {
        width: auto !important;
      }
    }
  }
  .reports__bank--chart {
    .donut-chart--banks {
      .react-bootstrap-table {
        width: auto !important;
      }
    }
  }

  .bank-stacked-chart-wrapper {
    display: none !important;
  }

  .bank-symbol-container {
    flex-direction: row !important;
    justify-content: flex-end !important;
    width: 100%;
    padding-right: 15px;
    background-color: $concrete;
    height: 32px;
    margin-top: 0 !important;
  }

  .bank-stacked-chart-container {
    flex-direction: column !important;
    border-bottom: 0 !important;
    &.first-item {
      margin-top: 30px;
    }
    .react-bootstrap-table {
      margin-right: 0 !important;
      .stacked-chart-table {
        margin-top: 15px !important;
      }
    }
  }

  .redemption-bank-label {
    display: inline-block !important;
    margin-right: 20px;
    @include font-mixin(normal, normal, 600, 12px, 21px, $open-sans)
  }

  .redemption-chart__outter-wrapper {
    margin-right: -15px !important;
  }

  #chart-export-btn__ {
    &TotalsTransactions {

    }
    &TransactionsWithInstallments{
      top: 25px !important;
    }
    &card {
      left: 5px !important;
    }
    &transaction-types {

    }
  }
}
