/* GOOGLE Fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

$open-sans: 'Open Sans', sans-serif;
$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;


/* NEW COLORS */
$bahama-blue: #005596;
$tango: #F47B20;
$midnight-blue: #00245D;
$cerulean: #0099D6;
$lochmara: #007DC3;
$affair: #7D4199;
$abbey: #595A5C;
$jumbo: #76777A;
$bombay: #B4B5B8;
$tan-hide: #F89D59;
$zircon: #EFF5FF;
$nepal: #9EB6C4;
$fan-green: #009F25;
$bright-red: #A50000;
$azure: #2e6da4;
$rajah: #F8AC72;
$link-water: #D4E5F2;
$shark: #323334;
$glacier: #7ea9ca;
$seashel: #f1f1f1;
$london-hue: #bd9fcb;
$red: #F70000;
$limeade: #54C700;
$concrete: #f2f2f2;
$malibu: #5AC6FF;
$french-pass: #a9dffc;
$white-pointer: #faf0ff;
$turquoise: #40e0d0;
/* The rest */
$alabaster: #FAFAFA;
$darker-alabaster: #f7f7f7;
$alto: #d0d0d0;
$mercury: #e5e5e5;
$mercury2: #E2E2E2;
$light-silver: #C4C4C4;
$cornflower-blue: #ccc;
$jacksons-purple: #322199;
$grenadier: #DE2402;
$sunglow: #FFC130;
$pelorous: #37A9B2;
$blumine: #1E478D;
$carrot-orange: #F28E1D;
$pelorous: #37A9B2;
$mine-shaft: #3c3c3c;
$deep-sea: #007E6B;
$sangria: #9F0000;
$forest-green: #1E8839;
$thunderbird: #CC1F1F;

/* OLD COLORS */

$eastern-blue: #20949D;
$silver: #b9b9b9;
$dark-silver: #c6c6c6;
$dark-alto: #d9d9d9;
$light-alto: #e0e0e0;
$gallery: #ebebeb;
$wild-sand: #f4f4f4;
$gray: #848484;
$dove-gray: #707070;
$scorpion: #606060;
$tundora: #484141;
$white: #ffffff;
$black: #000000;
$gauge-data: $tango;
$gauge-bg: $alto;
$primary: $dove-gray;
$secondary: $gray;

/* RESPONSIVE LIMITS */
$tablet-width-limit: 991px;
$common-medium-limit: 1335px;
$bigger-tablet-width-limit: 1024px;
$tablet-width-limit-plus-one: 992px;
$common-mobile-limit: 667px;
$min-mobile-limit: 320px;
$medium-mobile-limit: 400px;
$medium-mobile-limit-plus-a-hundred: 500px;
$medium-mobile-limit-plus-one: 401px;
$bg-wave-limit: 1280px;

/* SideBar */
$sidebar-width: 155px;

/* Popover Inputs */
$popover-input-height: 26px;
$popover-input-margin-top: 7px;

/* Social Media */
$facebook: #3c5a99;
$instagram: #8a49a1;
$linkedin: #0077b5;
$youtube: #e62117;

/* MIXINS */

@mixin font-mixin($style, $variant, $weight, $size, $line, $family: $open-sans) {
  font: $style $variant $weight #{$size}/#{$line} $family;
}

@mixin icon-font-mixin($size, $color) {
  font-size: $size;
  color: $color;
}

@mixin hover-focus-states($initialClr, $hoverClr, $initialFont, $hoverFont) {
  &:hover,
  &:active,
  &:focus:hover,
  &:active:focus {
    color: $hoverFont !important;
    background-color: $hoverClr !important;
  }
  &:focus {
    color: $initialFont !important;
    background-color: $initialClr !important;
  }
}

@mixin main-page($margin) {
  margin: $margin !important;
  position: relative;
  @include flex-row(space-between, stretch);
  .main-content {
    width: calc(100% - 300px);
  }
  @media screen and (max-width: $tablet-width-limit) {
    flex-direction: column !important;
    .main-content {
      width: 100% !important;
      padding: 0 !important;
    }
  }
}

@mixin bg-opacity($color, $opacity) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin box-shadow($h-offset, $v-offset, $blur, $spread, $color) {
  box-shadow: $h-offset $v-offset $blur $spread $color !important;
  -webkit-box-shadow: $h-offset $v-offset $blur $spread $color !important;
  -moz-box-shadow: $h-offset $v-offset $blur $spread $color;
}

@mixin flex-row($justify, $align) {
  display: flex;
  flex-direction: row;
  justify-content: $justify;
  align-items: $align;
}

@mixin inline-flex-row($justify, $align) {
  display: inline-flex;
  flex-direction: row;
  justify-content: $justify;
  align-items: $align;
}

@mixin inline-flex-column($justify, $align) {
  display: inline-flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-column($justify, $align) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-reversed-column($justify, $align) {
  display: flex;
  flex-direction: column-reverse;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-reversed-row($justify, $align) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: $justify;
  align-items: $align;
}

@mixin reset-margin-block {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

@mixin new-margin-block($margin) {
  margin-block-start: $margin;
  margin-block-end: $margin;
  margin-inline-start: $margin;
  margin-inline-end: $margin;
  padding-inline-start: $margin;
}

@mixin horizontal-flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

//@mixin linear-gradient ($color1, $color2) {
//    background-image: -webkit-linear-gradient(top, $color1 0%, $color2 100%);
//    background-image: -o-linear-gradient(top, $color1 0%, $color2 100%);
//    background-image: -webkit-gradient(linear, left top, left bottom, from($color1), to($color2));
//    background-image: linear-gradient(to bottom, $color1 0%, $color2 100%);
//}

@mixin button-mixin($color, $radius, $border) {
  color: $color;
  border-radius: $radius;
  border: $border;
}

@mixin overlay-mixin($bg-color, $opacity, $zindex) {
  width: 100%;
  height: 100%;
  opacity: $opacity;
  background-color: $bg-color;
  position: fixed;
  z-index: $zindex;
}

@mixin override-with-font {
  font-family: "pfm" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin navButton {
  @include font-mixin(normal, normal, 600, 10px, 1, $open-sans);
  color: $gray;
  padding: 6px 12px;
  border: 1px solid $gray;
  border-radius: 20px;
  margin-top: 15px;
}

@mixin maxWidthHeight($maxWidth, $maxHeight) {
  max-width: $maxWidth;
  max-height: $maxHeight;
}

@mixin reset-input-autofill {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
  }
}

@mixin reset-btn-styles {
  background-color: transparent;
  border: none;
  text-shadow: none !important;
  box-shadow: none !important;
  background-image: none !important;
}

@mixin transition($type, $duration, $timing) {
  -webkit-transition: $type $duration $timing;
  -moz-transition: $type $duration $timing;
  -o-transition: $type $duration $timing;
  -ms-transition: $type $duration $timing;
  transition: $type $duration $timing;
}

@mixin animation($name, $duration, $timing, $delay, $iteration, $direction) {
  -webkit-animation: $name $duration $timing $delay $iteration $direction;
  -moz-animation: $name $duration $timing $delay $iteration $direction;
  -ms-animation: $name $duration $timing $delay $iteration $direction;
}

@mixin transparent-shadow($shadow-color, $color) {
  text-shadow: 0 0 5px $shadow-color;
  color: $color;
}

@mixin transform($type) {
  -webkit-transform: $type;
  -moz-transform: $type;
  -ms-transform: $type;
  -o-transform: $type;
  transform: $type;
}

@mixin round-corners-rhombus($width, $height, $color, $radius) {
  width: $width;
  height: $height;
  background-color: $color;
  border-radius: $radius;
}

@mixin placeholder($color) {
  ::placeholder {
    color: $color;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $color;
  }

  ::-ms-input-placeholder {
    color: $color;
  }
}

@mixin btns-mixin($width, $marginTop, $bgColor, $min-width, $line-height, $color, $border, $radius: 30px, $fontSize: 14px) {
  border: $border;
  border-radius: $radius;
  padding: 0 10px;
  height: 30px;
  width: $width;
  margin-top: $marginTop;
  background: $bgColor none !important;
  min-width: $min-width;
  color: $color;
  font-size: 14px;
  &:hover,
  &:focus,
  &:active {
    background-position: 0 !important;
    background-color: $bgColor;
    box-shadow: none !important;
  }
}

@mixin login-btns-mixin($width, $marginTop, $bgColor, $min-width, $line-height, $color: $white, $border: none) {
  @include btns-mixin($width, $marginTop, $bgColor, $min-width, $line-height, $color, $border);
  border-color: transparent !important;
  @include hover-focus-states($bgColor, $midnight-blue, $color, $color)
}

@mixin single-container-wrap {
  @include box-shadow(5px, 6px, 7px, 0, rgba($black, 0.10));
  border-radius: 12px;
  background-color: $white;
}

@mixin override-panel {
  background-image: none !important;
}

@mixin override-caret($color, $padding) {
  border: solid $color;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: $padding;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -3px;
  transition: transform 0.3s linear;
}

@mixin override-caret-reversed {
  -webkit-transform: rotate(-135deg) !important;
  transform: rotate(-135deg) !important;
  transition: transform 0.3s linear;
  margin-top: 3px !important;
}

@mixin caret-horizontal($color, $padding, $rotate) {
  border: solid $color !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: $padding;
  transform: $rotate;
  -webkit-transform: $rotate;
  margin: 2px 0 2px 0;
  border-radius: 0 !important;
  width: 8px;
  height: 8px;
}

@mixin fixed-top-item($bg-color, $width) {
  position: fixed;
  width: $width;
  background-color: $bg-color;
}

@mixin custom-checkbox($color, $borderColor, $bgColor, $margin, $marginBefore, $padding, $width: 12px, $height: 12px) {
  input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: calc(50% - 10px);
    left: 0;
    width: $width;
    height: $height;
    margin: $margin;
  }
  input[type=checkbox]:disabled + label {
    opacity: 0.5 !important;
    cursor: not-allowed;

    &:before {
      cursor: not-allowed !important;
    }
  }
  label {
    vertical-align: middle;
    @include font-mixin(normal, normal, 400, 12px, 12px, ($open-sans));
    display: inline-block;
    margin-bottom: 0 !important;
    white-space: nowrap;

    &:before {
      content: " ";
      font-family: "pfm" !important;
      border: 1.5px solid $borderColor;
      border-radius: 2px;
      background-color: $bgColor;
      color: $color;
      width: 12px;
      height: 12px;
      margin: $marginBefore;
      display: inline-block;
      padding: $padding;
      vertical-align: middle;
      cursor: pointer;
      @include font-mixin (normal, normal, 900, 10px, 0.5, ($open-sans));
    }

  }

  input[type=checkbox]:checked + label:before {
    content: "n";
    font-size: 9px;
  }
}

@mixin override-dropdown($margin, $minWidth: 190px) {
  .dropdown-menu {
    margin: $margin;
    height: auto;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    width: inherit;
    min-width: $minWidth;

    > li {
      height: 20px;
      font-size: 12px !important;

      > a {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 3px 5px;
      }

      &.active > a {

      }

      &:not(.active) a:hover {
        color: $abbey !important;
        background-image: none !important;
        background-color: $link-water;
        text-decoration: none !important;
      }
    }
  }
}

@mixin override-multiple-mids($minWidth, $height, $margin, $border, $minHeight, $maxHeight, $innerHeight, $padding,  $disabledBorder, $overflowY,
$width: auto, $menuFontSize: 14px) {
  min-width: $minWidth;
  width: $width;
  height: $height;
  margin: $margin;
  border-radius: 0;

  .search-box {
    $sb: &;
    &__element {
      max-height: $height !important;
      min-width: $minWidth;
    }

    &__control {
      cursor: copy;
      height: $innerHeight !important;
      min-height: $minHeight !important;
      max-height: $maxHeight !important;
      border-radius: 0;
      border: $border;
      overflow: hidden;

      &--is-focused {
        cursor: copy;
        border-radius: 0;
        max-height: $maxHeight;
        min-height: $minHeight;
        border: $border !important;
        box-shadow: none !important;
        width: 100%;
      }
    }

    &__value-container {
      overflow-y: $overflowY !important;
    }

    &__indicator {
      display: none !important;
    }

    &__indicators {
      display: none !important;
    }

    &--is-disabled {
      height: $height;
      min-height: $height;
      cursor: not-allowed !important;
      .search-box__placeholder {
        color: rgba(208, 208, 208, 70%) !important;
      }
    }

    &__control--is-disabled {
      border-radius: 0;
      border: $disabledBorder;
      height: $innerHeight !important;
      min-height: $minHeight;

      #{$sb}__value-container {
        overflow-y: $overflowY !important;
      }

    }

    &__option{
      cursor: copy;
    }

    &__value-container {
      overflow-y: $overflowY;
      height: $innerHeight !important;
      padding: $padding;
      min-height: calc(#{$minHeight} - 2px) !important;
      max-height: calc(#{$maxHeight} - 2px) !important;
    }

    &__placeholder {
      color: hsl(0, 0%, 50%) !important;
    }

    &__menu {
      z-index: 3;
      margin-top: 2px;
    }

    &__multi-value {
      background-color: $lochmara;
      color: $white;
      border: 0;
      border-radius: 8px;
      cursor: pointer !important;
      &__label {
        color: $white;
        padding: 3px 0 3px 6px !important;
      }
      &__remove {
        cursor: pointer;
        border-radius: 8px;
        padding-left: 0 !important;
        padding-right: 1px !important;

        &:hover {
          background-color: $tango;
          color: $white;
          border-radius: 8px;
        }
      }
    }
  }
}

@mixin override-chart-div($left, $top, $margin) {
  .amcharts-chart-div {
    > a {
      left: $left !important;
      top: $top !important;
      margin: $margin !important;
    }
  }
}

@mixin dropdown-btn-mixin($width: 145px, $height: 26px, $border-clr: $alto) {
  width: 100%;
  @include override-dropdown(0 0 0);
  button {
    color: $abbey !important;
    @include reset-btn-styles;
    border: 1px solid $border-clr;
    border-radius: 0 !important;
    @include flex-row (space-between, center);
    height: $height !important;
    width: $width;
    margin-right: 20px;
    padding: 3px 10px !important;
    font-size: 12px !important;
    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $white !important;
    }
  }
  .caret {
    @include override-caret($tango, 3px);
  }
  &.open {
    .caret {
      @include override-caret-reversed;
    }

    button {
      background-color: $white !important;
    }
  }
}

@mixin user-profile-btn($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
  cursor: pointer;
}

@mixin user-modal-tile($minHeight, $marginBottom, $padding, $bgColor) {
  @include box-shadow(1px, 2px, 1px, 1px, rgba($black, 0.05));
  border-radius: 42px;
  width: 100%;
  min-height: $minHeight;
  margin-bottom: $marginBottom;
  padding: $padding;
  position: relative;
  background-color: $bgColor;
}

@mixin button-as-url($color, $border-bottom-color: #444) {
  background: none !important;
  color: $color;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  border-bottom: 1px solid $border-bottom-color;
  cursor: pointer;
}

@mixin left-pseudo-border($color, $height, $left: 0) {
  content: ' ';
  border-left: 2px solid $color;
  height: $height;
  width: 2px;
  position: absolute;
  left: $left;
  display: block;
}

@mixin bottom-pseudo-padding($color, $width) {
  &:before {
    content: "";
    border-bottom: 1px solid $color;
    width: $width;
    position: absolute;
    bottom: -1px;
    left: 0;
    display: block !important;
  }
  &:after {
    content: "";
    border-bottom: 1px solid $color;
    width: $width;
    position: absolute;
    bottom: -1px;
    right: 0;
    display: block !important;
  }
}

@mixin toggled-state($color, $color-light) {
  &:before {
    background: $color-light;
  }
  span {
    background: $color;
    transform: translateX(20px);
    transition: all .2s cubic-bezier(.8, .4, .3, 1.25), background .15s ease;
    box-shadow: 0 3px 8px rgba($color, .2);

    &:before {
      transform: scale(1);
      opacity: 0;
      transition: all .4s ease;
    }
  }
  i {
    color: $white;
    font-size: 12px;
    right: 4px;
    top: 4px;
    position: absolute;
  }
}

@mixin cross-symbol($width, $height, $color) {
  width: $width;
  height: $height;
  background: linear-gradient(to bottom, transparent 35%, $color 35%, $color 65%, transparent 65%),
  linear-gradient(to right, transparent 35%, $color 35%, $color 65%, transparent 65%);
}

@mixin minus-symbol($width, $height, $color) {
  width: $width;
  height: $height;
  background: linear-gradient(to bottom, transparent 35%, $color 35%, $color 65%, transparent 65%);
}

@mixin cbox-switch($on-clr, $on-clr-light, $off-clr, $off-clr-light, $margin) {
  .toggle {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    margin: $margin;

    &:before {
      content: "";
      position: relative;
      top: 3px;
      left: 3px;
      width: 34px;
      height: 14px;
      display: block;
      background: $off-clr;
      border-radius: 8px;
      transition: background .2s ease;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      display: block;
      background: $off-clr-light;
      border-radius: 10px;
      box-shadow: 0 3px 8px rgba($silver, .5);
      transition: all .2s ease;

      &:before {
        content: "";
        position: absolute;
        display: block;
        margin: -18px;
        width: 56px;
        height: 56px;
        background: rgba($on-clr, .5);
        border-radius: 50%;
        transform: scale(0);
        opacity: 1;
        pointer-events: none;
      }
    }
  }
  .cbx {
    display: none;
  }

  .cbx:checked + .toggle {
    @include toggled-state($on-clr, $on-clr-light);
  }
  .cbx:checked + .toggle.disabled-cls {
    @include toggled-state($dark-alto, $gallery);
  }
}

@mixin error-warning-msg-mixin($color) {
  color: $color;
  display: inline-flex;
  font-size: 12px;
  span[class^="ico"] {
    margin-right: 4px !important;
    display: inline-block;
    margin-top: 0;
  }
  > div {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    line-height: 14px;

    &.double-msg {
      display: flex !important;
      flex-direction: column !important;
    }
  }
}

@mixin register-add-remove-element($color, $margin, $fontSize: 14px) {
  margin: $margin;
  text-align: center;
  display: inline-block;
  color: $color;
  font-size: $fontSize;
}

@mixin radio-btns($color) {

  input[type="radio"] + label {
    color: $abbey;
    position: relative;
  }

  input[type="radio"] + label::before {
    /* Outer Circle of radio button */
    border: 1px solid $abbey;
    content: ' ';
    display: inline-block;
    margin-right: 5px;
    width: 17px;
    height: 17px;
    min-width: 17px;
    border-radius: 50%;
    transition: border 0.15s ease-in-out;
  }

  input[type="radio"] + label::after {
    /* Inner Circle of radio button */
    border: 0 solid $color;
    content: ' ';
    background: transparent;
    display: inline-block;
    margin-right: 5px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 3px;
    transition: border 0.15s ease-in-out;
  }

  input[type="radio"] {
    opacity: 0;
    position: absolute;
  }

  /* When button is active */
  input[type="radio"]:checked + label::after {
    background: $color;
  }

  input[type="radio"]:checked + label::before {
    border-color: $color;
  }

  input[type="radio"]:checked + label {
    color: $color;
  }
}

@mixin feedback-msg($bgColor) {
  position: absolute;
  background-color: $bgColor;
  height: 100%;
  top: 0;
  width: 100%;
  margin-left: -28px;
  border-radius: 12px;
  padding: 15px 25px;
  cursor: pointer;
  @include flex-row(space-between, center);
}

@mixin business-title-mixin {
  @include font-mixin (normal, normal, 400, 16px, 1.2, $open-sans);
  @include flex-row(space-between, center);
  color: $midnight-blue;
  margin: 0 !important;
  background-color: transparent !important;
  width: calc(100% - 8px);
}

@mixin accordion-icons($color, $size, $right) {
  > i {
    position: absolute;
    color: $color;
    font-size: $size;
    right: $right;
    transition: opacity .3s, transform .3s;

    &.icon-plus-accordion {
      transform: rotate(180deg) scale(.5);
      opacity: 0;
    }

    &.icon-minus-accordion {
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
  }
  &.collapsed {
    > i {
      &.icon-plus-accordion {
        transform: rotate(0deg) scale(1);
        opacity: 1;
      }

      &.icon-minus-accordion {
        transform: rotate(-180deg) scale(.5);
        opacity: 0;
      }
    }
  }
}


@mixin business-accordion {
  @include single-container-wrap;
  @include override-panel;
  border-color: transparent !important;
  .panel-heading {
    @include override-panel;
    border: none !important;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    background-color: transparent !important;
    padding: 10px 15px !important;

    &:hover {
      h4 {
        color: $bahama-blue;
      }
    }

    h4 {
      @include business-title-mixin;

      &:before {
        @include left-pseudo-border($tango, 13px);
        margin-left: -1px;
      }

      @include accordion-icons($tango, 12px, 15px);

      &:hover {
        i {
          color: $midnight-blue;
        }
      }
    }
  }
}

@mixin editable-fields($maxBtnWidth) {
  $ef: &;
  &__btn {
    @include reset-btn-styles;
    @include flex-column(flex-start, flex-start);
    max-width: $maxBtnWidth;

    &:hover,
    &:focus,
    &:active:focus,
    &:active {
      @include reset-btn-styles;
    }

    text-align: left !important;
    padding: 0;
  }
  &__wrapper {
    white-space: pre-wrap;
    display: inline-block;
    word-break: break-word;
    cursor: pointer;

    #{$ef}__value {
      color: $tango !important;
      border-bottom: 1px dashed $tango !important;
    }

    #{$ef}__no-value {
      color: $bombay;
      border-bottom: 1px dashed $bombay;
    }

    .editable-field__icon {
      margin-left: 5px;
      white-space: nowrap;

      &.icon-orange {
        color: $tango;
      }

      &.icon-gray {
        color: $bombay;
      }
    }

  }
}

@mixin charts-timespan-dropdown-mixin($minWidth: 190px) {
  .dropdown {
    min-width: 150px;
    @include override-dropdown(0, $minWidth);

    .charts-timespan__dropdowns {
      margin-top: 3px;
      font-size: 12px !important;
      color: $midnight-blue;
      border: 1px solid $alto !important;
      text-shadow: unset !important;
      width: inherit;
      min-width: 150px;
      font-family: $open-sans;
      padding: 5px 6px !important;
      @include flex-row(space-between, center);
      background: $concrete no-repeat 90% 50% !important;
      -webkit-appearance: none;
      border-radius: 1px;

      .caret {
        @include override-caret($lochmara, 3px);
      }
    }

    &.open {
      .caret {
        @include override-caret-reversed;
      }
    }
  }
}

/* Identical Search Fields Mixin OVERRIDE*/

@mixin search-field($width, $bgColor, $marginTop, $borderClr) {
  width: $width;
  height: 36px;
  display: inline-block;
  vertical-align: top;
  margin-top: $marginTop;

  .search-box {
    $sb: &;
    &__control {
      border-radius: 20px;
      background-color: $bgColor !important;
      border: 2px solid $borderClr;
      cursor: pointer;
      &:after {
        content: 'g';
        @include override-with-font;
        padding-right: 10px;
        color: $bahama-blue;
      }

      &--is-focused {
        border-radius: 20px;
        border-color: $borderClr;
        box-shadow: 0 0 1px $borderClr !important;
        cursor: text !important;
        &:after {
          content: 'g';
          @include override-with-font;
          padding-right: 10px;
          color: $bahama-blue;
          border-left: 0 !important;
        }

        &:hover {
          border: 2px solid $borderClr;
        }

        #{$sb}__indicator-separator {
          background-color: transparent !important;
        }

        #{$sb}__indicators {
          display: none;
        }
      }
    }

    &__menu {
      z-index: 5;
      margin-top: 2px;
    }

    &__value-container--is-multi{
      border: none !important;
    }

    &__indicators{
      display: none;
    }

    &__indicator-separator {
      background-color: transparent !important;
    }

  }
}

/* Autofill dropdowns */
@mixin autofill-dropdowns-mixin($max-height: 26px, $width: 195px) {
  color: $abbey;
  .search-box {
    &__element {
      margin-top: 2px;
      width: $width;
    }
    &__control {
      @include autofill-dropdowns-inputs-mixin($white, $jumbo, $abbey, $tango);
      min-height: $max-height !important;
      max-height: $max-height !important;

      &--is-focused {
        margin: $popover-input-margin-top 0 15px 0;
        outline: none !important;
        min-height: $max-height !important;
        height: $max-height !important;
        border: 1px solid $jumbo !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        cursor: pointer;
      }
    }

    &__value-container {
      height: $max-height !important;
    }

    &__menu {
      margin-top: 2px;
      &-list {
        position: relative !important;
        margin-top: 0;
        padding-top: 0;
        max-height: 150px !important;
        overflow-y: auto !important;
        padding-bottom: 15px;
      }
    }

    &__option {
      height: auto !important;
      min-height: 20px;
      padding: 5px;
      vertical-align: middle;
      cursor: pointer;

      &--is-selected {
        background-color: $azure !important;
        color: $white !important;
      }
      &--is-focused:not(.search-box__option--is-selected) {
        background-color: $link-water !important;
      }
    }

  }
}

@mixin autofill-dropdowns-inputs-mixin($bg, $border, $font, $caret) {
  background: $bg no-repeat 90% 50%;
  -webkit-appearance: none;
  border-radius: 1px;
  border: 1px solid $border;
  cursor: pointer;

  @include flex-row(space-between, center);
  @include font-mixin(normal, normal, 400, 1em, 1, $open-sans);

  .multi-select-caret {
    margin-right: 4px;
    @include override-caret($caret, 3px);

    &.arrow-up {
      @include override-caret-reversed;
    }
  }

  .search-box {
    &__indicator-separator {
      width: 0 !important;
    }
    &__dropdown-indicator {
      padding: 7px !important;
    }
    &__value-container {
      height: $popover-input-height !important;
    }
    &__single-value {
      color: $font !important;
    }
  }

  &:hover, &:focus, &:active {
    background: $bg no-repeat 90% 50%;
  }
}

@mixin override-modal-close-btn {
  button {
    color: $tango;
    opacity: 1;

    &:hover {
      opacity: 0.7;
      color: $tango !important;
    }
  }
}

@mixin autofill-dropdowns-disabled-mixin {
  .search-box {
    $sb: &;
    &__control {
      &--is-disabled {
        @include autofill-dropdowns-inputs-mixin($concrete, $mercury, $abbey, $bombay);
        #{$sb}__placeholder {
          color: $bombay !important;
        }
        #{$sb}__indicators {
          border: 0 !important;
        }
        #{$sb}__indicator-separator {
          display: none !important;
        }
      }
    }
  }
}

@mixin upload-file($size, $iconSize: 10px) {
  @include flex-row(flex-start, stretch);
  label {
    cursor: pointer;
    margin-bottom: 0 !important;
    position: absolute;
    @include flex-row(stretch, center);

    span {
      &:first-child {
        font-size: $iconSize;
        color: $lochmara;
        margin-right: 5px;
      }

      &:nth-child(2) {
        @include font-mixin(normal, normal, 700, $size, 1.2, $open-sans);
        color: $lochmara;
      }

      &:nth-child(3) {
        @include font-mixin(normal, normal, 400, $size, 1.2, $open-sans);
        color: $shark;
        margin-left: 5px;
      }
    }
  }
}

@mixin hide-input {
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

@mixin loader($color) {
  .loader {
    border: 4px solid $color;
  }
  .loader-inner {
    background-color: $color;
  }

  h4 {
    color: $color;
  }

  h6 {
    color: $color;
  }

  #box {
    background-color: $color;
  }

  #box2 {
    background-color: $color;
  }
}

@mixin spinner($color) {
  .spinner > div {
    background-color: $color;
  }
}

@mixin blue-border-btn-mixin($margin, $fontSize, $padding, $opacity: 1) {
  margin: $margin;
  padding: $padding !important;
  @include font-mixin(normal, normal, 400, 0.78em, 1, $open-sans);
  @include reset-btn-styles;
  @include button-mixin($bahama-blue, 15px, 1px solid $bahama-blue);
  @include hover-focus-states($white, $bahama-blue, $bahama-blue, $white);
  font-size: $fontSize;
  opacity: $opacity
}

@mixin black-border-btn-mixin($margin, $fontSize, $focusColor, $padding, $hoverBorder:1px solid $black) {
  margin: $margin;
  padding: $padding;
  @include font-mixin(normal, normal, 400, 0.78em, 1, $open-sans);
  @include reset-btn-styles;
  @include button-mixin($black, 15px, 1px solid $black);
  &:hover,
  &:active,
  &:focus {
    background-color: $focusColor !important;
    @include button-mixin($white, 15px, $hoverBorder);
    border-color: $focusColor !important;
  }
  font-size: $fontSize
}

@mixin donut-chart-table-row($color) {
  td:first-child:before {
    background-color: $color;
  }
}

@mixin download-btn-mixin {
  background-color: $tango !important;
  background-image: url('../img/icon_download.png') !important;
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
  width: 22px !important;
  height: 22px !important;
  z-index: 0 !important;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  @include hover-focus-states($tango, $midnight-blue, $white, $white);
}

@mixin text-ellipsis($maxWidth) {
  display: inline-block;
  max-width: $maxWidth;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@mixin password-eye($top, $right) {
  color: $shark;
  position: absolute;
  right: $right;
  top: $top;
  cursor: pointer;
  @include reset-btn-styles;
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:active:hover
  {
    background-color: transparent;
  }
}

@keyframes FadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: hidden;
  }
}

@mixin wave-props($position) {
  width: 100vw;
  position: $position;
  bottom: 0;
  img {
    width: 100%;
    min-width: 1280px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}

/* ANIMATIONS */

@-webkit-keyframes smallToBig {
  from {
    -webkit-transform: scale(0.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes smallToBig {
  from {
    -moz-transform: scale(0.1);
  }
  to {
    -moz-transform: scale(1);
  }
}

@-ms-keyframes smallToBig {
  from {
    -ms-transform: scale(0.1);
  }
  to {
    -ms-transform: scale(1);
  }
}


@-webkit-keyframes moveFromBottom {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0%);
  }
}

@-moz-keyframes moveFromBottom {
  from {
    -moz-transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(0%);
  }
}

@-ms-keyframes moveFromBottom {
  from {
    -ms-transform: translateY(100%);
  }
  to {
    -ms-transform: translateY(0%);
  }
}


