.login-area {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: $alabaster;
  min-height: 620px;

  .logo {
    //cursor: default;
  }

  form {
    margin-bottom: 10px !important;
  }

  &__top {
    @include flex-row(flex-end, center);
    margin: 2rem 2rem 1rem 0;
  }

  &__content {
    @include flex-row(center, flex-start);
    margin: 100px auto 0 auto;
  }

  &__left {
    @include flex-column(space-between, flex-start);
    font-size: 32px;
    max-width: 240px;
    color: $midnight-blue;
  }

  h2 {
    @include font-mixin(normal, normal, 600, 1em, 1, $open-sans);
    margin: 0 !important;
    word-spacing: 0.1em;
    color: $midnight-blue;
  }

  &__sub-header {
    @include font-mixin(normal, normal, 400, 0.7em, 1.1, $open-sans);
    color: $lochmara;
    margin: 5px 0 0 0;
    max-width: 300px;
  }

  &__text {
    @include font-mixin(normal, normal, 400, 0.45em, 1.3, $open-sans);
    margin-bottom: 0;

    &_intro {
      margin: 21px 0 10px 0;
      color: $jumbo;
    }

    &_list {
      @include reset-margin-block;
      margin-left: 16px;
      color: $jumbo;
      li {
        margin-bottom: 14px;
        max-width: 250px;
      }
      &--bold {
        li {
          font-weight: 600 !important;
        }
      }
    }
  }

  &__register-area {
    > span {
      @include font-mixin(normal, normal, 500, 1.22em, 1, $open-sans);
    }
    @include flex-column(center, center);
    color: $midnight-blue;
    margin-top: 30px;
    width: 100%;
  }

  &__bullet-padding {
    padding-left: 20px;
    line-height: 2;
  }

  &__login-with-forgot {
    @include flex-column(center, flex-start);
    z-index: 1;
    margin-left: 77px;
  }

  &__forgot-password {
    width: 100%;
    @include font-mixin(normal, normal, 600, 0.8em, 1, $open-sans);
    text-align: center;
    a {
      color: $lochmara;
    }
  }

  &__login-box {
    @include box-shadow(0, 6px, 20px, 0, rgba($black, 0.10));
    @include flex-column(center, stretch);
    background-color: $white;
    padding: 30px 65px 5px 75px;
    border-radius: 42px;
    font-size: 14px;
    max-width: 400px;
    position: relative;

    form {
      > span {
        color: $midnight-blue;
        font-weight: 600;
      }
    }

    .password-container {
      @include flex-row(stretch, flex-start);
      position: relative;
  }

    input {
      border-radius: 15px;
      font-size: 0.95em;
      border: 1px solid $bombay;
      padding: 3px 10px;
      margin: 7px 0 25px 0;
      width: 100%;
      @include placeholder($bombay);
      background-color: $white;
      &#password {
        padding: 3px 33px 3px 10px !important;
      }
    }

    &__password {
      @include flex-row(flex-start, flex-start);
      position: relative;
    }

    &__wrong-creds {
      @include icon-font-mixin(0.7em, red);
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }

  &__login-button-area {
    @include flex-row(center, center);
    width: 100%;
    margin: 0 0 15px 0;
  }

  &__login-button {
    @include login-btns-mixin(45%, 15px, $tango, 150px, 15px);
  }

  &__register-button {
    @include login-btns-mixin (auto, 10px, $bahama-blue, 220px, 7px);
  }

  &__copyright {
    @include font-mixin(normal, normal, 600, 10px, 1.2, $open-sans);
    color: $jumbo;
    @include flex-row(flex-start, flex-start);
    width: 100%;
    margin: 5% auto 0 auto;
    position: absolute;
    bottom: 20px;
  }
}

.icon-password-eye {
  @include password-eye(7px, 0);
}

@media screen and (max-width: $tablet-width-limit) {

  .login-area {
    height: auto;
    padding: 0 0 5rem 0;
    min-height: auto !important;
    &__top {
      margin: 1rem 0;
    }
    &__content {
      flex-direction: column !important;
      margin: 0 auto 0 auto !important;
    }
    &__left {
      margin: auto;
    }
    &__login-with-forgot {
      margin: 4rem auto;
      width: calc(100% - 40px);
    }
    &__login-box {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      padding: 30px 40px 0 40px;
    }
    &__forgot-password {
      margin-left: 0;
      width: 100%;
    }
    &__copyright {
      margin-left: 87px;
    }
    .logo img {
      width: 80px;
      height: 33.15px;
    }
  }
}