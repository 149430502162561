.top-main-renderer {
  background-color: $alabaster;
  height: 120px;
  margin-right: 20px;
  @include flex-row(space-between, flex-start);

  .quick-find__info-button {
    width: 5%;
    padding: 0;
    margin-left: 5px;
    visibility: hidden;

    &-label {
      color: $bahama-blue;
      cursor: pointer;
    }
  }

  &__multiple-mids {

  }

  &__info-button {
    width: 5%;
    padding: 0;
    margin-left: 5px;
    visibility: hidden;

    &-label {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: 10px;
    }
  }


  &__wrapper {
    background-color: $alabaster;
    position: relative;
    height: 60px;
    margin: 70px 0 0 155px !important;
    padding-top: 10px;
    width: calc(100% - 175px);
    min-width: 912px;


    .lesser-renderer-height {
      h3 {
        margin-top: 8px !important;
      }
    }

    &.lesser-renderer-height__wrapper + .business-page {
      .__excel-button{
        margin-top: -40px !important;
      }
    }

    h3 {
      @include font-mixin(normal, normal, 400, 23px, 1, $open-sans);
      margin: 0 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      height: 26px;
    }

    .dropdown {
      @include override-dropdown(0);

      .dropdown-menu {
        z-index: 4 !important;
        width: 100% !important;
      }


      #dropdown-companies {
        border-bottom: 2px solid $tango;
        color: $abbey;
        text-shadow: unset !important;
        min-width: 190px;
        max-width: 275px;
        font-family: $open-sans;
        @include flex-row(space-between, center);
        background: $white no-repeat 90% 50%;
        -webkit-appearance: none;
        border-radius: 1px;

        &:hover,
        &:active,
        &:focus:hover,
        &:active:focus {
          //background-color: $cerulean !important;
          //color: $white;
          //.caret {
          //  border-color: $white;
          //}
        }

        &:focus {
          //background-color: $cerulean !important;
          //color: $white;
          //.caret {
          //  border-color: $white;
          //}
        }

        &:hover,
        &:active,
        &:focus {
          z-index: 0 !important;
        }

        .caret {
          @include override-caret($tango, 4px);
          margin-left: 9px;
        }

        .companies-in-dropdown {
          @include text-ellipsis(230px)
        }
      }

      &.open {
        .caret {
          @include override-caret-reversed;
        }
      }
    }
  }

  &__helper {
    position: fixed;
    top: 125px;
    background-color: $alabaster;
    height: 30px;
    display: block;
    padding: 15px;
    width: calc(100% - 153px);
    margin-left: -3px;
  }

  &__left-part {
    padding: 0 0 3px 0;
    width: calc(100% - 350px);
    @include flex-row(flex-start, flex-end);
    position: relative;

    &__link-wrapper {
      position: relative;
    }

    &__reset-link {
      @include button-as-url($lochmara);
      position: relative;
      top: 20px;
      right: -15px;
      font-size: 9px;
    }

    &__loader-align {
      margin-top: 10px;
    }
  }

  &__right-part {
    @include flex-column(space-between, flex-start);
    padding: 0 0 0 4px !important;
    text-align: left;
    margin-top: 20px;
    width: 300px;

    > span {
      font-weight: 600;
    }

    .search-fast {
      @include search-field (100%, transparent, 10px, $lochmara);
      z-index: 1;
      position: relative;

      &__header-tooltip {
        @include flex-row(flex-start, flex-start);
        position: relative;
      }
    }

    .search-fast-label {
      @include font-mixin(normal, normal, 400, 14px, 1, $open-sans);
      color: $bahama-blue;
      margin: 0 !important;
    }
  }

  .multiselect-stores {
    @include override-multiple-mids(272px, 35px, 0 0 0 30px, 2px solid $lochmara, 35px, 35px, 35px, 0 8px, 2px solid $alto, auto);

    &__loader {
      min-width: 183px;
      height: 21px;
      margin: 0 0 0 30px !important;
      border-radius: 0;
    }
  }

  #mid-popover-positioned-bottom-tltp {
    top: 17px !important;
    left: 256px !important;
  }
}

@media screen and (max-width: $common-medium-limit) {
  .top-main-renderer {
    &__wrapper {
      margin: 70px 20px 0 20px !important;
      width: calc(100% - 40px) !important;
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {

  #dropdown-companies {
    .companies-in-dropdown {
      max-width: 100%;
    }
  }
  .top-main-renderer {
    flex-direction: column !important;
    height: auto;

    #mid-popover-positioned-bottom-tltp {
      top: 45px !important;
      left: 505px !important;
      right: -18px;
      margin-left: 17px;
    }
    &__wrapper {
      width: calc(100% - 40px) !important;
      margin: 70px 20px 0 20px !important;
      height: auto !important;
      min-width: unset !important;
    }

    &__info-button {
      &-label {
        cursor: pointer;
        position: absolute;
        right: -18px !important;
        top: 30px !important;
      }
    }

    &__left-part {
      padding: 0 !important;
      width: 100% !important;
      flex-direction: column !important;
      align-items: center !important;

      &__loader-align {
        margin-top: 20px;
        margin-right: 60px;
      }

      &__reset-link {
        top: 60px;
        right: 1px;
        position: absolute !important;
      }
    }

    &__right-part {
      padding: 0 !important;
      width: 100% !important;
      margin-left: 0 !important;
      margin-top: 23px;

      .search-fast {
        width: 100% !important;
        z-index: 2;
      }

      /* temporary fix */
      .search-fast__header-tooltip {
        display: block !important;
        position: relative;
      }
    }

    .multiselect-stores {
      width: 100% !important;
      margin-top: 20px !important;
      margin-left: 0 !important;

    }
  }
}

