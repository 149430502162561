.register-merchant-step1 {
  @include reset-login(100vh, 0);
  overflow-y: visible !important;

  &__custom-modal {
    width: 20%;
    margin-top: 10%;
    @include override-modal-close-btn;
  }
}

.register-merchant-step2 {
  @include reset-login(auto, 110px);
  overflow-y: visible !important;

  &__custom-modal {
    width: 20%;
    margin-top: 10%;
    @include override-modal-close-btn;
  }
}

.legal-rep {
  &__btn {
    @include btns-mixin(100%, 15px, $tango, 150px, 15px, $white, $white);
    @include hover-focus-states($tango, $midnight-blue, $white, $white);
    border-color: transparent !important;
    display: block;
    &--container {
      margin-bottom: 20px;
    }
  }

  &__confirmation {
    margin-top: 15px;
    display: flex;
  }

  &__text-check {
    line-height: 19px;
    font-weight: 600;
    color: $shark;

  }

  &__icon-check {
    color: $limeade;
    font-size: 30px;
    margin-right: 9px;
    margin-top: 3px;
  }
}

.register-merchant-radius--legal {
  cursor: pointer;
  @include radio-btns($bahama-blue);
  width: 50%;
  display: inline-flex;
  flex-direction: row;

  label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0;
    cursor: pointer;
  }
}

.register-merchant-radius--corp {
  cursor: pointer;
  @include radio-btns($bahama-blue);
  width: auto;
  display: flex;
  flex-direction: column;

  label {
    display: inline-flex;
    align-items: flex-start;
    @include font-mixin(normal, normal, 600, 13px, 14px, $open-sans);
    margin-bottom: 6px;
    cursor: pointer;
  }
}

.register-merchant-radius-section {
  margin: 7px 0 15px 0;
  position: relative;
  display: block;
}

.register {
  &__added-user,
  &__added-mid {
    color: $bahama-blue;
    font-weight: 500;
  }

  &__error-mid {
    border: 1px solid $bright-red !important;
  }
}

.registration-mid {
  color: $bahama-blue;
}

.registration-mid:last-child > input {
  margin-bottom: 10px !important;
}

.register__add-remove-element {
  &__container {
    @include flex-row(space-between, center);
  }
  &__add {
    color: $lochmara;
    &.add--disabled {
      color: $bombay !important;
      background-color: transparent !important;
      cursor: not-allowed;
      .icon-circled-plus {
        color: $bombay !important;
      }
    }
  }

  &__remove {
    color: $bright-red;
    margin-left: auto;
  }
  @include flex-row(stretch, center);
  padding: 0 !important;
  @include reset-btn-styles;
  font-size: 14px;
  .icon-circled-plus {
    @include register-add-remove-element($lochmara, 0 5px 0 0, 16px);
  }
  .icon-circled-minus {
    @include register-add-remove-element($bright-red, 0 0 0 5px, 16px);
  }
}

.registration-disabled {
  > input:disabled {
    border-color: $silver;
    color: $dove-gray !important;
    background-color: $alto !important;
    cursor: not-allowed !important;
  }
   > input:-webkit-autofill {
     -webkit-box-shadow: 0 0 0 30px $alto inset !important;
   }
}


.register-merchant-title {
  font-size: 22px;
  color: $lochmara;
  margin-bottom: 20px !important;
}
.cbox-registration {
  &__accept-terms,
  &__confirm-personal-info {
    display: inline-block;
    position: relative;
    @include custom-checkbox($tango, $tango, transparent, 5px 0 0 0, -2px 5px 0 0, 2px 1px 0 0, 20px, 20px);
    input[type="checkbox"] {
      top: 0 !important;
    }
    label {
      margin-right: 5px;
      width: 12px;
      height: 12px;
    }
  }
  &__confirm-personal-info {
    margin-top: 15px;
  }

  &__container {
    @include flex-column(flex-start, center)
  }

  &__text {
    font-size: 14px;
    color: $shark;
    &--blue {
      font-size: 14px;
      color: $bahama-blue;
      font-weight: 700;
      &:hover {
        color: $lochmara;
      }
    }
  }
}
.seal-error {
  margin-top: 25px;
}
.icon-pending__registration {
  text-align: center;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $bahama-blue;
  margin-top: 10px;
}
.successful-merchant-registration-msg {
  margin-top: 30px;
  text-align: justify;
  @include font-mixin(normal, normal, 400, 14px, 17px, $open-sans);
}

.register-radio-btn {
  margin-left: 5px;
  cursor: pointer;
}

.upload-register-seal {
  @include upload-file (14px, 16px);
  @include hide-input;
  margin-top: 25px;
  height: auto;
  label span {
    font-weight: 400 !important;
  }
}

.seal-file {
  margin-top: 17px;
  max-width: 100%;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__icon {
    font-size: 13px;
    color: $limeade;
    margin-right: 5px;
  }
  &__label {
    @include font-mixin(normal, normal, 500, 14px, 15px, $open-sans);
    color: $midnight-blue;
  }
  &__attachment {
    margin-top: 10px;
    width: 100%;
  }
}


.signature-cropper {
  width: 370px;
  position: relative;
  display: block;
  margin: 10px 0 0px -10px;
  align-self: center;
  &__label {
    @include font-mixin(normal, normal, 500, 14px, 15px, $open-sans);
    color: $midnight-blue;
    display: inline-block;
    margin: 0 0 5px 0;
    padding: 5px 50px 5px 60px;
    width: 100%;
  }
  &__hint {
    @include font-mixin(normal, normal, 700, 12px, 14px, $open-sans);
    color: $affair;
    display: inline-block;
    padding: 5px 30px 5px 60px;
    width: 100%;
    word-break: keep-all;
    text-align: justify;

    i {
      vertical-align: middle;
    }
  }
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  &__content {
    width: 370px;
    height: 250px;
    position: relative;
    display: block;
  }
  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    &--content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 75px;
    }
    &--btn {
      @include btns-mixin(35px, 7px, $affair, 30px, 30px, $white, $affair, 5px, 13px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: darken($affair, 5%) !important;
        border-color: darken($affair, 5%) !important;
        color: $zircon !important;
        @include box-shadow(0, 1px, 9px, 1px, rgba($dove-gray, 0.35));
      }
      &:active,
      &:focus {
        color: $zircon !important;
      }
      span {
        margin-left: 3px;
      }
    }
    &--zoom-out {
      span {
        margin-top: -3px;
      }
    }
  }
}



@media screen and (max-width: $tablet-width-limit) {
  .register-merchant-step1,
  .register-merchant-step2 {
    height: auto;
    padding: 0 20px 5rem 20px !important;
    min-height: auto !important;
  }

  .register-merchant-step1__content,
  .register-merchant-step2__content {
    flex-direction: column !important;
    margin: 0 auto 0 auto;
  }

  .register-merchant-step1__left,
  .register-merchant-step2__left {
    margin: auto;
  }

  .register-merchant-step1__top,
  .register-merchant-step2__top {
    margin: 1rem 0 !important;
  }

  .register-merchant-step1__login-with-forgot,
  .register-merchant-step2__login-with-forgot {
    margin: 1.6rem auto !important;
  }

  .register-merchant-step1__login-box,
  .register-merchant-step2__login-box {
    margin: 0 auto !important;
    position: relative !important;
    padding: 20px 30px 0 30px !important;
    width: 100% !important;
  }

  .logo img {
    width: 80px;
    height: 33.15px;
  }

  .signature-cropper {
    margin: 0 auto 0 -10px !important;
    display: flex;
    flex-direction: column;
    &__label {
      padding: 15px 50px 5px 10px !important;
    }
    &__hint {
      padding: 15px 50px 5px 10px !important;
    }
    &__controls {
      padding: 0 50px 5px 10px !important;
    }
    &__content {
      width: calc(100% - 10px);
    }
  }
}
