.transactions {
  @include single-container-wrap;
  min-height: calc(100vh - 250px);
  overflow: hidden;

  &-page {
    margin: 0 0 0 155px !important;
    &.more-criteria {
      .transactions {
        min-height: calc(100vh - 20px) !important;
      }
    }
  }
}


#controlled-tabs-transactions {
  position: relative;

  .nav-tabs {
    border-bottom: 1px solid $bombay;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding-top: 5px;
    position: relative;
    min-height: 50px;
    @include bottom-pseudo-padding($white, 30px);

    > li {
      @include font-mixin (normal, normal, 400, 1em, 1, $open-sans);
      margin-left: 17px;
      margin-bottom: -3px;

      > a {
        span {
          border-bottom: 4px solid transparent;
          padding-bottom: 4px;
          color: $lochmara;
          letter-spacing: -0.25px;

          i {
            margin-right: 5px;
            font-size: 1.714em;
          }
        }

        &:hover {
          background-color: transparent !important;

          > span {
            color: $midnight-blue;
          }
        }
      }

      &.active {
        > a {
          background-color: transparent !important;

          span {
            border-bottom: 4px solid $midnight-blue;
            color: $midnight-blue !important;
          }
        }
      }
    }
  }
}


#controlled-tabs-transactions-tab-notecom {
  padding: 10px 15px 10px 13px;

  > span {
    padding-right: 8px;
  }
}

#controlled-tabs-transactions-tab-ecom {
  padding: 10px 15px 10px 9px;

  > span {
    padding-right: 22px;
  }
}

#controlled-tabs-transactions-tab-installments {
  padding: 10px 15px 10px 9px;

  > span {
    padding-right: 36px;
  }
}

#controlled-tabs-transactions-tab-notecom,
#controlled-tabs-transactions-tab-ecom,
#controlled-tabs-transactions-tab-installments {
  border: 0 !important;
}

.icon-saita {
  font-size: 12px;
  @include flex-row(center, flex-end);
  border-radius: 100%;
  color: $white;
}

.transactions-popover-wrapper {
  padding: 12px 15px 15px 30px;
  z-index: 0;
  top: 181px;

  &__excel-button-tooltip-wrapper {
    z-index: 1;
    @include font-mixin(normal, normal, 400, 12px, 1.2, $open-sans);
    color: $jumbo;
    margin-right: 10px;
  }

  &__excel-button {
    background-color: $affair;
    width: 22px;
    height: 22px;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    border: 0 !important;
    padding: 2px 2px 2px 4px;
    &:hover {
      background-color: $tango;
    }

    &:disabled {
      background-color: $light-alto !important;
      box-shadow: none;
    }
  }
}

.transaction-excel-wrapper {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  right: 44px;
  top: 20px;
}

.transactions-popover-toggle {
  width: 210px;
  height: 35px;
  border-radius: 20px;
  border: 1px solid $affair;
  color: $jumbo;
  background: $wild-sand none !important;
  @include flex-row(space-between, center);

  &__loader {
    display: flex;
    margin-left: 31px;
  }

  &.not-triggered {
    @include hover-focus-states ($wild-sand, rgba(125, 65, 153, 0.25), $jumbo, $affair);
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $affair;
    text-shadow: none;
    box-shadow: none !important;
  }

  > span:first-child {
    transition: opacity .3s;
    position: absolute;
  }

  > span:nth-child(2) {
    transition: opacity .3s;
    opacity: 0;
  }

  > .icons {
    position: relative;
    width: 14px;
    height: 14px;
    padding-right: 16px;

    i {
      position: absolute;
    }

    .icon-ico-magnifier {
      transition: opacity .3s, transform .3s;
      color: $affair;
    }

    .icon-x-icon {
      transition: opacity .3s, transform .3s;
      transform: rotate(-180deg) scale(.5);
      opacity: 0;
    }
  }

  &.triggered {
    color: $white !important;
    background: $affair none !important;
    text-shadow: none !important;
    @include hover-focus-states ($affair, rgba(125, 65, 153, 0.25), $white, $affair);

    &:hover,
    &:active,
    &:focus:hover,
    &:active:focus {
      .icon-x-icon {
        color: $affair;
      }
    }

    &:focus {
      .icon-x-icon {
        color: $white;
      }
    }

    .icon-ico-magnifier {
      transform: rotate(180deg) scale(.5);
      opacity: 0;
    }

    .icon-x-icon {
      transform: rotate(0deg) scale(1);
      opacity: 1;
      color: $white;
    }

    > span:first-child {
      filter: alpha(opacity=0);
      opacity: 0;
    }

    > span:nth-child(2) {
      filter: alpha(opacity=1);
      opacity: 1;
    }
  }

  &.move-to-middle {;
    @include transform(translateX(calc(100% + 43px)))
  }

  &.move-to-right {;
    @include transform(translateX(calc(100% + 296px)))
  }
}

.card-number-tltp {
  &.position-el {
    top: 191px !important;
    left: 135px !important;
  }
  &.position-en {
    left: 122px !important;
    top: 211px !important;
  }
}

#transactions-popover-positioned-bottom {
  @include maxWidthHeight(737px, 560px);
  width: 737px;
  left: 15px !important;
}
#installments-popover-positioned-bottom {
  @include maxWidthHeight(300px, 560px);
  width: 300px;
  left: 450px !important;
}

#transactions-popover-positioned-bottom,
#installments-popover-positioned-bottom {
  @include font-mixin(normal, normal, 400, 12px, 1, $open-sans);
  z-index: 2;
  color: $abbey;
  top: 98px !important;
  background-color: $alabaster;
  border: 1px solid $mercury;

  > .arrow {
    border-bottom-color: $mercury !important;

    &:after {
      border-bottom-color: $alabaster;
    }
  }

  > .popover-content {
    padding: 35px 10px 10px 10px !important;
  }
}

#controlled-tabs-transactions-pane-notecom {
  #transactions-popover-positioned-bottom {
    > .arrow {
      left: 208px !important;
    }
  }
}

#controlled-tabs-transactions-pane-ecom {
  #transactions-popover-positioned-bottom {
    > .arrow {
      left: 461px !important;
    }
  }
}

#controlled-tabs-transactions-pane-installments {
  #installments-popover-positioned-bottom {
    > .arrow {
      left: 714px !important;
    }
  }
}

/* FROM TO (AMOUNT RANGE INPUTS MIXIN */

@mixin amount-range($marginTop) {
  &__from-to-placeholder {
    @include flex-row(space-between, flex-start);
    width: 100%;
    margin-top: 8px;
    display: flex !important;

    &__label {
      margin-top: $marginTop;
      display: flex !important;
      width: 100% !important;
    }
  }

  &__from-placeholder {
    position: relative;
    width: 50%;
    @include flex-row(space-between, center);

    &__input-text {
      padding: 5px !important;
    }
  }

  &__to-placeholder {
    position: relative;
    margin-left: 10px;
    width: 50%;
    @include flex-row(space-between, center);

    &__input-text {

    }
  }

  &__currencyInput {
    position: absolute;
    right: 8px;
  }
}

.overall-search-modal {

  > hr {
    margin-top: 0;
  }

  .search-modal {

    display: flex;

    &__dropdown-label {
      margin-top: 10px;
    }

    &__column {
      padding: 0 15px;
      font-size: 1.0em;

      > span {
        line-height: 1.2;
        letter-spacing: 0.2px;
      }

      > div > p {
        font-weight: 700;
      }

      &__info-button-label {
        margin-left: 5px;
        cursor: pointer;
        vertical-align: middle;
        margin-bottom: 0 !important;
      }

      &__info-button {
        width: 5%;
        padding: 0;
        margin-left: 5px;
        visibility: hidden;
        /* Makes input not-clickable */
        position: absolute;
        /* Remove input from document flow */
      }
    }

    &__left-column-upper {
      width: 265px;

      > span {
        display: block;
      }

      @include amount-range(18px);

      .rw-datetime-picker {
        margin-top: $popover-input-margin-top;
        height: $popover-input-height;

        .rw-widget-container {
          outline: none !important;
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
          border-color: $jumbo !important;
        }
      }

      .rw-datetime-picker {
        width: 50%;
        font-size: 1.0em;
        display: inline-block;
      }


      &__left-criteria {

        > input {
          margin-bottom: 20px;
          font-size: 0.9em;
        }

        > label {
          margin-left: 4px;
        }
      }

      &__input-text {
        height: $popover-input-height;
        width: 100%;
        @include flex-row(space-between, center);
        padding: 6px 12px;
        border: 1px solid $jumbo;
      }

    }

    &__left-column-bottom {
      width: 265px;
    }

    &__mid-column-upper {
      width: 228px;

      @include amount-range (1px);

      &__input-text {
        height: $popover-input-height;
        width: 100%;
        @include flex-row(space-between, center);
        padding: 6px 12px;
        border: 1px solid $jumbo;
      }

      &__mids-search-wrapper {
        @include flex-row(flex-start, flex-start)
      }
    }

    &__mid-column-bottom {
      width: 228px;
      @include flex-column(flex-end, flex-start)
    }

    &__right-column-upper {
      width: 228px;

      &__tids-loader {
        @include flex-row(center, center)
      }

      &__single-tid {
        height: 26px !important;
        margin: 7px 0 15px 0;
        padding: 6px 12px 6px 8px;
        background-color: $white;
        border: 1px solid $jumbo;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 195px;
      }
    }

    &__right-column-bottom {
      width: 228px;

      .payment-type-wrapper {
        margin-top: 1px;
      }
    }

    .checkboxes-container {
      display: block;
      margin-top: 18px;
      @include flex-row(flex-start, center);

      > span:nth-child(2) {
        margin-left: 10px;
      }

      .cbox-search-modal {
        position: relative;
        @include custom-checkbox($tango, $tango, transparent, 5px 0 0 0, -4px 5px 0 0, 3px 1px 0 0);
      }
    }

    .transactions-allmids-cbox {
      margin-bottom: 0;

      &__wrapper {
        @include flex-row (flex-start, center);
        margin-top: 10px;
      }

      position: relative;
      @include custom-checkbox($tango, $tango, $white, 2px 0 0 0, 2px 5px 6px 0, 2px 1px 0 0);
    }

    @-moz-document url-prefix() {
      .transactions-allmids-cbox {
        padding-top: 3px !important;
      }
    }

    .transactions-allmids-select {
      @include override-multiple-mids (195px, 26px, 10px 0 0 0, 1px solid $abbey, 26px, 36px, auto, 0 1px, 1px solid $alto, auto);
    }

    .no-multi,
    .simple-input {
      background: $white no-repeat 90% 50%;
      -webkit-appearance: none;
      border-radius: 1px;
      margin-bottom: 15px;
      margin-top: $popover-input-margin-top;
      width: 100% !important;
      height: $popover-input-height;
      border: 1px solid $jumbo;
      padding-left: 8px;
      @include flex-row(space-between, center);
      @include font-mixin(normal, normal, 400, 1em, 1, $open-sans);

      &:hover, &:focus, &:active {
        background: $white no-repeat 90% 50%;
      }
    }

    .dropdown {
      width: 100%;
      @include override-dropdown(-15px 0 0);
      max-width: 195px;

      button {
        color: $abbey !important;
        height: $popover-input-height !important;
        box-shadow: none !important;
      }

      .caret {
        @include override-caret($tango, 3px);
      }

      &.open {
        .caret {
          @include override-caret-reversed;
        }

        button {
          background-color: $white !important;
        }
      }
    }

    &__mid-column {
      &__dropdowns {
        @include autofill-dropdowns-mixin;
        max-width: 195px;
        .search-box {
          &__element {
            margin-top: 7px;
            margin-bottom: 15px;
          }
        }
      }
    }

    &__transaction-type-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__pick-bank-label--wrapper {
      margin-top: 18px;
    }
  }

  &__search-button {
    border-radius: 15px;
    border: none;
    width: auto;
    padding: 5px 15px;
    background-color: $tango;
    color: white;
    float: right;
    height: 30px;
    margin-right: 10px;
    margin-top: 15px;
    min-width: 160px;
    @include hover-focus-states ($tango, $midnight-blue, $white, $white);
  }

  &__more-criteria-line {
    margin: 0 10px 10px 14px !important;
  }

  &__button-container {
    @include flex-row(flex-end, center);
    height: 40px;

    &__reset-link {
      @include button-as-url($lochmara);
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 9px;
    }
  }
}


.mid-line {
  cursor: pointer;

  &__more-criteria {
    visibility: hidden;
    position: absolute;

    &__paragraph {
      margin: 15px 0 15px 15px;

      .mid-line:hover {
        color: $tango !important;
      }
    }
  }

  .icon-right-open-1, .icon-minus-less {
    color: $tango;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    height: 16px;
  }
}

#dropdown-transaction-types + .dropdown-menu {
  width: auto !important;
}



@media screen and (max-width: $min-mobile-limit) {
  #controlled-tabs-transactions {
    > ul > li {
      &:first-child {
        > a > span {
          max-width: 110px !important;
          width: 110px !important;
        }
      }

      &:nth-child(2) {
        > a > span {
          max-width: 95px !important;
          width: 95px !important;
        }
      }

      > a > span {
        font-size: 12px !important;

      }
    }
  }
}

.transactions-allmids-select {
  width: calc(100% - 17px) !important;
}

.react-bootstrap-transactions-table-pagination-total {
  margin-left: 10px;
}

@media screen and (max-width: $tablet-width-limit) {
  .transactions {
    margin-top: 20px !important;
  }
  .transactions-page {
    margin: 20px 0 0 0 !important;
    padding-bottom: 50px;
    height: 748px !important;

    &.more-criteria {
      &.note-open {
        //height: 1184px !important;
      }

      &.ecom-open {
        //height: 1037px !important;
      }
    }
  }
  .transactions-popover-toggle {
    width: 100% !important;
  }

  #transactions-popover-positioned-bottom,
  #installments-popover-positioned-bottom {
    position: absolute !important;
    max-width: none;
    max-height: none;
    width: calc(100% - 40px) !important;
    left: 20px !important;
    top: 130px !important;
    margin-bottom: 60px !important;

    > .arrow {
      right: 30px;
    }

    .popover-content .search-modal {
      flex-direction: column !important;

      .search-modal__column {
        width: 100% !important;
      }
    }
  }

  #controlled-tabs-transactions {
    overflow-y: auto !important;
    height: calc(100% + 115px) !important;
  }

  #controlled-tabs-transactions-pane-notecom {
    #transactions-popover-positioned-bottom {
      > .arrow {
        left: unset !important;
      }
    }
  }

  #controlled-tabs-transactions-pane-ecom {
    #transactions-popover-positioned-bottom {
      > .arrow {
        left: unset !important;
      }
    }
  }

  #controlled-tabs-transactions-pane-installments {
    #installments-popover-positioned-bottom {
      > .arrow {
        left: unset !important;
      }
    }
  }

  .transaction-excel-wrapper {
    right: 20px !important;
    top: 23px;
  }

  .transactions-popover {
    &-wrapper {
      top: 210px !important;
      z-index: 1 !important;
      padding: 8px 20px 15px 20px !important;

      &__excel-button {
        z-index: 1 !important;
        position: relative;
        left: -10px;

        &-tooltip-wrapper {
          display: none !important;
        }
      }
    }

    &-toggle {
      &.move-to-middle {
        @include transform(none);
      }
      &.move-to-right {
        @include transform(none);
      }
    }
  }

  .search-modal {
    &__right-column-upper {
      margin-top: 18px;
    }

    &__mid-column-upper {
      margin-top: 13px;
    }

    &__mid-column {
      &-upper__mids-search-wrapper {
        width: calc(100% + 17px) !important;
      }

      &__dropdowns {
        max-width: unset !important;
        width: 100%;
        .search-box__element {
          width: 100% !important;
        }
      }
    }

    .dropdown {
      max-width: unset !important;
      width: calc(100% - 5px) !important;
    }
  }


  #controlled-tabs-transactions .nav-tabs {
    display: flex;
    width: 100% !important;
    border-radius: 0 !important;
    z-index: 1 !important;
    height: 55px !important;
    margin-bottom: 24px !important;
    @include bottom-pseudo-padding($white, 20px);

    li {
      width: 50%;

      &:first-child {
        margin-left: 15px !important;
        margin-right: 0 !important;

        > a > span {
          width: 126px !important;
        }
      }

      &:nth-child(2) {
        margin-left: 0 !important;

        > a > span {
          width: 110px !important;
        }
      }

      a {
        padding: 10px 15px 0 5px !important;
        border: 1px solid transparent !important;

        span {
          display: flex;
          align-items: flex-end;
          line-height: 16px;
          padding-right: 0 !important;
        }
      }
    }
  }

  .react-bootstrap-table-pagination-list {
    padding: 0 20px !important;
  }

  #transactions-download-tooltip > .tooltip-arrow {
    left: 153px !important;
  }
}

@media screen and (max-width: $min-mobile-limit) {
  #controlled-tabs-transactions .nav-tabs li {
    width: 44%;
  }
}

@media screen and (max-width: $medium-mobile-limit) {
  .transaction-excel-wrapper {
    top: 60px !important;
  }

}


/* Specific Rules for Browsers */

/* Firefox */
@-moz-document url-prefix() {
  .transactions-popover-wrapper {
    top: 184px;
  }
}


