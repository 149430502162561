.transactions-table-wrapper {
  position: relative;
  padding-top: 50px;

  &__trx-tooltip-wrapper {
    position: absolute;
    right: 97px;
    top: 10px;
    z-index: 2;
    @include font-mixin(normal, normal, 400, 12px, 1.2, $open-sans);
    color: $jumbo;
  }

  .react-bootstrap-table-pagination {
    position: absolute;
    top: -14px;
    width: 100%;
    @include flex-column(stretch, flex-end);
    margin: 0 !important;
    background-color: $white;
    .col-md-6.col-xs-6.col-sm-6.col-lg-6 {
      width: 100% !important;
    }
    .react-bootstrap-table-pagination-list {
      @include flex-row(center, center);
      .react-bootstrap-table-page-btns-ul {
        margin: 30px 0 0 0;
        padding-bottom: 8px;
        li {
          a {
            border: none !important;
            color: $abbey;
            @include font-mixin(normal, normal, 400, 12px, 1, $open-sans);
            &:hover,
            &:active,
            &:focus {
              background-color: transparent;
              font-weight: 600;
            }
          }
          &.active {
            a {
              background-color: transparent !important;
              border-color: transparent !important;
              font-weight: 700 !important;
              z-index: 1 !important;
            }
          }
          &:first-child a {
            padding-left: 0 !important;
          }
          &[title="Προηγούμενη σελίδα"],
          &[title="Previous page"],
          &[title="Επόμενη σελίδα"],
          &[title="Next page"],
          &[title="Πρώτη σελίδα"],
          &[title="First page"],
          &[title="Τελευταία σελίδα"],
          &[title="Last page"] {
            a {
              color: transparent !important;
              &:hover {
                font-weight: 400 !important;
              }
            }
            &.disabled {
              a,
              a:before {
                border-color: $light-silver !important;
              }
            }
          }
          &[title="Προηγούμενη σελίδα"],
          &[title="Previous page"] {
            a:before {
              @include caret-horizontal($tango, 0, rotate(135deg));
              content: ' ';
              position: absolute;
            }
          }
          &[title="Πρώτη σελίδα"],
          &[title="First page"] {
            a:before,
            a:after {
              @include caret-horizontal($tango, 0, rotate(135deg));
              content: ' ';
              position: absolute;
            }
            a:after {
              margin-left: 1px !important;
            }
          }
          &[title="Επόμενη σελίδα"],
          &[title="Next page"] {
            a:before {
              @include caret-horizontal($tango, 0, rotate(-45deg));
              content: ' ';
              position: absolute;
            }
          }
          &[title="Τελευταία σελίδα"],
          &[title="Last page"] {
            a:before,
            a:after {
              @include caret-horizontal($tango, 0, rotate(-45deg));
              content: ' ';
              position: absolute;
              margin-left: -4px;
            }
            a:after {
              margin-left: -2px;
            }
          }
        }
      }
    }

    .react-bs-table-sizePerPage-dropdown {
      position: absolute;
      right: 30px;
      top: 15px;
      z-index: 2;
      width: 54px !important;
      min-width: 54px !important;
      display: flex;
      justify-content: flex-end;

      .dropdown-toggle {
        border: 1px solid $jumbo;
        border-radius: 0;
        background-image: none;
        text-shadow: none;
        box-shadow: none;
        padding: 5px 8px;

        > span {
          margin-left: 8px;

          > .caret {
            @include override-caret($tango, 3px);
          }
        }
      }

      &.open {
        .dropdown-toggle > span > .caret {
          @include override-caret-reversed;
        }
      }
      .dropdown-menu {
        width: 54px;
        min-width: 54px;
        overflow-y: hidden !important;
        li > a {
          padding: 5px 8px;
        }
      }
    }
  }
  .react-bootstrap-table {
    //margin-top: 130px;
  }
}

.react-bootstrap-transactions-table-pagination-total {
  @include flex-column(stretch, flex-start);
  position: absolute;
  left: 30px;
  top: 20px;
  .results-total {
    @include font-mixin(normal, normal, 600, 12px, 1, $open-sans);
    @include flex-row(stretch, flex-start);
    > span:first-child {
      margin: 0 5px 0 0;
    }
  }
  .dates-summary {
    margin: 6px 0 0 0;
    @include font-mixin(normal, normal, 400, 12px, 1, $open-sans);
    @include flex-row(stretch, flex-start);
    > span:nth-child(2) {
      margin: 0 5px;
    }
  }
}

.transactions-table {
  font-size: 12px;
  color: $shark;
  table-layout: fixed;
  border-collapse: collapse;
  border: none !important;
  margin: 0 !important;
  td:nth-child(1), th:nth-child(1) {
    width: 16px;
    position: relative;
  }

  td:nth-child(2), th:nth-child(2) {
    width: 9%;
    min-width: 80px;
  }

  td:nth-child(3), th:nth-child(3) {
    width: 9%;
    min-width: 80px;
  }

  td:nth-child(4), th:nth-child(4) {
    width: 9%;
    min-width: 80px;
  }
  td:nth-child(5), th:nth-child(5) {
    width: 7%; min-width: 60px;
  }
  td:nth-child(6), th:nth-child(6) {
    width: 8%;
    min-width: 85px;
  }

  td:nth-child(7), th:nth-child(7) {
    width: 8%;
    min-width: 80px;
  }

  td:nth-child(8), th:nth-child(8) {
    width: 8%;
    min-width: 80px;
  }

  td:nth-child(9), th:nth-child(9) {
    width: 7%;
    min-width: 60px;
  }

  td:nth-child(10), th:nth-child(10) {
    width: 7%;
    min-width: 60px;
  }

  td:nth-child(11), th:nth-child(11) {
    width: 12%;
    min-width: 80px;
  }

  td:nth-child(12), th:nth-child(12) {
    width: 9%;
    min-width: 80px;
  }

  td:nth-child(13), th:nth-child(13) {
    width: 5%;
    min-width: 55px;
  }

  thead {
    border: none !important;
    background-color: $darker-alabaster;
    //@include fixed-top-item($darker-alabaster, calc(100% - #{$sidebar-width}));
    tr {
      @include flex-row(flex-start, stretch);
      &:after {
        content:"";
        position:absolute;
        width:100%;
        bottom:1px;
        z-index:-1;
        @include box-shadow(0, 1px, 2px, 1px, rgba($dove-gray, 0.25));
      }
      th {
        flex-shrink: 0;
        @include font-mixin(normal, normal, 600, 0.917em, 1, $open-sans);
        @include flex-row(stretch, flex-end);
        //padding: 12px 5px !important;
        letter-spacing: -0.2px;
        border: none !important;
        cursor: pointer;
        //border: 1px solid purple !important;
        background-color: $darker-alabaster;
      }
    }
    .transactions-table-header {
      @include flex-row(space-between, flex-end);
      position: relative;
      span {
        white-space: pre;
      }
      .dropup,
      .dropdown {
        color: $tango;
      }
    }
  }

  tbody {
    display: block;
    overflow-y: scroll;
    height: 100vh;
    width: calc(100% + 17px);
    margin-right: -17px;
    box-sizing: content-box;
    tr {
      cursor: pointer;
      @include flex-row(flex-start, stretch);
      max-height: 42px;
      border: none !important;
      &:hover {
        background-color: $zircon !important;
      }
      &:after {
        content: "";
        width: 100%;
        border-bottom: 1px solid $bombay;
      }
      td {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid $bombay;
        .transactions__datetime-col {
          @include flex-column(space-between, flex-start);
          > span {
            &:first-child {
              @include font-mixin(normal, normal, 400, 1em, 1.4, $open-sans);
            }
            &:nth-child(2) {
              @include font-mixin(normal, normal, 400, 0.84em, 1, $open-sans);
            }
          }
        }
        .transaction-type {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .bank-icon-wrapper {
      @include flex-row (center, center);
      margin-left: auto;
      margin-right: auto;
      > span {
        zoom: 0.7;
        -moz-transform: scale(0.7);
        -moz-transform-origin: 0 0;
      }
    }
    .asfalistikos-goneas-wrapper {
      @include flex-row (center, center);
      margin-left: auto;
      margin-right: auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .settled-state-icon {
    @include flex-row (center, center);
    padding-left: 3px;
    height: 42px;
    &.settled-state-done{
      border-left: 2px solid $affair;
    }
    &.settled-state-pending {
      border-left: 2px solid $tango;
    }
    &.in-batch {
      border-left: 2px solid $lochmara;
    }
    &.pay-success {
      border-left: 2px solid $forest-green;
    }
    &.pay-failed {
      border-left: 2px solid $thunderbird;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      border-left: 2px solid $bombay;
      width: 2px;
      height: 2px;
    }
  }
  .icon-settling {
    &.icon-paperclip {
      color: $affair;
      font-size: 10px;
    }
    &.icon-circle-clock {
      font-size: 10px;
      color: $tango;
    }
    &.icon-success {
      font-size: 10px;
      color: $forest-green;
    }
    &.icon-failed {
      font-size: 10px;
      color: $thunderbird;
    }
    &.icon-kleismeno-paketo {
      color: $lochmara;
      font-size: 9px;
    }
  }
  .datetime-tbl {
    @include flex-column(stretch, flex-start);
    .date-tbl {

    }
    .time-tbl{
      @include font-mixin(normal, normal, 400, 0.833em, 1, $open-sans);
    }
  }
  &__empty-state {
    min-height: 100vh;
    @include flex-row(center, flex-start);
    margin-top: 15px;
  }
}

.installments-table {
  font-size: 12px;
  color: $shark;
  table-layout: fixed;
  border-collapse: collapse;
  border: none !important;
  margin: 0 !important;
  td:nth-child(1), th:nth-child(1) {
    width: 10%;
    position: relative;
  }

  td:nth-child(2), th:nth-child(2) {
    width: 10%;
    min-width: 80px;
  }

  td:nth-child(3), th:nth-child(3) {
    width: 20%;
    min-width: 80px;
  }

  td:nth-child(4), th:nth-child(4),
  td:nth-child(5), th:nth-child(5),
  td:nth-child(7), th:nth-child(7) {
    width: 10%;
    min-width: 80px;
  }

  td:nth-child(6), th:nth-child(6) {
    width: 15%;
    min-width: 80px;
  }

  thead {
    border: none !important;
    background-color: $darker-alabaster;
    tr {
      @include flex-row(stretch, stretch);
      &:after {
        content:"";
        position:absolute;
        width:100%;
        bottom:1px;
        z-index:-1;
        @include box-shadow(0, 1px, 2px, 1px, rgba($dove-gray, 0.25));
      }
      th {
        flex-shrink: 0;
        @include font-mixin(normal, normal, 600, 0.917em, 1, $open-sans);
        @include flex-row(stretch, flex-end);
        letter-spacing: -0.2px;
        border: none !important;
        cursor: pointer;
        background-color: $darker-alabaster;
      }
    }
    .installments-table-header {
      @include flex-row(space-between, flex-end);
      position: relative;
      span {
        white-space: pre;
      }
      .dropup,
      .dropdown {
        color: $tango;
      }
    }
  }

  tbody {
    display: block;
    overflow-y: scroll;
    height: 100vh;
    width: calc(100% + 17px);
    margin-right: -17px;
    box-sizing: content-box;
    tr {
      cursor: pointer;
      @include flex-row(stretch, stretch);
      max-height: 42px;
      border: none !important;
      &:hover {
        background-color: $zircon !important;
      }
      &:after {
        content: "";
        width: 100%;
        border-bottom: 1px solid $bombay;
      }
      td {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid $bombay;
        .installments__datetime-col {
          @include flex-column(space-between, flex-start);
          > span {
            @include font-mixin(normal, normal, 400, 1em, 1.4, $open-sans);
          }
        }
      }
    }
  }
  .datetime-tbl {
    @include flex-column(stretch, flex-start);
    .date-tbl {
    }
  }
  &__empty-state {
    min-height: 100vh;
    @include flex-row(center, flex-start);
    margin-top: 15px;
  }
}


@mixin icons-trans-table($width, $height, $posx, $posy) {
  width: $width;
  height: $height;
  display: block;
  background: url('../img/css_sprite.png') $posx $posy;
  overflow: hidden;
}

.scheme-icon-wrapper {
  @include flex-row (center, center);
  margin-left: auto;
  margin-right: auto;
}

.bank-icon-wrapper > span {
  overflow: hidden;
}

.asfalistikos-goneas-wrapper > span {
  overflow: hidden;
}

.table-hdr-settle-cell {
  padding: 2px !important;
}

.table-col-settle-cell {
  padding: 0 !important;
  align-items: flex-start !important;
  > div {
    position: relative;
  }
}

.table-hdr-align-center .transactions-table-header {
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start !important;
}

.table-hdr-align-left .transactions-table-header {
  margin-right: auto;
}

.table-hdr-align-right .transactions-table-header {
  margin-left: auto;
}

.table-col-align-right {
  justify-content: flex-end;
}

.table-col-align-center {
  justify-content: center;
}

.trans-tbl-icon {
  &.icon-icon-check {
    color: $fan-green;
  }
  &.icon-x-icon{
    color: $bright-red;
    font-size: 0.7em;
  }
}

.bg-american-express {
  width: 27px; height: 24px;
  background: url('../img/css_sprite.png') -185px -51px;
}

.bg-diners {
  width: 40px; height: 11px;
  background: url('../img/css_sprite.png') -130px -16px;
}

.bg-maestro {
  width: 30px; height: 24px;
  background: url('../img/css_sprite.png') -13px -93px;
}

.bg-mastercard {
  width: 32px; height: 20px;
  background: url('../img/css_sprite.png') -134px -53px;
}

.bg-unionpay {
  width: 31px; height: 22px;
  background: url('../img/css_sprite.png') -11px -134px;
}

.bg-visa {
  width: 49px; height: 20px;
  background: url('../img/css_sprite.png') -118px -94px;
}

.bg-discover {
  width: 34px; height: 8px;
  background: url('../img/css_sprite.png') -179px -100px;
}

.jump-to-page {
  position: relative;
  margin: 30px 15px 20px auto;

  &__wrapper {
    @include flex-row(flex-end, center);
  }

  &__label {
    @include font-mixin(normal, normal, 400, 12px, 1.2, $open-sans);
    color: $jumbo;
    margin-right: 15px;
  }
  button,
  input {
    width: 45px;
    height: 30px;
    border: 1px solid $jumbo;
    padding: 5px;
  }
  button {
    background-color: $white;
    font-weight: 600;
    color: $tango;
  }
  input {
    @include font-mixin(normal, normal, 400, 12px, 1.2, $open-sans);
  }
}

/* media queries */

@media screen and (max-width: $tablet-width-limit) {

  .transactions-table-wrapper {
    &__trx-tooltip-wrapper {
      display: none !important;
    }
  }

  .transactions-table-wrapper .react-bootstrap-table {
    overflow-x: auto;
    padding-top: 40px !important;
    min-height: 320px;
  }
  .react-bootstrap-table-pagination {
    width: 100% !important;
    //top: 265px !important;
    .react-bs-table-sizePerPage-dropdown {
      right: 15px !important;
    }
  }
  .react-bootstrap-transactions-table-pagination-total {
    left: 15px !important;
  }
  .react-bootstrap-table-page-btns-ul {
    margin: 70px 0 0 0 !important;
  }

  .transactions-table {
    thead {
      width: 100% !important;
      position: static !important;
      z-index: 5 !important;
      overflow: visible !important;
      margin-top: -45px;
      //opacity: 1;
      tr > th:not(:first-child) {
        //z-index: 0 !important;
        width: 15% !important;
        min-width: 90px !important;
      }
    }

    tbody {
      overflow-y: unset;
      margin-top: 0;
      tr > td:not(:first-child) {
        width: 15% !important;
        min-width: 90px !important;
      }
    }

    .react-bootstrap-table-pagination-list {
      width: 100%;
    }

    &__empty-state {
      width:100% !important;
      min-height: 100vh !important;
      position: absolute;
      top: 130px;
    }
  }
}
