.gauge {
  position: relative;
  width: 96.91px;
  margin: 0 auto;

  &__outer__wrapper {
    @include flex-row(flex-end, flex-end);
    margin-top: 20px;
  }
  &__wrapper {
    @include flex-column(flex-end, stretch);
  }

  &__container {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 50%;
    overflow: hidden;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
  }

  &__background {
    z-index: 0;
    position: absolute;
    background-color: $gauge-bg;
    top: 0;
    border-radius: 300px 300px 0 0;
  }

  &__data {
    z-index: 1;
    position: absolute;
    background-color: $gauge-data;
    margin-left: auto;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  &__center {
    z-index: 2;
    position: absolute;
    background-color: #fff;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
  }

  &__needle {
    z-index: 1;
    background-color: $scorpion;
    height: 2px;
    position: absolute;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  &__needle__base {
    position: absolute;
    width: 8px;
    height: 8px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: $scorpion;
    top: 44px;
    left: 50%;
    margin-left: -3.9px;
  }

  &__left-round {
    //width: 9.5px;
    //height: 9.5px;
    //background-color: $gauge-data;
    //position: relative;
    //top: 52px;
    //transform: translateY(-100%);
    //z-index: -1;
    //border-radius: 100%;
    background-color: $tango;
    border-radius: 100%;
    position: absolute;
    transform: translateY(-100%);
    z-index: -1;
    width: 3.7px;
    height: 5px;
    top: 51px;
    left: 0;
  }
  &__right-round {
    background-color: $gauge-bg;
    border-radius: 100%;
    position: absolute;
    transform: translateY(-100%);
    z-index: -1;
    width: 3.7px;
    height: 5px;
    top: 51px;
    right: 0;
  }

  &__label {
    text-align: center;

    &--bottom {
      @include font-mixin(normal, normal, 700, 12px, 1, $open-sans);
      margin-top: -2px;
      color: $tango;
    }

    &--left {
      margin-right: 5px;
      margin-top: -20px;
      padding-bottom: 13px;
    }

    &--right {
      margin-left: 5px;
      margin-top: -20px;
      padding-bottom: 13px;
    }

    &--top {
      margin-bottom: 5px;
    }

    &__text > span {
      font-size: 9px;
      color: $shark;
    }

    &__value {

      span:nth-child(2) {
        font-size: 18px !important;
      }
      span:first-child,
      span:nth-child(3) {
        font-size: 12px !important;
      }

      &--average {
        color: $tango;
      }
    }
  }
}

.gauge {
  height: calc(48.5px + 1em);
&__container { width: 97px; height: 48.5px; }
&__background { width: 97px; height: 48.5px; }
&__center {
  //width: 78px;
  //height: 64px;
  //top: 10px;
  //margin-left: 9.5px;
  width: 89px;
  height: 50px;
  top: 4px;
  margin-left: 4px;
}

&__data { width: 97px; height: 48.5px; }
&__needle { left: 48.5px; top: 46.5px; width: 48px; }
&__label__text{@include flex-column(center, center)}

  &__data {
    -webkit-transform: rotate(0.75turn);
    -moz-transform: rotate(0.75turn);
    -ms-transform: rotate(0.75turn);
    -o-transform: rotate(0.75turn);
    transform: rotate(0.75turn);
    animation: 2s ease-in-out slideGaugeNeedle;
  }
  &__needle {
    -webkit-transform: rotate(0.75turn);
    -moz-transform: rotate(0.75turn);
    -ms-transform: rotate(0.75turn);
    -o-transform: rotate(0.75turn);
    transform: rotate(0.75turn);
    animation: 2s ease-in-out slideGaugeNeedle;
    z-index: 2;
  }
}

@keyframes slideGaugeNeedle {
  0% {
    -webkit-transform: rotate(0.5turn);
    -moz-transform: rotate(0.5turn);
    -ms-transform: rotate(0.5turn);
    -o-transform: rotate(0.5turn);
    transform: rotate(0.5turn);
  }
  100% {
    -webkit-transform: rotate(0.75turn);
    -moz-transform: rotate(0.75turn);
    -ms-transform: rotate(0.75turn);
    -o-transform: rotate(0.75turn);
    transform: rotate(0.75turn);
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .gauge__outer__wrapper {
    margin: 30px 0;
  }
}