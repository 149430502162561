@import 'gauge';

.dashboard {
  margin-bottom: 15px;
  @include single-container-wrap;
  padding: 12px 12px 12px 12px;

  &.add-padding-bottom {
    padding-bottom: 80px;
  }

  .dashboard-kpi-excel-btn {
    position: absolute;
    left: 10px;
    top: 47px;
    border-radius: 50%;
    border: 0 !important;
    @include download-btn-mixin;
    &.disabled {
      opacity: 0.7;
      background-color: $bombay !important;
    }
  }

  .goto__transactions {
    @include flex-row(center, center);
    &__btn {
      @include blue-border-btn-mixin(30px 0 15px 0, 11px, 6px 20px);
    }
  }

  &__tiles {
    padding: 0 40px;
    width: 100%;
    @include flex-row(space-between, flex-end);
    flex-wrap: wrap;
    margin-top: 30px;
  }

  @include charts-timespan-dropdown-mixin(inherit);
}

.transactions-info {
  $trin: &;
  @include flex-column(flex-start, flex-start);
  &__label {
    @include font-mixin(normal, normal, 600, 12px, 1, $open-sans);
    color: $white;
    border-radius: 20px;
    padding: 6px 10px;
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 20px;
      width: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  &__value {
    @include font-mixin(normal, normal, 300, 42px, 1, $open-sans);
    display: inline-flex;
    align-items: baseline;
    &__amount {
      color: $bahama-blue;
    }
    &__currency {
      color: $bahama-blue;
      display: inline-flex;
      margin-right: 2px;
      margin-left: 6px;
    }
    &__count {
      color: $affair;
    }
    border-bottom: 1px solid $bombay;
    padding-bottom: 5px;
    position: relative;
  }

  #{$trin}__percentage {
    margin-top: 4px;
    max-height: 25px;
    @include inline-flex-row(flex-start, stretch);
    .timespan-value {
      margin-left: 15px;
      > span:first-child {
        @include font-mixin(normal, normal, 400, 26px, 1, $open-sans);
        color: $jumbo;
      }
      > span:nth-child(2) {
        @include font-mixin(normal, normal, 400, 16px, 1, $open-sans);
      }
      > span:nth-child(3) {
        .amount {
          margin: 0 10px;
          font-size: 18px;
        }
        .count {
          color: $bombay;
          margin: 0 10px;
          font-size: 18px;
        }
        .icon-kpi-arrow {
          font-size: 14px;
          display: inline-block;
        }
        .kpi-arrow-up {
          color: $limeade;
        }
        .kpi-arrow-down {
          color: $bright-red;
          transform: rotate(-180deg);
        }
      }
    }
    .timespan-label {
      @include flex-column (space-between, flex-start);
      white-space: nowrap;
      @include font-mixin(normal, normal, 400, 10px, 1.2, $open-sans);
    }
  }

  &_amount-type {
    #{$trin}__label {
      background-color: $bahama-blue;
      margin: 0 0 5px 22px;
      max-width: 170px;
      min-width: 112px;
      &:after {
        border-top: 5px solid $bahama-blue;
      }
    }
    #{$trin}__value {
      &:before {
        content: "";
        border-bottom: 1px solid $white;
        width: 24px;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
      &:after {
        content: "";
        border-bottom: 1px solid $bombay;
        width: 24px;
        position: absolute;
        bottom: -1px;
        right: -24px;
      }
      &__currency {
        @include font-mixin(normal, normal, 400, 22px, 1, $open-sans);
      }
    }

    #{$trin}__percentage {
      margin-left: 22px;
    }
  }

  &_count-type {
    #{$trin}__label {
      background-color: $affair;
      margin: 0 0 5px 0;
      &:after{
        border-top: 5px solid $affair;
      }
    }
    //#{$trin}__percentage {
    //  width: calc(100% - 22px);
    //}
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .transactions-info {
    &__value {
      &:before,
      &:after {
        border: 0 !important;
      }
    }
    &__percentage {
      flex-wrap: wrap;
    }
    &_amount-type {
      width: 100% !important;
    }
    &_count-type {
      width: 100% !important;
    }
  }
  .dashboard {
    margin-top: 20px;
    &__tiles {
      //justify-content: center !important;
      width: 100% !important;
      .transactions-info {
        margin-top: 40px;
      }
    }
    &-kpi-excel-btn {
      margin-top: 16px;
    }
  }
}