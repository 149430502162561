.overview {
  &-page {
    @include main-page(0 20px 0 155px);
  }
  &__section__title {
    @include font-mixin(normal, normal, 400, 16px, 1.2, $open-sans);
    color: $midnight-blue;
    display: inline-block;
    margin: 0;
    &:before {
      @include left-pseudo-border ($tango, 13px);
      margin-top: 3px;
    }
  }

  &__single-date {
    text-align: center !important;
  }
}

.timespan-dropdown-wrapper {
  @include flex-row(flex-end, center);
  margin: 10px 0;
}

.number-of-payments {
  height: 380px;
  padding-top: 1px;
  @include single-container-wrap;
  margin-top: 15px;
  position: relative;
  padding-bottom: 70px;


  .more__reports {
    @include flex-row(center, center);
    &__btn {
      @include navButton;
    }
  }
}

.my-pos {
  @include single-container-wrap;
  margin-top: 15px;
  padding: 12px 0 25px 0;

  &__title {
    @include font-mixin(normal, normal, 400, 18px, 2, ($open-sans));
  }

  &__content{
    @include flex-row(space-between, center);
    padding: 0 20px;
  }

  &__text {
    @include font-mixin(normal, normal, 400, 32px, 2, ($open-sans));
    color: $bahama-blue;
  }

  &__under-text {
    @include font-mixin(normal, normal, 400, 0.4em, 0, ($open-sans));
    color: $jumbo !important;
  }
}

.my-pos-excel-btn {
  position: relative;
  left: 10px;
  top: 6px;
  border-radius: 50%;
  border: 0 !important;
  @include download-btn-mixin;

  &.disabled {
    opacity: 0.7;
    background-color: $bombay !important;
  }
}

#export-week-btn {
  position: absolute;
  width: 25px;
  height: 25px;
  right: 5px;
  bottom: 20px;
  .amcharts-export-menu {
    transition: none !important;
    opacity: 1 !important;
    z-index: 1;
    > ul > li > a {
      width: 25px;
      height: 25px;
    }
  }
}

@media screen and (max-width: $tablet-width-limit) {
  .overview-page {
    margin: 20px 20px 0 20px !important;
  }
  .my-pos {
    &__text {
      @include font-mixin(normal, normal, 400, 1.7em, 2, ($open-sans));
      margin-right: 5px;
    }
    &__under-text {
      @include font-mixin(normal, normal, 400, 0.5em, 1.4, ($open-sans));
    }
    &__content {
      flex-wrap: wrap;
      justify-content: space-between !important;
    }
    &__text {
      margin-right: 10px !important;
      min-width: 75px !important;
      max-width: 75px !important;
    }
  }

 #chart-export-btn__TotalsTransactions + div {
   margin-top: 5px !important;
   margin-left: 20px !important;
 }
}





