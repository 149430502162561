@mixin box-styles($padding) {
  background-color: $white;
  padding: $padding;
  border-radius: 42px;
  font-size: 14px;
  max-width: 400px;
  @include box-shadow( 0, 6px, 20px, 0, rgba($black, 0.10));
  @include flex-column(center, stretch);
  position: relative;


  form {
    > span {
      color: $midnight-blue;
      font-weight: 600;
      &.legal-rep--txt {
        @include font-mixin (normal, normal, 600, 12px, 1.1, $open-sans);
      }
    }
  }

  .password-container {
    @include flex-row(stretch, flex-start);
    position: relative;
  }

  input:not([type="checkbox"]):not([type="radio"]):not([type="file"])  {
    border-radius: 15px;
    font-size: 0.95em;
    border: 1px solid $bombay;
    padding: 3px 10px;
    margin: 7px 0 25px 0;
    width: 100%;
    @include placeholder($bombay);
    background-color: $white;
    &#repeatPassword,
    &#password {
      padding: 3px 33px 3px 10px !important;
    }
  }

  &__password {
    @include flex-row(flex-start, flex-start);
    position: relative;
  }

  &__wrong-creds {
    @include icon-font-mixin(0.7em, red);
    margin-top: -10px;
    margin-bottom: 10px;
  }
}

@mixin reset-login($height, $top-margin) {
  height: $height;
  overflow: hidden;
  position: relative;
  background-color: $alabaster;
  min-height: 620px;

  form {
    margin-bottom: 10px !important;
  }

  &__top {
    @include flex-row(flex-end, center);
    margin: 2rem 2rem 1rem 0;
  }

  &__content {
    @include flex-row(center, flex-start);
    margin: $top-margin auto 0 auto;
  }

  &__left {
    @include flex-column(space-between, flex-start);
    font-size: 32px;
    max-width: 240px;
    color: $midnight-blue;
    z-index: 1;

    h2 {
      font-weight: 700;
      @include font-mixin(normal, normal, 600, 1em, 1, $open-sans);
      margin: 0 !important;
      word-spacing: 0.1em;
    }
  }

  &__sub-header {
    @include font-mixin(normal, normal, 400, 0.7em, 1.1, $open-sans);
    color: $cerulean;
    margin: 5px 0 0 0;
    max-width: 300px;
  }

  &__text {
    @include font-mixin(normal, normal, 600, 0.45em, 1.3, $open-sans);
    margin-bottom: 0;
    margin-top: 21px;

    &_intro {
      margin: 21px 0 10px 0;
      color: $jumbo;

      &__few {
        margin: 21px 0 10px 0;
        font-size: 0.35em;
      }
    }

    &_list {
      @include reset-margin-block;
      margin-left: 16px;
      color: $jumbo;

      li {
        margin-bottom: 14px;
        max-width: 250px;
      }
    }
  }

  &__register-area {
    > span {
      @include font-mixin(normal, normal, 500, 1.22em, 1, $open-sans);
    }

    @include flex-column(center, center);
    color: $midnight-blue;
    margin-top: 30px;
    width: 100%;
  }

  &__bullet-padding {
    padding-left: 20px;
    line-height: 2;
  }

  &__login-with-forgot {
    @include flex-column(center, flex-start);
    margin-left: 77px;
    margin-bottom: 10px;
    z-index: 1;

    &__info-button-label {
      margin-left: 5px;
      cursor: pointer;
    }

    &__info-button {
      width: 5%;
      padding: 0;
      margin-left: 5px;
      visibility: hidden; /* Makes input not-clickable */
      position: absolute; /* Remove input from document flow */
    }
  }

  &__forgot-password {
    width: 100%;
    @include font-mixin(normal, normal, 600, 0.8em, 1, $open-sans);
    text-align: center;
    margin-top: 15px;

    a {
      color: $lochmara;
    }
  }

  &__login-box {
    @include box-styles(30px 65px 10px 75px);
    width: 400px;
  }

  &__four-digit-box {
    color: $midnight-blue;
    width: 400px;
    margin-bottom: 10px;
    @include box-styles(47px 50px 0 50px);
    > span {
      font-size: 50px;
    }

    @include flex-row(center, flex-start);

    &__digit-column {
      @include flex-column(center, flex-start);
      .phone-input-wrapper {
        @include flex-row(space-between, flex-start);
        input {
          width: 150px !important;
          margin: 7px 10px 25px 0 !important;
        }
        button {
          @include login-btns-mixin(auto, 5px, $bahama-blue, 150px, 8px);
        }
      }
    }

    &__code {
      width: 60px !important;
      text-align: center;
    }
  }

  &__login-button-area {
    @include flex-row(center, center);
    margin: 0 0 15px 0;
    width: 100%;
    &__wrapper {
      @include flex-row(space-between, center);
      margin-top: 25px;
    }
    &.register__area {
      width: 110px !important;
    }
  }

  &__show-digit-code-help {
    font-size: 14px !important;
    @include font-mixin(normal, normal, 700, 14px, 16px, $open-sans);
    color: $lochmara;
  }

  button[disabled]:not(.add--disabled) {
    opacity: 0.7;
    background-color: $bombay !important;
  }

  &__login-button {
    @include login-btns-mixin(45%, 15px, $tango, 150px, 15px, $white);
  }

  &__resend-area {
    margin-top: 25px;
    ul {
      @include new-margin-block(10px);
    }
  }

  &__resend-button {
    @include btns-mixin(auto, 15px, $white, 150px, 15px, $lochmara, 1px solid $lochmara);
    height: auto !important;
    margin-bottom: 10px;
    padding: 5px 15px !important;
  }

  &__register-button {
    @include login-btns-mixin (auto, 10px, $bahama-blue, 220px, 7px);
  }
}

.register__button {
  width: auto !important;
  min-width: 110px !important;
  &:disabled {
    background-color: $light-alto !important;
    cursor: not-allowed;
  }
}

.reset-area {
  &__icon-password-eye {
    @include password-eye(7px, 0);
  }
  &__password-container {
    position: relative;
  }
}


@media screen and (max-width: $tablet-width-limit) {
  .reset-area-step1,
  .reset-area-step2 {
    height: auto !important;
    padding: 0 0 5rem 0 !important;
    min-height: auto !important;
  }

  .reset-area-step2 {
    &__four-digit-box {
      margin: 0 auto !important;
      position: relative !important;
      padding: 20px 30px 0 30px !important;
      width: 100% !important;
    }
  }

  .reset-area-step1__content,
  .reset-area-step2__content,
  .new-password-form__content,
  .new-phone-form__content {
    flex-direction: column !important;
    width: calc(100vw - 40px) !important;
    margin: 0 auto 0 auto !important;
  }
  .otp-form {
    &__content {
      flex-direction: column-reverse !important;
    }
    &__resend-area {
      margin-top: 10px !important;
    }
    &__login-with-forgot {
      margin: 0 auto 10px auto !important;
    }

    &__login-box {
      margin: 0 auto !important;
      position: relative !important;
      padding: 10px 30px 0 30px !important;
      width: calc(100vw - 40px) !important;
      max-width: calc(100vw - 40px) !important;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .reset-area-step1__left,
  .reset-area-step2__left,
  .otp-form__left {
    margin: auto;
  }

  .reset-area-step1__login-with-forgot,
  .reset-area-step2__login-with-forgot,
  .new-password-form__login-with-forgot,
  .new-phone-form__login-with-forgot,
  .opt-form__login-with-forgot,
  .new-password-form__top {
    margin: 1.6rem auto !important;
  }

  .reset-area-step2__login-with-forgot {
    width: 100% !important;
  }

  .reset-area-step1__login-box,
  .new-password-form__login-box,
  .reset-area-step2__login-box,
  .new-phone-form__login-box {
    margin: 0 auto !important;
    position: relative !important;
    padding: 20px 30px 0 30px !important;
    width: 100% !important;
  }

  .reset-area-step1__forgot-password,
  .reset-area-step2__forgot-password {
    margin-left: 0 !important;
    width: 100% !important;
  }


  .reset-area-step1__top,
  .reset-area-step2__top {
    margin: 1rem 0 !important;
  }

  .register__button {
    min-width: 80px !important;
  }

  .logo img {
    width: 80px;
    height: 33.15px;
  }
  .shapes {
    display: none;

    &__dark-blue {
      display: none;
    }
  }

}
